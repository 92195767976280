
import React, { useState } from 'react';
import { Tooltip } from '@material-ui/core';

const LabelCPFCNPJ = ({ value }) => {
  
    function format(value) {
      const cnpjCpf = value.replace(/\D/g, '');  
      if (cnpjCpf.length === 11) {
        return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
      }       
      return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
    }
  
    return (
      <Tooltip title={value}>
        <div> 
          {format(value)}   
        </div>
      </Tooltip>
    );
  }
  
export default LabelCPFCNPJ;