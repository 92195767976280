import React from 'react';
import { ArrowBackIos } from '@material-ui/icons';
import { Button, Grid } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';


const BackButton = props => {
  const history = useHistory();
  const { t } = useTranslation();

  function isHistoryEmpty() {
    return (history.action === 'POP');
  }

  return (
    <Grid
      item
      xs={12}
      md={12}
    >
    {  !isHistoryEmpty() &&
      <Button
        startIcon={<ArrowBackIos/>}
        color="primary"
        variant="contained"
        onClick={() => history.goBack()}
      >
        {t('button.back')}
      </Button>
    }
    </Grid>
  );
};

BackButton.propTypes = {
};

export default BackButton;