import { ChevronRight as ChevronRightIcon, ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import PropTypes from 'prop-types';
import React from 'react';
import { capitalize, formatNumber } from 'helpers/format'

function JsonTreeView(props) {
  const { json, capitalizeLabels, emptyLabel, startExpanded } = props;

  let curNodeId = 0
  let expandedNodes = []

  function isObject(value) {
    return value
        && value.constructor === Object
        && Object.keys(value).length > 0;
  }

  function isArray(value) {
    return value
        && Array.isArray(value);
  }

  function isEmptyObject(value) {
    return JSON.stringify(value) === JSON.stringify({})
  }

  function isRawValue(value) {
    return value === 0 || (value && !isEmptyObject(value))
  }

  function RecursiveTreeView(json) {

    function getTreeItemsFromData(jsonEntries) {

      return jsonEntries.map(function([key, value], index) {
        let children = undefined;
        let label = `${key}: `
        if(capitalizeLabels)
          label = capitalize(label)
        if(isObject(value)) {
          children = getTreeItemsFromData(Object.entries(value))
        } else if(isArray(value)) {
          children = value.map(function(jsonItem, innerIndex) {
            let innerChildren = undefined;

            let labelArray = `[${innerIndex}]: `
            if(isObject(jsonItem)) {
              innerChildren = getTreeItemsFromData(Object.entries(jsonItem))
            } else if(isArray(jsonItem)) {
              let jsonObject = {}
              jsonObject[`[${innerIndex}]`] = jsonItem
              return getTreeItemsFromData(Object.entries(jsonObject))
            } else if(isRawValue(jsonItem)) {
              labelArray += formatNumber(jsonItem)
            }
            if(innerChildren) {
              expandedNodes.push(curNodeId)
            }
            return (
              <TreeItem
                key={innerIndex}
                nodeId={curNodeId++}
                label={labelArray}
                children={innerChildren}
              />);
          })
        } else if(isRawValue(value)) {
          label += formatNumber(value)
        }

        if(children) {
          expandedNodes.push(curNodeId)
        }
        return (
          <TreeItem
            key={index}
            nodeId={curNodeId++}
            label={label}
            children={children}
          />
        );
      });
    }

    return getTreeItemsFromData(Object.entries(json))
  }

  return (
    <>
      <TreeView
        defaultExpanded={startExpanded ? expandedNodes : []}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {json ?
          RecursiveTreeView(json)
            :
          <TreeItem
            label={emptyLabel}
          />}
      </TreeView>
    </>
    );
}

JsonTreeView.propTypes = {
  startExpanded: PropTypes.bool,
  json: PropTypes.object,
  emptyLabel: PropTypes.string,
  capitalizeLabels: PropTypes.bool
};

export default JsonTreeView;
