import { IconButton, Tooltip, Box } from '@material-ui/core';
import { AccountTree, FilterNone as CopyIcon } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@material-ui/core';
import * as Constants from 'common/systemConstants';
import { EntityList, LabelDate, LabelTruncateRight, removeHtml, truncateMiddle } from 'components';
import { withSnackbar } from 'hooks/withSnackbar';

const Publications = (props) => {
  const { t } = useTranslation();

  function copy(text){
    navigator.clipboard.writeText(text)
    props.showMessageSuccess(t('label.copied'))
  }

  return (
    <EntityList
      actionsColumn={[
        (row) => (
          <Tooltip
            aria-label={t('button.constelation')}
            title={t('button.constelation')}
          >
            {/*<RouterLink color="inherit" to={`/publication/${row.id}/constellation`}>          */}
            <IconButton onClick={() => {window.open(`/#/publication/${row.id}/constellation`, '_blank')}}>
              <AccountTree fontSize="large" />
            </IconButton>
            {/*</RouterLink>*/}
          </Tooltip>
        )
      ]}
      columns={[
        {
          name: 'date',
          ordered: true,
          component: (row) => ( <LabelDate
            format={'DD/MM/YYYY'}
            value={row.date}
          /> )
        },
        {
          name: 'title',
          ordered: true
        },
        {
          name: 'url',
          ordered: true,
          component: (row) => (
            <Box display='flex' alignItems='center'>
              <Tooltip title={t('button.copy.clipboard')}>
                <IconButton onClick={() => {copy(row.url)}}>
                  <CopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Link href={row.url}
                    target="_blank">
                  {truncateMiddle(row.url, 10, 20)}
              </Link>
            </Box>
          )
        },
        {
          name: 'content',
          ordered: false,
          component: (row) => (
            <LabelTruncateRight
              length={200}
              value={removeHtml(row.content.text)}
            />
          )
        }
      ]}
      entity={'publication'}
      exportCsv
      objectTypeId={Constants.OBJECT_TYPE_IDS.PUBLICATION}
      sort={{
        column : 'date',
        order : Constants.ORDER_DESC
      }}
    />
  )
}

Publications.propTypes = {
};

export default withSnackbar(Publications);
