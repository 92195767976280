import { Box, Card, CardHeader, CardContent, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { formatDate } from 'helpers/format'

const RiskTable = props => {

  const { classes } = props
  const [object, setObject] = useState(props.object);

  React.useEffect(() => {
    setObject(props.object)
  }, [props.object]);

  const { t } = useTranslation();

  return (
    <Box my={2}>
      <Card>
        <CardHeader title={t('label.risks')} />
        <CardContent>
          <Card>
            <CardContent>
              {(object.risks && object.risks.list.length > 0)?
                <PerfectScrollbar>
                  <div className={classes.inner}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            {t('label.type')}
                          </TableCell>
                          <TableCell>
                            {t('label.description')}
                          </TableCell>
                          <TableCell>
                            {t('label.date')}
                          </TableCell>
                          <TableCell>
                            {t('label.rate')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(object.risks && object.risks.list.length > 0) && object.risks.list.map(row => (
                          <TableRow
                            className={classes.tableRow}
                            hover
                            key={row.date}
                          >
                            <TableCell>
                              {row.type}
                            </TableCell>
                            <TableCell>
                              {row.description}
                            </TableCell>
                            <TableCell>
                              {row.created_at ? formatDate(row.created_at) : ''}
                            </TableCell>
                            <TableCell>
                              {row.rate}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                  </div>
                </PerfectScrollbar>
                :
                <Typography>{t('table.result.none')}</Typography>
              }
            </CardContent>
          </Card>
        </CardContent>
      </Card>
    </Box>
  );
};

RiskTable.propTypes = {
};

export default RiskTable;