import { FormControlLabel, Slider, Typografy, FormGroup, FormLabel, FormControl, InputLabel, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, TextField, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CameraEnhance as CameraEnhanceIcon, Cancel as CancelIcon, Check as CheckIcon, DeviceHub as ConnectIcon, Delete as DeleteIcon, Edit as EditIcon, GpsFixed as GpsFixedIcon, Redo as RedoIcon, Save as SaveIcon, Toc as TocIcon, Undo as UndoIcon } from '@material-ui/icons';
import { useConfirm } from 'material-ui-confirm';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataSet, Network } from 'vis-network/standalone/esm/vis-network';
import { ColorPicker } from 'material-ui-color';

import { EntityList, LabelCPFCNPJ, LabelDate, LabelTruncateRight, removeHtml } from 'components';
import BackButton from 'components/BackButton';
import CollectionsContext from 'components/CollectionsContext';
import ConfirmDialog from 'components/ConfirmDialog'
import LoadingSpinner from 'components/LoadingSpinner';
import { downloadBase64Data } from 'helpers/file'
import { withSnackbar } from 'hooks/withSnackbar';
import { collectionsService, constellationService, genericService } from 'services';

import AddEntityContext from './Components/AddEntityContext';
import ConstellationProperties from './Components/ConstellationProperties';
import SaveToCollectionContext from './Components/SaveToCollectionContext';
import * as Constants from 'common/systemConstants';

const MIN_NODE_SIZE = 10
const MAX_NODE_SIZE = 60
const MIN_EDGE_SIZE = 1
const MAX_EDGE_SIZE = 12
const MAX_LABEL_SIZE = 20;
const MAX_TOOLTIP_SIZE = 50;
const MAX_HISTORIC_LENGTH = 30
const BREAK_LINE = '\n'

const DEFAULT_EDGE_COLOR = "#bdbdbd"
const DEFAULT_EDGE_PERCENTAGE = 10

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  constellation: {
    height: '500px'
  },
  inline: {
    display: 'inline',
  },
  graphToolbar: {
    paddingBottom: "0 !important"
  },
  iconButton: {
    color: "inherit"
  },
  toolbar: {
    width: 'fit-content',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
  },
  colorPicker: {
    width: "100%"
  },
  formInputs: {
    width: "100% !important",
    marginBottom: theme.spacing(2)
  }
}));

const graphOptions = {
  physics: {
    barnesHut: { gravitationalConstant: -30000 },
    stabilization: { iterations: 2500 },
    hierarchicalRepulsion: {
      centralGravity: 0.0,
      springLength: 500,
      springConstant: 0.01,
      nodeDistance: 200,
      damping: 0.09
    },
  },
  layout: {
    hierarchical: {},
  },
  interaction: {
    multiselect: true,
    hover: true,
    tooltipDelay: 100,
  },
  nodes: {
    color: {
      border: "#999999",
      highlight: {
        border: "#666666",
      },
      hover: {
        background: '#e0edf9'
      }
    },
    shadow: {
      enabled: true
    },
    borderWidth: 3,
    shape: "dot"
  },
  edges: {
    color: {
      color: DEFAULT_EDGE_COLOR
    },
  }
};

const ENTITY_COLUMNS = {
  "agency": [
    {
      name: "name",
      ordered: true
    },
    {
      name: "code",
      ordered: true,
      component: (row) => (<LabelCPFCNPJ value={row.code} />)
    }
  ],
  "company": [
    {
      name: "name",
      ordered: true
    },
    {
      name: "code",
      ordered: true,
      component: (row) => (<LabelCPFCNPJ value={row.code} />)
    },
    {
      name: "head_office",
      ordered: true,
      component: (row) => (<Checkbox readOnly={true} checked={(row.head_office == '1') ? true : false} />)
    },
  ],
  "publication": [
    {
      name: "date",
      ordered: true,
      component: (row) => (<LabelDate value={row.date} format={'DD/MM/YYYY'} />)
    },
    {
      name: "content",
      ordered: false,
      component: (row) => (<LabelTruncateRight value={removeHtml(row.content.text)} length={200} />)
    }
  ],
}

function getNodeSizeForWeight(weight) {
  if (!weight) {
    return MIN_NODE_SIZE
  }
  let size = weight * MAX_NODE_SIZE
  if (size < MIN_NODE_SIZE) {
    return MIN_NODE_SIZE
  }
  return size
}

function getEdgeSizeFortWeight(weight) {
  if (!weight) {
    return MIN_EDGE_SIZE
  }
  let size = weight * MAX_EDGE_SIZE
  if (size < MIN_EDGE_SIZE) {
    return MIN_EDGE_SIZE
  }
  return size
}

const paginationCollections = {
  size: 100,
  page: 0,
  sort: "name",
  order: "asc"
};

const GraphEdgeEditor = (props) => {

  const classes = useStyles();
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    label: "",
    color: DEFAULT_EDGE_COLOR,
    percentage: DEFAULT_EDGE_PERCENTAGE,
    line: "solid"
  });

  React.useEffect(() => {
    setFormData(props.values)
  }, [props.values]);

  const valuetext = (value) => {
    return `${value}%`;
  }

  const handleValueChange = event => {
    if (event.target.name == 'line') {
      setFormData({
        ...formData,
        [event.target.name]: event.target.checked ? "dashed" : "solid"
      });
    } else {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value
      });
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onCancel}
    >
      <DialogTitle>{t('label.node.connection')}</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <FormControl className={classes.formInputs} >
              <TextField
                fullWidth
                variant="standard"
                type={'text'}
                label={t('label.text')}
                name="label"
                value={formData.label}
                onChange={handleValueChange}
              />
            </FormControl>
          </Grid>

          <Grid
            align="left"
            item
            xs={12} lg={12}
          >
            <FormControl className={classes.formInputs}>
              <FormLabel>{t('label.density')}</FormLabel>
              <Slider
                defaultValue={20}
                step={10}
                marks
                min={10}
                max={100}
                valueLabelFormat={valuetext}
                valueLabelDisplay="auto"
                name="percentage"
                value={formData.percentage}
                onChange={(event, value) => {
                  handleValueChange({
                    target: {
                      name: "percentage",
                      value: value
                    }
                  })
                }}
              />
            </FormControl>
          </Grid>

          <Grid
            align="left"
            item
            xs={12} lg={12}
          >
            <FormControl className={classes.formInputs}>
              <FormLabel>{t('label.node.color')}</FormLabel>
              <ColorPicker
                className={classes.colorPicker}
                disableAlpha
                disablePlainColor
                defaultValue={DEFAULT_EDGE_COLOR}
                name="color"
                value={formData.color}
                onChange={(value) => {
                  handleValueChange({
                    target: {
                      name: "color",
                      value: "#" + value.hex
                    }
                  })
                }}
              />
            </FormControl>
          </Grid>

          <Grid
            align="left"
            item
            xs={12} lg={12}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="line"
                  checked={formData.line == "dashed"}
                  onChange={handleValueChange}
                />
              }
              label={t("label.dashed")}
            />
          </Grid>

        </Grid>

      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={props.onCancel}>
          {t('button.cancel')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => { props.onConfirm(formData) }}>
          {t('button.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

GraphEdgeEditor.propTypes = {
  values: PropTypes.object,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

const Constellation = (props) => {

  const { match } = props
  const { t } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState();
  const [nodesPropertiesOpen, setNodesPropertiesOpen] = useState(false);
  const [edgesPropertiesOpen, setEdgesPropertiesOpen] = useState(false);
  const [entitySelected, setEntitySelected] = useState('publication');
  const [entitySelectionOpen, setEntitySelectionOpen] = useState(false);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [selectedEdges, setSelectedEdges] = useState([]);
  const [undoDisabled, setUndoDisabled] = useState(true);
  const [redoDisabled, setRedoDisabled] = useState(true);
  const [tableDataCollections, setTableDataCollections] = useState();
  const [constellationId, setConstellationId] = useState();
  const [historicPositionSaved, setHistoricPositionSaved] = useState(-1);
  const [selectedEdgeData, setSelectedEdgeData] = useState({});

  const confirm = useConfirm();
  const historic = useRef([]);
  const historicPosition = useRef(-1);
  const graphNodes = useRef(new DataSet())
  const graphEdges = useRef(new DataSet())
  const graph = useRef(null);
  const domNode = useRef(null);

  React.useEffect(() => {
    setLoading(true);

    const paramPublicationId = match.params.publicationId;
    const paramConstellationId = match.params.constellationId;
    if (paramPublicationId) {
      genericService
        .getConstellationById("publication", paramPublicationId)
        .then(data => {
          fillConstellationData(data)
          setLoading(false);
        })
        .catch(except => {
          props.showMessageError(except.message, except.detailedMessage)
        });
    } else if (paramConstellationId) {
      constellationService.getById(paramConstellationId)
        .then(data => {
          fillConstellationData(data.data)
          setConstellationId(paramConstellationId)
          setHistoricPositionSaved(0)
          setLoading(false);
        })
        .catch(except => {
          props.showMessageError(except.message, except.detailedMessage)
        });
    } else {
      return
    }

    updateTableCollections()

  }, []);

  const updateTableCollections = () => {
    collectionsService
      .getPage(paginationCollections, false, 'false')
      .then((data) => {
        setTableDataCollections(data)
      })
      .catch((except) => {
        props.showMessageError(except.message, except.detailedMessage)
      });
  }

  useEffect(() => {
    window.onbeforeunload = function () {
      if (!undoDisabled || !redoDisabled)
        return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, [undoDisabled, redoDisabled]);

  /******************************
   * Constellation historic
   ******************************/

  function reloadUndoRedoButtons() {
    setRedoDisabled(
      historicPosition.current == (historic.current.length - 1))
    setUndoDisabled(historicPosition.current <= 0)
  }

  function fillConstellationData(data) {
    historic.current.splice(historicPosition.current + 1)
    if (historicPositionSaved >= historic.current.length) {
      setHistoricPositionSaved(-1)
    }
    historic.current.push(data)
    if (historic.current.length > MAX_HISTORIC_LENGTH) {
      historic.current.splice(0, 1)
    }
    historicPosition.current = historic.current.length - 1;

    loadConstellationData(data)
    reloadUndoRedoButtons()
  }

  function rewindConstellation() {
    if (historicPosition.current <= 0)
      return
    historicPosition.current--;
    loadConstellationData(historic.current[historicPosition.current])
    reloadUndoRedoButtons()
  }

  function forwardConstellation() {
    if (historicPosition.current == historic.current.length - 1)
      return
    historicPosition.current++;
    loadConstellationData(historic.current[historicPosition.current])
    reloadUndoRedoButtons()
  }

  function getCurrentConstellation() {
    return historic.current[historicPosition.current]
  }

  function cloneObject(object) {
    return JSON.parse(JSON.stringify(object))
  }

  function handleLabel(text) {
    text = text.trim()
    if (text.length <= MAX_LABEL_SIZE) {
      return text;
    }
    let numChunks = Math.ceil(text.length / MAX_LABEL_SIZE)
    const chunks = new Array(numChunks)
    for (let i = 0, j = 0; i < numChunks; ++i) {
      let chunk = text.substr(j, MAX_LABEL_SIZE)
      if (chunk.length === MAX_LABEL_SIZE
        && (!chunk.startsWith(' ') || (chunk.startsWith(' ') && text[j + MAX_LABEL_SIZE] !== ' '))
        && text[j + MAX_LABEL_SIZE] !== ' ') {

        while (!chunk.endsWith(' ')) {
          chunk = chunk.substr(0, chunk.length - 1)
        }
      }
      j = chunk.length
      if (i !== 0) {
        j += chunks.filter(x => x !== '').join('').length
      }
      chunks[i] = chunk
      if (i === numChunks - 1 && j < text.length) {
        numChunks++;
      }
    }
    return chunks.join(BREAK_LINE);
  }

  /******************************/

  function getMinMaxTotalValueForLevels(data) {
    const minMaxValues = {}
    for (let n in data.nodes) {
      let node = data.nodes[n]
      if (!node.value || node.value < 0) {
        continue;
      }

      let minMax = minMaxValues[node.level]
      if (!minMax) {
        minMax = { min: 0, max: node.value, total: node.value }
        minMaxValues[node.level] = minMax
        continue;
      }

      minMax.total += node.value

      if (node.value < minMax.min) {
        minMax.min = node.value
        continue;
      }

      if (node.value > minMax.max) {
        minMax.max = node.value
        continue;
      }
    }
    return minMaxValues
  }

  function loadConstellationData(data) {

    const dataNodes = {};

    const minMaxTotalLevelsValues = getMinMaxTotalValueForLevels(data);

    for (let n in data.nodes) {
      let node = data.nodes[n]
      let graphNodeId = generateGraphNodeId(node);
      if (dataNodes.hasOwnProperty(graphNodeId))
        continue;

      let label = node.label
      if (!label) {
        label = node.sub_label
      }
      label = handleLabel(label)
      if (node.data
        && node.data.content
        && node.data.content.pubDate) {
        label = node.data.content.pubDate + BREAK_LINE + label
      }
      let comments = node.comments
      if (comments && comments.length > MAX_TOOLTIP_SIZE) {
        comments = comments.substr(0, MAX_TOOLTIP_SIZE - 3) + '...';
      }

      let color = data.levels[node.level].color
      if (node.color) {
        color = node.color
      }

      if (!node.weight) {
        const minMaxTotalLevel = minMaxTotalLevelsValues[node.level]
        if (node.value && minMaxTotalLevel) {
          node.weight = node.value / ((minMaxTotalLevel.min + minMaxTotalLevel.max) * 1.0)
        }
      }

      let newNode = {
        level: node.level,
        color: {
          background: color,
          highlight: {
            background: color
          }
        },
        id: graphNodeId,
        label: label,
        size: getNodeSizeForWeight(node.weight),
        title: node.title
      }

      if (comments)
        newNode.title = comments

      if (node.group) {
        newNode.cid = node.group
      }

      dataNodes[graphNodeId] = newNode
    }

    const fromToEdges = {}
    const toFromEdges = {}

    const dataEdges = [];
    for (let e in data.edges) {
      const edge = data.edges[e]
      const from = generateGraphNodeId(edge.from)
      const to = generateGraphNodeId(edge.to)
      if (fromToEdges[from] == to
        || toFromEdges[to] == from) {
        continue
      }
      fromToEdges[from] = to
      toFromEdges[to] = from
      dataEdges.push({
        from: from,
        to: to,
        label: edge.label,
        dashes: (edge.line && edge.line === "dashed"),
        width: getEdgeSizeFortWeight(edge.width),
        color: {
          color: edge.color,
          highlight: edge.color
        },
      });
    }

    updateGraph(Object.values(dataNodes), dataEdges)
    updateSelectedItems()
  }

  function mergeConstellationData(left, right) {
    let merged = cloneObject(right)

    if (left.levels)
      merged.levels = { ...left.levels, ...merged.levels }
    if (left.nodes && left.nodes.length)
      merged.nodes = [...left.nodes, ...merged.nodes]
    if (left.edges && left.edges.length)
      merged.edges = [...left.edges, ...merged.edges]

    return merged
  }

  function generateGraphNodeId(element) {
    return `${element.level}_${element.id}`
  }

  function extractNodeId(graphNodeId) {
    let splitted = graphNodeId.split("_")
    return { level: parseInt(splitted[0]), id: splitted[1] }
  }

  function getClusters(nodes) {
    let clusters = {}
    for (let n in nodes) {
      const node = nodes[n]
      if (!node.cid) {
        continue;
      }
      const level = node.level
      if (!clusters[level]) {
        clusters[level] = {}
      }
      if (!clusters[level][node.cid]) {
        clusters[level][node.cid] = {
          "label": node.label,
          "color": node.color,
        }
      }
    }
    return clusters;
  }

  function clusterNodes(dataNodes) {
    const clusters = getClusters(dataNodes)
    for (let level in clusters) {
      const cids = clusters[level]
      for (let cid in cids) {
        const cluster = cids[cid]
        const clusterOptionsByData = {
          joinCondition: function (childOptions) {
            return (childOptions.cid == cid && childOptions.level == level);
          },
          clusterNodeProperties: {
            id: `cluster_${level}_${cid}`,
            label: cluster.label,
            level: level,
            shapeProperties: {
              borderDashes: [2, 6]
            },
            weight: getNodeSizeForWeight(0.8),
            color: cluster.color
          },
        };
        graph.current.cluster(clusterOptionsByData);
      }
    }
  }

  const openProperties = () => {
    const edges = updateSelectedEdges()
    const nodes = updateSelectedNodes()
    if (edges.length == 1 &&
      nodes.length == 0) {
      setEdgesPropertiesOpen(true)
    } else {
      setNodesPropertiesOpen(true)
    }
  }

  function updateSelectedItems() {
    updateSelectedNodes()
    updateSelectedEdges()
  }

  function updateGraph(dataNodes, dataEdges) {

    graphNodes.current.clear()
    graphNodes.current.add(dataNodes)

    graphEdges.current.clear()
    graphEdges.current.add(dataEdges)

    const data = {
      nodes: graphNodes.current,
      edges: graphEdges.current
    };

    graph.current = new Network(domNode.current, data, graphOptions);

    clusterNodes(dataNodes);

    graph.current.on("selectNode", function (params) {
      updateSelectedItems()
    })
    graph.current.on("deselectNode", function (params) {
      updateSelectedItems()
    })
    graph.current.on("selectEdge", function (params) {
      updateSelectedItems()
    })
    graph.current.on("deselectEdge", function (params) {
      updateSelectedItems()
    })
    graph.current.on("oncontext", function (params) {
      params.event.preventDefault();
      openProperties();
    })
    graph.current.on("doubleClick", function (params) {
      openProperties();
    })
  }

  function findNodeByGraphNodeId(graphNodeId) {
    let nodeInfo = extractNodeId(graphNodeId)
    return getCurrentConstellation().nodes.find((node) =>
      node.level == nodeInfo.level && node.id == nodeInfo.id)
  }

  function findEdgeByGraphEdgeId(graphEdgeId) {
    const edgeFound = graphEdges.current.get(graphEdgeId)
    let nodeInfoFrom = extractNodeId(edgeFound.from)
    let nodeInfoTo = extractNodeId(edgeFound.to)
    return getCurrentConstellation().edges.find((edge) =>
      nodeInfoFrom.level == edge.from.level &&
      nodeInfoFrom.id == edge.from.id &&
      nodeInfoTo.level == edge.to.level &&
      nodeInfoTo.id == edge.to.id)
  }

  function updateSelectedEdges() {

    let selectedGraphEdgeIds = graph.current.getSelectedEdges()
    let tempSelectedEdges = []
    let tempLabel = null

    for (let i in selectedGraphEdgeIds) {
      const edgeId = selectedGraphEdgeIds[i]
      if (edgeId.startsWith("clusterEdge:")) {
        continue
      }
      const selectedEdge = findEdgeByGraphEdgeId(edgeId)

      if (selectedEdge) {
        tempSelectedEdges.push(selectedEdge)
        tempLabel = selectedEdge.label
      }
    }
    setSelectedEdges(tempSelectedEdges)

    let edgeData = {
      label: "",
      color: DEFAULT_EDGE_COLOR,
      percentage: DEFAULT_EDGE_PERCENTAGE
    }
    if(tempSelectedEdges.length == 1) {
      edgeData = {
        label: tempSelectedEdges[0].label,
        color: tempSelectedEdges[0].color ?
          tempSelectedEdges[0].color : DEFAULT_EDGE_COLOR,
        percentage: tempSelectedEdges[0].width ?
          parseFloat(tempSelectedEdges[0].width) * 100 : DEFAULT_EDGE_PERCENTAGE,
        line: tempSelectedEdges[0].line
      }
    }
    setSelectedEdgeData(edgeData)

    return tempSelectedEdges
  }

  function updateSelectedNodes() {
    let selectedGraphNodeIds = graph.current.getSelectedNodes()
    let tempSelectedNodes = []

    const addToSelectedNodes = (nodeId) => {
      const selectedNode =
        findNodeByGraphNodeId(nodeId)
      tempSelectedNodes.push(selectedNode)
    }

    for (let i in selectedGraphNodeIds) {
      const nodeId = selectedGraphNodeIds[i]
      if (!graph.current.isCluster(nodeId)) {
        addToSelectedNodes(nodeId)
        continue;
      }

      const nodeIdsInCluster = graph.current.getNodesInCluster(nodeId)
      for (let j in nodeIdsInCluster) {
        const clusterNodeId = nodeIdsInCluster[j]
        addToSelectedNodes(clusterNodeId)
      }
    }

    setSelectedNodes(tempSelectedNodes)
    return tempSelectedNodes
  }

  function getGraphImageBase64() {
    graph.current.fit()
    const data = domNode.current.querySelector('canvas').toDataURL()
    return data;
  }

  function getCentralNodeLabel() {
    const constellation = getCurrentConstellation()
    const centralNode = constellation.centralNode
    const { id, level } = centralNode
    let node = constellation.nodes.find(
      (node) => (node.id == id && node.level == level))
    // If central node was deleted, search for next node in the same level
    if (!node) {
      node = constellation.nodes.find(
        (node) => (node.level == level))
    }
    if (!node) {
      return "N/A"
    }
    return node.label;
  }

  function getConstellationDBObject() {
    return {
      name: getCentralNodeLabel(),
      data: getCurrentConstellation(),
      image: getGraphImageBase64()
    }
  }

  function getNodeEntity(node) {
    const constellation = getCurrentConstellation()
    const level = constellation.levels[node.level];
    return level.entity
  }

  function areNodesConnected(node1, node2) {
    const constellation = getCurrentConstellation()
    const edgeFound = constellation.edges.find(
      (edge) => (edge.from.level == node1.level
        && edge.from.id == node1.id
        && edge.to.level == node2.level
        && edge.to.id == node2.id)
        ||
        (edge.from.level == node2.level
          && edge.from.id == node2.id
          && edge.to.level == node1.level
          && edge.to.id == node1.id))
    return !edgeFound
  }

  function mustShowConnectIcon() {
    if (!selectedNodes || selectedNodes.length != 2)
      return false;
    if (!selectedEdges || selectedEdges.length == 0)
      return true
    return areNodesConnected(
      selectedNodes[0],
      selectedNodes[1]
    )
  }

  /******************************
   * Toolbar actions
   ******************************/

  function handleToolbarHelp() {
  }

  function handleToolbarCentralize() {
    let centralGraphNodeId =
      generateGraphNodeId(
        getCurrentConstellation().centralNode)

    graph.current.unselectAll();
    graph.current.selectNodes([centralGraphNodeId])
    graph.current.focus(centralGraphNodeId,
      {
        scale: 1.5,
        locked: true,
        animation: {
          duration: 1000,
          easingFunction: "easeInOutCubic"
        }
      })
  }

  function handleToolbarNodesProperties() {
    setNodesPropertiesOpen(!nodesPropertiesOpen)
  }

  function handleToolbarEdgesProperties() {
    setEdgesPropertiesOpen(!edgesPropertiesOpen)
  }

  function handleToolbarAddEntity(entity) {
    setEntitySelectionOpen(true)
    setEntitySelected(entity)
  }

  function handleToolbarUndo() {
    rewindConstellation()
  }

  function handleToolbarRedo() {
    forwardConstellation()
  }

  function handleFirstCollectionClicked(collectionId) {
    setLoading(true);
    constellationService
      .create(getConstellationDBObject())
      .then(newConstellationId => {

        setConstellationId(newConstellationId)
        setHistoricPositionSaved(historicPosition.current)
        collectionsService
          .addItem(collectionId, Constants.OBJECT_TYPE_IDS.CONSTELLATION, newConstellationId)
          .then(() => {
            updateTableCollections();
            setLoading(false);
          })
          .catch(except => {
            props.showMessageError(except.message, except.detailedMessage)
          });
      })
      .catch(except => {
        props.showMessageError(except.message, except.detailedMessage)
      });
  }

  function handleCollectionClicked(collectionId) {
    updateTableCollections();
  }

  function handleSaveConstellation() {
    setLoading(true);
    constellationService
      .save(constellationId, getConstellationDBObject())
      .then(data => {
        setHistoricPositionSaved(historicPosition.current)
        collectionsService
          .updateItem(Constants.OBJECT_TYPE_IDS.CONSTELLATION, constellationId)
          .then(() => {
            updateTableCollections();
            setLoading(false);
          })
          .catch(except => {
            props.showMessageError(except.message, except.detailedMessage)
          });
      })
      .catch(except => {
        props.showMessageError(except.message, except.detailedMessage)
      });
  }

  function handleDownloadGraphImage() {
    setLoading(true);
    const imageData = getGraphImageBase64()
    downloadBase64Data(imageData, "constellation")
    setLoading(false);
  }

  const handleToolbarRemoveEdges = () => {

    const options = {
      title: t('dialog.confirm.delete.selected'),
      confirmButtonText: t('dialog.confirm.delete.ok'),
      cancelButtonText: t('dialog.confirm.delete.cancel'),
      confirmButtonIcon: <CheckIcon />,
      cancelButtonIcon: <CancelIcon />
    }

    ConfirmDialog(confirm, t, options)
      .then(() => {
        let constellation = cloneObject(getCurrentConstellation())
        selectedEdges.forEach((edgeToDelete) => {
          let i = constellation.edges.length
          while (i--) {
            const edge = constellation.edges[i]
            const isToDelete = (edge.from.level == edgeToDelete.from.level
              && edge.from.id == edgeToDelete.from.id
              && edge.to.level == edgeToDelete.to.level
              && edge.to.id == edgeToDelete.to.id)
            if (isToDelete) {
              constellation.edges.splice(i, 1);
            }
          }
        })

        fillConstellationData(constellation)
      })
      .catch(() => { /* ... */ });
  }

  const handleToolbarConnectNodes = () => {
    setEdgesPropertiesOpen(!edgesPropertiesOpen)
  }

  function handleToolbarEdgesPropertiesConfirm(edgeProperties) {

    let constellation = cloneObject(getCurrentConstellation())

    // New connection
    if (selectedNodes.length == 2) {
      const nodeFrom = selectedNodes[0]
      const nodeTo = selectedNodes[1]
      constellation.edges.push({
        from: {
          id: nodeFrom.id,
          level: nodeFrom.level,
        },
        to: {
          id: nodeTo.id,
          level: nodeTo.level,
        },
        label: edgeProperties.label
      })
    }
    // Edit connection
    else if (selectedEdges.length == 1) {
      const selectedEdge = selectedEdges[0]
      let foundEdge = constellation.edges.find((edge) =>
        edge.from.level == selectedEdge.from.level
        && edge.from.id == selectedEdge.from.id
        && edge.to.level == selectedEdge.to.level
        && edge.to.id == selectedEdge.to.id)

      if (foundEdge) {
        foundEdge.label = edgeProperties.label
        foundEdge.color = edgeProperties.color
        foundEdge.line = edgeProperties.line
        foundEdge.width = edgeProperties.percentage / 100.0
      }
    }

    fillConstellationData(constellation)
    updateSelectedEdges()
    setEdgesPropertiesOpen(!edgesPropertiesOpen)
  }

  /******************************
   * Properties actions
   ******************************/

  function handleCloseProperties() {
    setNodesPropertiesOpen(false)
  }

  function handleConfirmDeleteNodes(nodesToDelete) {
    let constellation = cloneObject(getCurrentConstellation())
    nodesToDelete.forEach((nodeToDelete) => {
      let i = constellation.nodes.length
      while (i--) {
        const node = constellation.nodes[i]
        if (node.level == nodeToDelete.level
          && node.id == nodeToDelete.id) {
          constellation.nodes.splice(i, 1);
        }
      }
    })

    fillConstellationData(constellation)
    updateSelectedNodes()
    setNodesPropertiesOpen(false)
  }

  function handleConfirmComment(comments) {
    let constellation = cloneObject(getCurrentConstellation())
    const selectedNode = selectedNodes[0]
    const nodeFound = constellation.nodes.find(
      (node) => (selectedNode.id == node.id && selectedNode.level == node.level))
    nodeFound.comments = comments
    fillConstellationData(constellation)
    updateSelectedNodes()
    setNodesPropertiesOpen(false)
  }

  function handleViewNodeDetails(node) {
    const entity = getNodeEntity(node)
    window.open(`/#/${entity}/${node.id}`, "_blank")
  }

  function handleSearchRelationShips(node) {
    setNodesPropertiesOpen(false)
  }

  /******************************
   * External table row selection
   ******************************/

  const handleEntitySelected = (itemId) => {
    setEntitySelectionOpen(false)
    setLoading(true)

    genericService
      .getConstellationById(entitySelected, itemId)
      .then(data => {
        let merged = mergeConstellationData(
          data,
          getCurrentConstellation())
        fillConstellationData(merged)
        setLoading(false);
      })
      .catch(except => {
        props.showMessageError(except.message, except.detailedMessage)
      });

    setEntitySelected('')
  }

  const handleCancelSelect = () => {
    setEntitySelectionOpen(false)
  }

  /******************************/

  return (
    <>
      {((selectedEdges && selectedEdges.length == 1) || (mustShowConnectIcon())) &&
        <GraphEdgeEditor
          open={edgesPropertiesOpen}
          values={selectedEdgeData}
          onCancel={handleToolbarEdgesProperties}
          onConfirm={handleToolbarEdgesPropertiesConfirm} />
      }
      {
        entitySelectionOpen &&
        <EntityList
          selection={true}
          onRowSelected={handleEntitySelected}
          onCancel={handleCancelSelect}
          entity={entitySelected}
          columns={ENTITY_COLUMNS[entitySelected]}
        />
      }
      <div hidden={entitySelectionOpen} className={classes.root}>

        <ConstellationProperties
          open={nodesPropertiesOpen}
          nodes={selectedNodes}
          onConfirmDelete={handleConfirmDeleteNodes}
          onPropertiesCancel={handleCloseProperties}
          onConfirmComent={handleConfirmComment}
          onViewNodeDetails={handleViewNodeDetails}
          onSearchRelationships={handleSearchRelationShips} />

        <Grid
          container
          spacing={4}
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            className={classes.graphToolbar}
          >

            <Grid container alignItems="center" className={classes.toolbar}>

              {/*<IconButton color="inherit" onClick={handleToolbarHelp}>
              <HelpIcon fontSize="large" />
            </IconButton>
            */}

              <Tooltip title={t('button.undo')}>
                <span>
                  <IconButton color="inherit" onClick={handleToolbarUndo} disabled={undoDisabled}>
                    <UndoIcon fontSize="large" />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title={t('button.redo')}>
                <span>
                  <IconButton color="inherit" onClick={handleToolbarRedo} disabled={redoDisabled}>
                    <RedoIcon fontSize="large" />
                  </IconButton>
                </span>
              </Tooltip>

              {constellationId ?
                <>
                  <Tooltip title={t('button.save')}>
                    <span>
                      <IconButton color="inherit" onClick={handleSaveConstellation}
                        disabled={historicPositionSaved == historicPosition.current}>
                        <SaveIcon fontSize="large" />
                      </IconButton>
                    </span>
                  </Tooltip>

                  <CollectionsContext
                    itemId={constellationId}
                    objectTypeId={Constants.OBJECT_TYPE_IDS.CONSTELLATION}
                    onCollectionClicked={handleCollectionClicked}
                    collections={tableDataCollections}
                    classes={{ button: classes.iconButton }}
                  />
                </>
                :
                <SaveToCollectionContext
                  onCollectionClicked={handleFirstCollectionClicked}
                  collections={tableDataCollections}
                />
              }

              <Divider orientation="vertical" flexItem />

              <Tooltip title={t('button.centralize')}>
                <span>
                  <IconButton color="inherit" onClick={handleToolbarCentralize}>
                    <GpsFixedIcon fontSize="large" />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title={t('button.download.image')}>
                <span>
                  <IconButton color="inherit" onClick={handleDownloadGraphImage}>
                    <CameraEnhanceIcon fontSize="large" />
                  </IconButton>
                </span>
              </Tooltip>

              <Divider orientation="vertical" flexItem />

              <Tooltip title={t('button.properties')}>
                <span>
                  <IconButton color="inherit" onClick={handleToolbarNodesProperties}
                    disabled={!selectedNodes.length}>
                    <TocIcon fontSize="large" />
                  </IconButton>
                </span>
              </Tooltip>

              <AddEntityContext
                onItemClicked={handleToolbarAddEntity}
                entities={Object.keys(ENTITY_COLUMNS)}
              />

              {selectedEdges.length == 1 && selectedNodes.length == 0 &&
                <Tooltip title={t('button.edit.edge')}>
                  <span>
                    <IconButton color="inherit"
                      onClick={handleToolbarEdgesProperties}>
                      <EditIcon fontSize="large" />
                    </IconButton>
                  </span>
                </Tooltip>
              }

              {selectedEdges.length > 0 && selectedNodes.length == 0 &&
                <Tooltip title={t('button.delete.edges')}>
                  <span>
                    <IconButton color="inherit"
                      onClick={handleToolbarRemoveEdges}>
                      <DeleteIcon fontSize="large" />
                    </IconButton>
                  </span>
                </Tooltip>
              }

              {mustShowConnectIcon() &&
                <Tooltip title={t('button.connect.nodes')}>
                  <span>
                    <IconButton color="inherit"
                      onClick={handleToolbarConnectNodes}>
                      <ConnectIcon fontSize="large" />
                    </IconButton>
                  </span>
                </Tooltip>
              }
            </Grid>

          </Grid>

          <Grid
            item
            xs={12}
          >
            <LoadingSpinner loading={loading} />
            <div hidden={loading}
              className={classes.constellation}
              ref={domNode} />
          </Grid>

          <BackButton />

        </Grid>
      </div>
    </>)
};

export default withSnackbar(Constellation)
