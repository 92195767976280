import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { companiesService, publicationsService } from 'services';
import {
  Grid, TextField, Box, FormControlLabel, Checkbox
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'components/LoadingSpinner';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import BackButton from 'components/BackButton';
import RenderRelationships from 'components/RenderRelationships';
import DetailsNavigation from 'components/DetailsNavigation';
import * as Constants from 'common/systemConstants';
import CollectionsContext from 'components/CollectionsContext';
import { collectionsService } from 'services';
import { withSnackbar } from 'hooks/withSnackbar';
import { formatDate } from 'helpers/format'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Grid
            container
            spacing={4}
            justifyContent="center"
          >
            {children}
          </Grid>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const Company = (props) => {
  const { match } = props
  const { t } = useTranslation();
  const objectId = match.params.id
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const [object, setObject] = useState({
    id: '',
    name: '',
    code: '',
    head_office: '',
    created_at: ''
  });
  const [publications, setPublications] = useState([]);
  const [agencies, setAgencies] = useState();
  const [collectionsData, setCollectionsData] = useState();

  React.useEffect(() => {
    updateCollections();
  }, []);

  const updateCollections = () => {
    if(props.disableCollections)
      return
    collectionsService.getPage(
      {
        size: 100,
        page: 0,
        sort: 'name',
        order: 'asc'
      },
      false,
      'false'
    )
      .then((data) => {
        setCollectionsData(data)
      })
      .catch((except) => {
        props.showMessageError(except.message, except.detailedMessage)
      });
  }

  const handleClickOnCollection = () => {
    updateCollections()
  };

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  function handleOnItemChanged(item) {
    setObject(item)
    setAgencies()
    setPublications([])
    if (item && item.properties && item.properties.publicationList) {
      companiesService
        .getRelatedAgencies(item.id)
        .then(agencies => {
          setAgencies(agencies)
        })
      let publicationList = item.properties.publicationList.map(row => {return row.publication_id})
      publicationsService
        .getByIds(publicationList)
        .then(publications => {
          setPublications(publications)
        })
    }
  }

  return (
    <div className={classes.root}>

      <div className={classes.tableHeader}>
        <Box p={2} display={(!object) ? 'none' : 'block'}>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={12}>
              <DetailsNavigation
                onItemChanged={handleOnItemChanged}
                entity={'company'}
                initialItemId={objectId}/>
            </Grid>
          </Grid>
        </Box>

        {!object ?
          <LoadingSpinner loading={!object} color="inherit" size={25} />
          :
          <Box p={2}>

            <Grid
              container
              spacing={4}
              justifyContent="center"
            >
              <Grid
                item
                xs={2}
                md={1}
              >
                <CollectionsContext
                    collections = {collectionsData}
                    itemId={parseInt(objectId)}
                    objectTypeId={Constants.OBJECT_TYPE_IDS.COMPANY}
                    onCollectionClicked = {handleClickOnCollection}
                  />
              </Grid>

              <Grid
                item
                xs={10}
                md={2}
              >
                <TextField
                  fullWidth
                  margin="dense"
                  onChange={handleChange}
                  required
                  variant="standard"
                  InputProps={{ readOnly: true }}
                  label="Id"
                  name="id"
                  value={object.id}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={5}
              >
                <TextField
                  fullWidth
                  margin="dense"
                  onChange={handleChange}
                  required
                  variant="standard"
                  InputProps={{ readOnly: true }}
                  label="Nome"
                  name="type"
                  value={object.name}
                />
              </Grid>

              <Grid
                item
                xs={2}
                md={1}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={(object.head_office == '1') ? true : false}
                      color="primary"
                      onChange={handleChange}
                      name="head_office"
                      value={(object.head_office == '1') ? true : false}
                    />
                  }
                  label="Matriz"
                />

              </Grid>

              <Grid
                item
                xs={10}
                md={1}
              >
                <TextField
                  fullWidth
                  margin="dense"
                  onChange={handleChange}
                  variant="standard"
                  InputProps={{ readOnly: true }}
                  label="CPF/CNPJ"
                  name="when"
                  value={object.code}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={2}
              >
                <TextField
                  onChange={handleChange}
                  fullWidth
                  variant="standard"
                  InputProps={{ readOnly: true }}
                  label={t('label.obtained_at')}
                  name="message"
                  value={formatDate(object.created_at)}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <RenderRelationships entity={"company"} id={objectId} classes={classes} />
              </Grid>

              <BackButton />

            </Grid>
          </Box>
        }

      </div>

    </div>
  );
};

export default withSnackbar(withRouter(Company));
