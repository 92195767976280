
import config from 'config';
import { BehaviorSubject } from 'rxjs';
import { handleResponse } from 'helpers';

let jsonParseable = null
try {
    JSON.parse(localStorage.getItem('currentUser'))
} catch (e) {
    localStorage.setItem('currentUser', '{}') 
}

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const usersService = {
    login,
    logout,
    currentUser: (currentUserSubject) ? currentUserSubject.asObservable() : null,
    get currentUserValue () { return (currentUserSubject) ? currentUserSubject.value : null},
    setCurrentUser,
    getRole
};

function getRole(keycloak){
  let roles = []
  if (keycloak && keycloak.realmAccess && keycloak.realmAccess.roles){
    roles = keycloak.realmAccess.roles.filter((role) => {
        return (role !== 'offline_access') && (role !== 'uma_authorization') ;
    })
  }
  return (roles) ? roles : "Sem perfil"
}

function setCurrentUser(user){
    localStorage.setItem('currentUser', JSON.stringify(user));
    currentUserSubject.next(user);
}

function login(email, passwd) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, passwd })
    };

    return fetch(`${config.apiUrl}/users/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}

function getAll() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/user/${id}`, requestOptions).then(handleResponse);
}