import { Button, Card, CardActions, CardContent, CardHeader, Collapse, Divider, Drawer, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, TextField, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Delete as DeleteIcon, List as ListIcon, OpenInNew as OpenInNewIcon, Link as RelationshipsIcon, Undo as UndoIcon } from '@material-ui/icons';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { JsonTreeView } from 'components';

const useStyles = makeStyles(theme => ({
  drawerContent: {
    width: '350px',
    padding: theme.spacing(1)
  },
  cardContent: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0
    }
  }
}));

const NodeItem = (props) => {
  const { t } = useTranslation();
  const {deleteChecked, node} = props
  const [propertiesOpen, setPropertiesOpen] = useState(false);

  const handleClick = () => {
    setPropertiesOpen(!propertiesOpen)
  };

  return (
    <>
      <Divider/>
      <ListItem
          alignItems="flex-start"
          selected={deleteChecked}
          button={!!node.data}
          onClick={handleClick}>
        <ListItemText
          primary={<Typography variant="subtitle1">{node.label}</Typography>}
          secondary={<Typography variant="body1">{node.sub_label}</Typography>}
        />
        <ListItemSecondaryAction>
          { !!node.data &&
          <Tooltip title={t('button.view.details')}>
            <IconButton edge="end" aria-label="delete" onClick={() => props.onViewNodeDetails(node)}>
              <ListIcon />
            </IconButton>
          </Tooltip>
          }
          {
            deleteChecked ?
              <Tooltip title={t('button.undo.delete')}>
                <IconButton edge="end" aria-label="delete" onClick={() => props.onUncheckDeleteNode(node)}>
                  <UndoIcon />
                </IconButton>
              </Tooltip>
              :
              <Tooltip title={t('button.delete')}>
                <IconButton edge="end" aria-label="delete" onClick={() => props.onCheckDeleteNode(node)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
          }

        </ListItemSecondaryAction>
      </ListItem>
      {
      node.data &&
      <>
        <Collapse in={propertiesOpen} unmountOnExit>
          <List component="div" disablePadding>
            <Divider/>
            <ListItem selected={true}>
              <JsonTreeView
                startExpanded={false}
                json={node.data}
                capitalizeLabels={true}
                emptyLabel={t('jobs.info.empty')}/>
            </ListItem>
          </List>
        </Collapse>
      </>
      }
    </>);
}

NodeItem.propTypes = {
  node: PropTypes.object,
  deleteChecked: PropTypes.bool,
  onCheckDeleteNode: PropTypes.func,
  onUncheckDeleteNode: PropTypes.func,
  onViewNodeDetails: PropTypes.func,
};

const ConstellationProperties = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [nodesToDelete, setNodesToDelete] = useState([]);
  const [comments, setComments] = useState();
  const [commentsOriginal, setCommentsOriginal] = useState();

  React.useEffect(() => {
    if(props.nodes.length == 1) {
      const tempComments = props.nodes[0].comments
      setComments(tempComments)
      setCommentsOriginal(tempComments)
    }
    setNodesToDelete([])
  }, [props.nodes]);

  const handleCloseDrawer = () => (event) => {
    if (event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    handleCancel()
  };

  function handleCancel() {
    setNodesToDelete([])
    props.onPropertiesCancel()
  }

  function handleConfirm() {
    if(nodesToDelete.length) {
      props.onConfirmDelete(nodesToDelete)
    } else if(isCommentsChanged()) {
      props.onConfirmComent(comments)
    }
  }

  function handleDeleteNode(node) {
    let tempToDelete = [...nodesToDelete]
    tempToDelete.push(node)
    setNodesToDelete(tempToDelete)
  }

  function handleUndoDeleteNode(node){
    let tempToDelete = [...nodesToDelete]
    for(let i in tempToDelete) {
      if(tempToDelete[i].level === node.level
          && tempToDelete[i].id === node.id ) {
        tempToDelete.splice(i, 1)
        break;
      }
    }
    setNodesToDelete(tempToDelete)
  }

  const handleCommentsChange = (event) => {
    setComments(event.target.value)
  };

  function isCommentsChanged() {
    if(!commentsOriginal && !comments)
      return false
    return commentsOriginal != comments
  }

  return (
      <Drawer
        anchor={"right"}
        open={props.open}
        onClose={handleCloseDrawer()}>
        <div className={classes.drawerContent}>

          <Card>
            <CardHeader title={t("label.properties")}/>

              <CardContent classes={{root: classes.cardContent}}>

              {(props.nodes.length > 0) ?
                <List>
                  {
                    props.nodes.map((node, index) => {

                      let isToDeleteNode = nodesToDelete.find(item => {
                        return item.level === node.level
                                && item.id === node.id
                      })

                      return (
                        <NodeItem
                            key={`${node.level}_${node.id}`}
                            deleteChecked={!!isToDeleteNode}
                            node={node}
                            onCheckDeleteNode={handleDeleteNode}
                            onUncheckDeleteNode={handleUndoDeleteNode}
                            onViewNodeDetails={props.onViewNodeDetails}
                            />
                      );

                    })}

                  { props.nodes.length == 1 &&
                  <>
                    { false && props.nodes[0].data && //TODO: search for relationships
                    <>
                      <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        onClick={() => { props.onSearchRelationships(props.nodes[0]) } }
                        startIcon={<RelationshipsIcon />}
                      >
                        {t('button.search.relationships')}
                      </Button>
                    </>
                    }

                    <Divider/>
                    <ListItem alignItems="flex-start" >
                      <TextField
                        fullWidth
                        multiline
                        margin="dense"
                        variant="outlined"
                        type={'text'}
                        label={t('label.comments')}
                        onChange={handleCommentsChange}
                        value={comments}
                        name={'comments'}
                        rows={4}
                        inputProps={ {maxLength: 200} }
                      />
                    </ListItem>
                  </>
                  }
                </List>
                :
                <List>
                  <Divider/>
                  <ListItem alignItems="flex-start" >
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1">
                            {t('label.empty.items')}
                          </Typography>}
                      />
                  </ListItem>
                </List>
              }

            </CardContent>

              { ((nodesToDelete.length > 0) || isCommentsChanged())&&
                <>
                  <Divider/>
                  <CardActions>
                    <Button onClick={handleCancel}>
                        {t('button.cancel')}
                    </Button>
                    <Button
                      onClick={handleConfirm}
                      color="primary"
                      variant="contained"
                      >
                        {t('button.save')}
                    </Button>
                  </CardActions>
                </>
              }
          </Card>
        </div>
      </Drawer>
  );
};

ConstellationProperties.propTypes = {
  open: PropTypes.bool,
  nodes: PropTypes.array,
  onConfirmDelete: PropTypes.func,
  onPropertiesCancel: PropTypes.func,
  onConfirmComent: PropTypes.func,
  onViewNodeDetails: PropTypes.func,
  onSearchRelationships: PropTypes.func,
};

export default ConstellationProperties;