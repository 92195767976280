import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { withKeycloak } from '@react-keycloak/web';
import PropTypes from 'prop-types';
import loginBackgroundImage from "public/images/login-background.jpg";
import React from 'react';
import { useTranslation } from 'react-i18next';
import  { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import { Footer } from 'components';

import { usersService } from '../../services';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    /*backgroundColor: theme.palette.neutral,*/
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${loginBackgroundImage})`,
    backgroundColor: 'rgba(98, 83, 0, 0.8)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundBlendMode: 'multiply'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentBody: {
    padding: theme.spacing(4),
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  title: {
    marginBottom: theme.spacing(4)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  footer: {
    paddingTop: theme.spacing(5),
  }
}));

const Login = ({ keycloak }) => {

  const { t } = useTranslation();
  const classes = useStyles();

  const handleSignIn = () => (event) => {        
    event.preventDefault();
    keycloak.login();
  };

  if (keycloak && keycloak.authenticated) {
    const user = {
      name: keycloak.tokenParsed.preferred_username,
      position: usersService.getRole(keycloak),
      token: keycloak.token,
      id: keycloak.subject
    }
    usersService.setCurrentUser(user)
    return <Redirect to='/home'  />
  }

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography
                className={classes.quoteText}
                variant="h1"
              >
                {t('login.song')}
              </Typography>
              <Typography
                className={classes.name}
                variant="body1"
              >
                {t('login.song.title')}
              </Typography>
              <Typography
                className={classes.bio}
                variant="body2"
              >
                {t('login.song.artist')}
              </Typography>
            </div>
          </div>
        </Grid>

        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>         

            <div className={classes.contentBody}>

              <Grid
                container
              >                
                <Grid
                  item
                  xs={12}
                >
                  <Typography
                    className={classes.title}
                    variant="h2"
                  >
                    {t('login.access')}
                  </Typography>              
                </Grid>

                
                <Grid
                  item
                  xs={12}
                >
                  <Button
                    className={classes.signInButton}
                    fullWidth
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"                
                    onClick={handleSignIn()}
                  >
                    {t('button.enter')}
                  </Button>  
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Footer className={classes.footer} />
                </Grid>

              </Grid>               
            </div>   
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.object
};

export default withKeycloak(Login);