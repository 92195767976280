
import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { logsService } from 'services';
import {
  Card, CardHeader, CardContent, Grid, FormControl, TextField, Tooltip, Button, Select, MenuItem, Typography
} from '@material-ui/core';
import { Refresh, Pause, PlayArrow, FilterNone as CopyIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'components/LoadingSpinner';
import { capitalize } from 'helpers/format'
import { withSnackbar } from 'hooks/withSnackbar';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
}));

const AVAILABLE_SERVICES = [
  'backend',
  'searcher',
  'jobs',
  'classifiers',
  'crawlers'
]

var isAlreadyRequesting = false
const Logs = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const waitTime = 10;
  const [remainTime, setRemainTime] = useState(10);
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [serviceSelected, setServiceSelected] = useState(AVAILABLE_SERVICES[0]);
  const service = useRef(AVAILABLE_SERVICES[0]);
  const [paused, setPaused] = useState(false);
  const pausedRef = useRef(false);

  const handleServiceChange = event => {
    const value = event.target.value
    setServiceSelected(value)
    service.current = value
  };

  async function loadLog(force){
    if(!force && isAlreadyRequesting)
      return
    isAlreadyRequesting = true
    setLoading(true)
    logsService.getBackendLog(service.current)
      .then(logs => {
        setContent(logs.content)
        setLoading(false)
      })
      .finally(()=>{
        isAlreadyRequesting = false
      })
  }

  function updateLogs() {
    loadLog(true)
  }

  function togglePlayPause() {
    pausedRef.current = !pausedRef.current
    setPaused(!paused)
  }

  function copyLog() {
    navigator.clipboard.writeText(content)
    props.showMessageSuccess(t('label.copied'))
  }

  React.useEffect(() => {
      loadLog()
      const intervalPromise = setInterval(()=>{
        let remaining = new Date().toJSON().split('T')[1].split('.')[0].split(':')[2] % waitTime
        setRemainTime(waitTime - remaining)
        if (remaining == 0 && !pausedRef.current){
          loadLog()
        }
      }, 1000)

      return()=> {
        clearInterval(intervalPromise)
      }
  }, [])

  React.useEffect(() => {
    loadLog(true)
  }, [serviceSelected])

  return (
    <div className={classes.root}>
      <Card>
        <CardHeader
          title={`${t('label.nextUpdate')}: ` + (paused ? t('label.paused') : `${remainTime}s`)}
        />
        <CardContent>
          <Grid
            container
            spacing={1}
            alignContent="center"
          >
            <Grid
              item
              xs={10}
              md={4}
            >
              <Typography variant="h6" style={{marginTop:"10px", marginBottom:"10px"}}>
                {t('label.select.service')}
              </Typography>

              <FormControl fullWidth >
                <Select
                  fullWidth
                  onChange={handleServiceChange}
                  value={serviceSelected}
                  variant="standard"
                >
                  {
                    AVAILABLE_SERVICES.map(item =>
                      <MenuItem key={item} value={item}>{capitalize(item)}</MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={2}
              md={3}
            >
              <Tooltip title={t("button.refresh")}>
                <Button onClick={()=>updateLogs()}>
                  <Refresh />
                </Button>
              </Tooltip>
              <Tooltip title={ paused ? t("button.continue") : t("button.pause")}>
                <Button onClick={()=>togglePlayPause()}>
                  { paused ?  <PlayArrow /> : <Pause /> }
                </Button>
              </Tooltip>
              <Tooltip title={t("button.copy.clipboard")}>
                <Button onClick={()=>copyLog()}>
                  <CopyIcon />
                </Button>
              </Tooltip>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
            >
              {loading ?
                <LoadingSpinner loading={true} />
                :
                <TextField
                  style={{width: '100%'}}
                  type="text"
                  value={content}
                  InputProps={{
                    readOnly: true,
                  }}
                  multiline
                  maxRows={50}
                  />
              }
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default withSnackbar(Logs);