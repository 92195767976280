import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { spheresService } from 'services';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ListIcon from '@material-ui/icons/List';
import { ErrorOccurred, NoResultsFound, SearchInput } from 'components';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableSortLabel,
  TableHead,
  TableRow,
  Tooltip,
  Grid,
  IconButton,
  Box,
  Typography,
  CardHeader,
  Button
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as Constants from 'common/systemConstants';
import LoadingSpinner from 'components/LoadingSpinner';
import { Add, Delete, Edit, PlayCircleFilled } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import BackButton from 'components/BackButton';
import CustomLink from 'components/CustomLink';
import CustomTablePagination from 'components/CustomTablePagination';
import ConfirmDialog from 'components/ConfirmDialog'
import { useConfirm } from 'material-ui-confirm';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  tableContent: {
    marginTop: theme.spacing(2)
  },
  toolbar: {
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  spacer: {
    flexGrow: 1
  },
  formControl: {
    width: '100%'
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const DEFAULT_SORT_COLUMN = "name";
const DEFAULT_ORDER = Constants.ORDER_ASC;

const Sphere = () => {
  const [loading, setLoading] = useState();
  const { t } = useTranslation();
  const classes = useStyles();
  const [tableData, setTableData] = useState();
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [filters, setFilters] = useState({
    name: '',
  });
  const [pagination, setPagination] = useState({
    size: Constants.DEFAULT_ROWS_SIZE,
    page: 0,
    sort: DEFAULT_SORT_COLUMN,
    order: DEFAULT_ORDER
  });
  React.useEffect(() => {
    updateTable();
  }, [pagination, filters]);

  function updateTable () {
    setLoading(true)
    spheresService.getPage(
      pagination,
      filters.description,
    ).then(tableData => {
      if (tableData.error){
        setErrorOccurred(tableData.error);
      } else {
        setErrorOccurred(false);
        setTableData(tableData);
      }
      setLoading(false)
    })
    .catch((e)=>{setErrorOccurred(true);setLoading(false)})
  }

  const handleFilterChange = event => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value
    });
  };
  const handlePaginationSizeChange = event => {
    setPagination({
      ...pagination,
      ["size"]: event.target.value
    });
  };
  const handlePaginationPageChange = (event, page) => {
    setPagination({
      ...pagination,
      ["page"]: page
    });
  };
  const handlePaginationSortChange = (property) => (event) => {
    let order = Constants.ORDER_ASC;
    if(property === pagination.sort
      && pagination.order == Constants.ORDER_ASC) {
        order = Constants.ORDER_DESC;
    }
    setPagination({
      ...pagination,
      ["sort"]: property,
      ["order"]: order
    });
  };

  const confirm = useConfirm();

  const handleRemove = (id) => {
    ConfirmDialog(confirm, t)
      .then(() => {
        spheresService.remove(id).then(updateTable)
       })
      .catch(() => { /* ... */ });
  }

  return (
    <div className={classes.root}>
          <Box mx={0} my={2}>
            <Grid container justifyContent = "flex-end">
                  <CustomLink
                    to="sphere/create"
                  >
                    <Button
                    color="primary"
                    variant="contained">
                      <Add /> {t('button.add')}
                    </Button>
                  </CustomLink>
            </Grid>
          </Box>
          <Card>
          <CardContent className={classes.content}>
            <PerfectScrollbar>
              <div className={classes.inner}>
                  <LoadingSpinner loading={loading} />
                  <NoResultsFound show={(!loading && typeof tableData != "undefined" && tableData.count == 0)}/>
                  <ErrorOccurred display={(!loading && errorOccurred!=false)?"block":"none"} msg={errorOccurred}/>
                  <Box component="div" display={(!loading && typeof tableData != "undefined"  && tableData.count > 0)?"block":"none"}>
                    <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                            <TableSortLabel
                              active={pagination.sort === "id"}
                              direction={pagination.order}
                              onClick={handlePaginationSortChange("id")}
                            >
                              {t('table.label.sphere.id')}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={pagination.sort === "name"}
                            direction={pagination.order}
                            onClick={handlePaginationSortChange("name")}
                          >
                            {t('table.label.sphere.name')}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">{t('table.label.sphere.actions')}</TableCell>
                      </TableRow>
                    </TableHead>
                      <TableBody>
                        {((typeof tableData != "undefined") && tableData.count > 0) &&
                            tableData.data.map(row => (
                              <TableRow
                                className={classes.tableRow}
                                hover
                                key={row.id}
                              >
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell align="center">
                                    <Tooltip title={t('button.edit')} aria-label={t('button.edit')}>
                                      <CustomLink to={'sphere/' + row.id+'/edit'}>
                                        <IconButton className={classes.topBarButtons} size="small">
                                          <Edit />
                                        </IconButton>
                                      </CustomLink>
                                    </Tooltip>
                                    <Tooltip title={t('button.remove')} aria-label={t('button.remove')}>
                                        <IconButton className={classes.topBarButtons} size="small">
                                          <Delete onClick={()=>handleRemove(row.id)} />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                      </TableBody>
                      </Table>
                  </Box>
              </div>
            </PerfectScrollbar>
          </CardContent>
          <CardActions className={classes.actions}>
            { (typeof tableData != "undefined" && tableData.count > 0) &&
                <CustomTablePagination
                  component="div"
                  count={tableData.total}
                  onPageChange={handlePaginationPageChange}
                  onRowsPerPageChange={handlePaginationSizeChange}
                  page={pagination.page}
                  rowsPerPage={pagination.size}
                  rowsPerPageOptions={Constants.DEFAULT_ROWS_SIZE_OPTIONS}
                />
            }
          </CardActions>
        </Card>
        <Box mt={3}>
          <Grid
              container
              spacing={4}
            >
              <BackButton />
          </Grid>
        </Box>
    </div>
  //);
  )
  }
export default withRouter(Sphere);