import React from 'react';

const regex = /(<([^>]+)>)/ig;

export function removeHtml(value) {
  if(!value){
    return value
  }
  return value.replace(regex, ' ');
}

export const LabelRemoveHtml = ({ value }) => {
  return (<> {removeHtml(value)} </>)
}
