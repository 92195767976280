import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import BuildIcon from '@material-ui/icons/Build';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { Card } from '@material-ui/core';
import Batch from './components/Batch/Batch';
import Execution from './components/Execution/Execution';
import { useTranslation } from 'react-i18next';
import { SettingsBackupRestore } from '@material-ui/icons';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConfigurationForm } from './components/Configuration';
import { Configurations }  from './components/Configuration';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function JobConfig(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
      <Card>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="secondary"
            textColor="primary"
            aria-label="scrollable force tabs example"
          >
            <Tab label={t('settings.jobs.configuration')}
                component={Link}
                to="/jobs/config"
                value="/jobs/config"
                icon={<BuildIcon />}
            />

            <Tab label={t('settings.jobs.executions')}
                component={Link}
                to="/jobs/executions"
                value="/jobs/executions"
                icon={<SettingsBackupRestore />}
            />

            <Tab label={t('settings.jobs.batches')}
                component={Link}
                to="/jobs/batches"
                value="/jobs/batches"
                icon={<PlayCircleOutlineIcon />}
            />

          </Tabs>
        </AppBar>

        <Switch>

          <Route path="/jobs/config/create">
            <ConfigurationForm />
          </Route>

          <Route path="/jobs/config/:id/edit">
            <ConfigurationForm />
          </Route>

          <Route exact path="/jobs/config">
            <Configurations />
          </Route>

          <Route exact path="/jobs/executions">
            <Execution />
          </Route>

          <Route exact path="/jobs/batches">
            <Batch />
          </Route>

          <Route exact path="/jobs/">
            <Redirect to="/jobs/config" />
          </Route>

          <Redirect to="/not-found" />

        </Switch>
      </Card>
  );
}