import { usersService } from 'services';

export function authHeader() {
    // return authorization header with jwt token
    const currentUser = usersService.currentUserValue;
    if (currentUser && currentUser.token) {
        return { Authorization: `Bearer ${currentUser.token}` , 'Content-Type': 'application/json' };
    } else {
        return {};
    }
}