import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Tooltip, IconButton, CardActions, Card, CardContent, Grid, Typography, Avatar, Box, Popover, CardActionArea  } from '@material-ui/core';
import LoadingSpinner from 'components/LoadingSpinner';
import LinkIcon from '@material-ui/icons/Link';
import CustomLink from 'components/CustomLink';
import { LabelNumberSeparator } from 'components';
import theme from 'theme';
import { Timer } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'helpers/format'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: '#585858',
    color: theme.palette.primary.contrastText
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  avatar: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  }
}));

const CountCompaniesAndAgenciesRelationship = props => {
  const { className, data, ...rest } = props;
  const { t } = useTranslation();

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <LoadingSpinner loading={(data === false) ? true : false} color="inherit" size={25} />
        <Box p={2} display={(data === false) ? 'none' : 'block'}>
          <Typography
            color="inherit"
            variant="h5"
          >
            {t('label.updated.at')}
            {(data) ? formatDate(data.result.content.date, "DD/MM HH:mm:ss") : ""}
          </Typography>
        </Box>
      </Popover>
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
        >
            <Grid item>

              <Typography
                className={classes.title}
                color="inherit"
                gutterBottom
                variant="body2"
              >
                {t('dashboard.count.agencies.companies.relations')}
              </Typography>
              <Typography
                color="inherit"
                variant="h3"
              >
                <LabelNumberSeparator value={(data)?data.result.content.countRelationshipsAgenciesCompanies:null}/>
              </Typography>
            </Grid>
            <Grid item>
              <LoadingSpinner loading={(typeof data == 'undefined')?true:false} color="inherit" size={25}/>
              <Box display={(typeof data == 'undefined')?'none':'block'}>
                <Avatar className={classes.avatar}>
                  <LinkIcon className={classes.icon} />
                </Avatar>
              </Box>
            </Grid>

          </Grid>

      </CardContent>
      <CardActions disableSpacing>
        <Tooltip title={t('label.updated_at')}>
          <IconButton onClick={handleClick} className={classes.root}>
            <Timer fontSize="medium" />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

CountCompaniesAndAgenciesRelationship.propTypes = {
  className: PropTypes.string
};

export default CountCompaniesAndAgenciesRelationship;
