import { ListItem, ListItemText , List, ListItemAvatar, Divider, Avatar, Card, CardContent, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { AccountCircle as AccountCircleIcon, HomeWork as HomeWorkIcon } from "@material-ui/icons"

import LOGO_PF from 'public/images/logos/logo-policia-federal.png';
import LOGO_UNB from 'public/images/logos/logo-unb.png';

const ABOUT = "O projeto Deepvacuity tem como principal objetivo auxiliar na perícia e gestão criminalística, utilizando-se de métodos baseados em aprendizado supervisionado de máquina."

const PARTNERS = [
  {
    title: 'Polícia Federal do Brasil',
    imageSource: LOGO_PF
  },
  {
    title: 'Universidade de Brasília',
    imageSource: LOGO_UNB
  },
]

const CREDITS = [
  {
    group: "Coordenação e Pesquisa",
    icon: <AccountCircleIcon />,
    members: [
      {
        title: "Aletéia Patrícia Favacho de Araújo von Paumgartten",
        subtitle: "UnB"
      },
      {
        title: "Flavio de Barros Vidal",
        subtitle: "UnB"
      }
    ]
  },
  {
    group: "Desenvolvimento de Machine Learning e Pesquisa",
    icon: <AccountCircleIcon />,
    members: [
      {
        title: "Marcos Cavalcanti Lima",
        subtitle: "PF / UnB"
      },
      {
        title: "Roberta Costa Silva",
        subtitle: "UnB"
      },
      {
        title: "Thiago Luís",
        subtitle: "UnB"
      }
    ]
  },
  {
    group: "Desenvolvimento e Pesquisa",
    icon: <AccountCircleIcon />,
    members: [
      {
        title: "Felipe Lopes de Souza Mendes",
        subtitle: "UnB"
      },
      {
        title: "Jefferson Chaves Gomes",
        subtitle: "UnB"
      },
      {
        title: "Leonardo Rebouças de Carvalho",
        subtitle: "UnB"
      },
      {
        title: "Marco Antonio de Sousa Reis",
        subtitle: "UnB"
      }
    ]
  }
]


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  avatar: {
    height: 125,
  },
  details: {
    display: 'flex'
  },
  listItemTextPrimary: {

  }
}));

const CardLogoText = (props) => {
  const classes = useStyles();
  return (
    <Card raised={true}>
      <CardContent>
        <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
          <img
            src={props.imageSource}
            className={classes.avatar}
          />
        </div>
      </CardContent>
      <Divider/>
      <CardContent>
        <Typography variant='h5' align="center" >
          {props.title}
        </Typography>
      </CardContent>
    </Card>
  );
}

const About = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (

    <div className={classes.root}>

      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          xs={12}
        >
          <Card raised={false}>
            <CardContent>
              <Typography variant="h5">
                {ABOUT}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          md={3}
          xs={12}
        >
          <Grid
            container
            spacing={2}
          >
            {PARTNERS.map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
              >
                <CardLogoText
                  title={item.title}
                  imageSource={item.imageSource}/>

              </Grid>
            ))}
          </Grid>

        </Grid>
        <Grid
          item
          md={9}
          xs={12}
        >
          <Card raised={false}>
            <CardContent>

                {CREDITS.map((creditItem, creditIndex) =>
                    <React.Fragment key={creditIndex}>
                      <Typography variant="h5" className={classes.title}>
                        {creditItem.group}:
                      </Typography>

                      <div className={classes.credits}>

                        <List dense={true}>

                          {creditItem.members.map((memberItem, memberIndex) =>
                            <ListItem key={memberIndex}>
                              <ListItemAvatar>
                                <Avatar>
                                  {creditItem.icon}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <Typography variant="h6" display="block" gutterBottom>
                                    {memberItem.title}
                                  </Typography>
                                }
                                secondary={
                                  <Typography variant="body1" display="block" gutterBottom>
                                    {memberItem.subtitle}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          )}
                        </List>
                      </div>
                    </React.Fragment>
                )}

            </CardContent>
          </Card>

        </Grid>

      </Grid>
    </div>
  )
};


export default About;
