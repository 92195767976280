import { Cancel as CancelIcon, Check as CheckIcon } from "@material-ui/icons"
import React from 'react';

const ConfirmDialog = (confirm, t, options) =>{

    if(!options) {
        options = {
            title: t('dialog.confirm.delete.title'),
            description: t('dialog.confirm.delete.description'),
            confirmButtonText: t('dialog.confirm.delete.ok'),
            cancelButtonText: t('dialog.confirm.delete.cancel'),
            confirmButtonIcon: <CheckIcon/>,
            cancelButtonIcon: <CancelIcon/>
        }
    }

    let confirmDialog = {
        title: options.title,
        description: options.description,
        confirmationText: options.confirmButtonText, 
        cancellationText: options.cancelButtonText, 
        confirmationButtonProps: {variant:"contained"},
        cancellationButtonProps: {variant:"contained"}
    }

    if(options.confirmButtonIcon) {
        confirmDialog.confirmationButtonProps.startIcon = options.confirmButtonIcon
    }

    if(options.cancelButtonIcon) {
        confirmDialog.cancellationButtonProps.startIcon = options.cancelButtonIcon
    }

    return confirm(confirmDialog)
}
export default ConfirmDialog