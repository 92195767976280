import { Button, Divider, CardActions, Box, Card, IconButton, CardHeader, CardContent, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactJson from "react-json-view";
import { Edit as EditIcon} from '@material-ui/icons';

const PropertiesTable = (props) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [jsonData, setJsonData] = useState(props.jsonData);

  const onDataUpdated = (e) => {
    setJsonData(e.updated_src)
  }

  const onEditData = (e) => {
    setIsEditing(true)
  }

  const onCancel = (e) => {
    setIsEditing(false)
    setJsonData(props.jsonData)
  }

  const onSave = (e) => {
    setIsEditing(false)
    props.onJsonDataUpdated(jsonData)
  }

  React.useEffect(() => {
    setJsonData(props.jsonData)
  }, [props.jsonData]);

  return (
    <Box my={2}>
        <Card>
            <CardHeader title={props.title}
                action={
                    <div hidden={isEditing}>
                        <IconButton onClick={onEditData}>
                            <EditIcon />
                        </IconButton>
                    </div>
                }
            />

            <CardContent>

                {isEditing ?
                    <ReactJson
                        name=""
                        style={{width:"100%"}}
                        src={jsonData}
                        onEdit={onDataUpdated}
                        onAdd={onDataUpdated}
                        onDelete={onDataUpdated}
                        displayDataTypes={false}
                        displayArrayKey={false}
                        quotesOnKeys={false}
                    />
                    :
                    <Card>
                        <CardContent>
                            { jsonData ?
                                <PerfectScrollbar>
                                    <div>
                                        <Table>
                                        <TableHead>
                                            <TableRow>
                                            <TableCell>
                                                {t('label.property')}
                                            </TableCell>
                                            <TableCell>
                                                {t('label.content')}
                                            </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            { Object.entries(jsonData).map(([property, value]) =>
                                                <TableRow
                                                    hover
                                                    key={property}
                                                >
                                                    <TableCell>
                                                        {property}
                                                    </TableCell>
                                                    <TableCell>
                                                        {JSON.stringify(value)}
                                                    </TableCell>
                                                </TableRow>
                                            ) }
                                        </TableBody>
                                        </Table>
                                    </div>
                                </PerfectScrollbar>
                                :
                                <Typography>{t('table.result.none')}</Typography>
                            }
                        </CardContent>
                    </Card>
                }
            </CardContent>

            { isEditing &&
            <>
                <Divider/>
                <CardActions>
                    <Button
                        color="primary"
                        onClick={onCancel}>
                        {t('button.cancel')}
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={onSave}>
                        {t('button.save')}
                    </Button>
                </CardActions>
            </>
            }

        </Card>
    </Box>
  );
};

PropertiesTable.propTypes = {
    title: PropTypes.string,
    jsonData: PropTypes.object,
    onJsonDataUpdated: PropTypes.func,
};

export default PropertiesTable;
