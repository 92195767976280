import config from 'config';
import { authHeader, handleResponse } from 'helpers';
import { createPaginationObject } from 'helpers/pagination';
import { objectToQueryString } from 'helpers/url';

export const companiesService = {
    getById,
    getPage,
    getCount,
    getRelatedAgencies
};

function getById(id) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/company/${id}`, requestOptions).then(handleResponse);
}

function getRelatedAgencies(id) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/company/${id}/relatedAgencies`, requestOptions).then(handleResponse);
}

function getCount() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/companies/getCount`, requestOptions).then(handleResponse);
}

function getPage(
    pagination,
    filterByName, filterByCode, filterByHeadOffice) {

    let paginationObj = createPaginationObject(
        pagination.page, pagination.size, pagination.sort, pagination.order);
    if(filterByName) {
        paginationObj.name = filterByName;
    }
    if(filterByCode) {
        paginationObj.code = filterByCode;
    }    
    if(filterByHeadOffice != null) {
        paginationObj.head_office = filterByHeadOffice;
    }
    let queryString = objectToQueryString(paginationObj);
    let url = `${config.apiUrl}/companies` + (queryString && ("?" + queryString));
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(url, requestOptions).then(handleResponse);
}