import { IconButton, Box, Divider, Button, CardActions, Link, Typography, CardContent, Card, Grid, FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { settingsService } from 'services';
import { withSnackbar } from 'hooks/withSnackbar';
import * as Constants from 'common/systemConstants';
import { formatDate } from 'helpers/format'
import { History as HistoryIcon} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  formControl: {
    width: '100%'
  },
  buttonHistory: {
    marginRight: theme.spacing(2)
  }
}));

const Settings = (props) => {

  const { match } = props
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const [settingName, setSettingName] = useState(Constants.OPTION_EMPTY);
  const [settings, setSettings] = useState([]);
  const [currentSetting, setCurrentSetting] = useState(null);

  const [values, setValues] = useState({
    settingValue: "",
    changeDescription: ""
  });

  React.useEffect(() => {
    settingsService.getAll()
      .then((data) => {
        setSettings(data)
      })
      .catch((except) => {
        props.showMessageError(except.message, except.detailedMessage)
      });
  }, []);

  const handleClickDefault = () => {
    setValues({
      ...values,
      settingValue: currentSetting.default_value
    });
  };

  const handleOpenHistory = () => {
    history.push("/settings/history")
  };

  const handleValueChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSettingChange = event => {
    let name = event.target.value
    setSettingName(name)
    setValues({
      ...values,
      settingValue: ""
    });
    setCurrentSetting(null)
    if(name === Constants.OPTION_EMPTY){
      return
    }

    settingsService.getByName(name)
      .then((data) => {
        setValues({
          ...values,
          settingValue: data.value
        });
        setCurrentSetting(data)
      })
      .catch((except) => {
        props.showMessageError(except.message, except.detailedMessage)
      });
  };

  const handleCancel = (event) => {
    setValues({
      ...values,
      settingValue: currentSetting.value
    });
  };

  const handleConfirm = (event) => {
    settingsService.updateByName(
          settingName,
          values.settingValue,
          values.changeDescription)
      .then((data) => {
        setValues({
          ...values,
          settingValue: data.value,
          changeDescription: ""
        });
        setCurrentSetting(data)
      })
      .catch((except) => {
        props.showMessageError(except.message, except.detailedMessage)
      });
  };

  return (
    <div className={classes.root}>
      <Card>
        <CardContent>

          <Grid
            container
            spacing={5}
            alignContent="center"
          >
            <Grid
              item
              xs={12}
            >
              <FormControl className={classes.formControl}>
                <InputLabel>{t('label.setting')}</InputLabel>
                <Select
                  fullWidth
                  value={settingName}
                  onChange={handleSettingChange}
                  variant="standard"
                >
                  <MenuItem value={Constants.OPTION_EMPTY}>{t('label.select.field')}</MenuItem>
                  {
                    settings.map(function(setting, index) {

                        let translationLabel = setting.label
                        if(!translationLabel) {
                          translationLabel = setting.name
                        }

                        return <MenuItem
                            key={index}
                            value={setting.name}>{t(translationLabel)}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Grid>

            {currentSetting &&
            <>
              <Grid
                item
                xs={12}
              >
                <FormControl className={classes.formControl}>
                  <TextField
                    label="Valor"
                    type="text"
                    name="settingValue"
                    value={values.settingValue}
                    onChange={handleValueChange}
                    />
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <FormControl className={classes.formControl}>
                  <TextField
                    label="Motivo da Alteração"
                    type="text"
                    name="changeDescription"
                    value={values.changeDescription}
                    onChange={handleValueChange}
                    multiline
                    rows={4}
                    />
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                >
                <Typography variant="h5">
                  Valor Padrão
                </Typography>
                <Typography>
                  <Link underline={'always'}
                        component="button" onClick={() => {
                      handleClickDefault()
                    }}>
                    {currentSetting.default_value}
                  </Link>
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                >
                <Typography variant="h5">
                Descrição
                </Typography>
                <Typography>
                  {currentSetting.description}
                </Typography>
              </Grid>

              { !!currentSetting.updated_at &&
                <Grid
                  item
                  xs={12}
                  >
                  <Typography variant="h5">
                    Última Atualização
                  </Typography>

                  <Box display='flex' alignItems='center'>

                    <IconButton className={classes.buttonHistory}
                        onClick={handleOpenHistory}>
                      <HistoryIcon fontSize="large" />

                    </IconButton>

                    <Link
                        underline={'always'}
                        component="button"
                        onClick={handleOpenHistory}>
                        {formatDate(currentSetting.updated_at)}
                    </Link>

                  </Box>

                </Grid>
              }
            </>
            }

          </Grid>
        </CardContent>

        { currentSetting && currentSetting.value !== values.settingValue &&
          <>
            <Divider />
            <CardActions className={classes.actions}>
              <CardActions>
                <Button onClick={handleCancel}>
                    {t('button.cancel')}
                </Button>
                <Button
                  onClick={handleConfirm}
                  color="primary"
                  variant="contained"
                  >
                    {t('button.save')}
                </Button>
              </CardActions>
            </CardActions>
          </>
        }
      </Card>
    </div>
  );
}

export default withSnackbar(Settings)