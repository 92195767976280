import config from 'config';
import { authHeader, doFetch, handleResponse } from 'helpers';
import { createPaginationObject } from 'helpers/pagination';
import { objectToQueryString } from 'helpers/url';
import { i18n } from 'translation/i18n';

import { usersService } from './users.service';

export const collectionsService = {
    downloadPDFById,
    getById,
    getPage,
    remove,
    removeItem,
    addItem,
    save,
    updateItem
};

function downloadPDFById(id) {
    let url = `${config.apiUrl}/collection/${id}/pdf?lang=${i18n.language}`
    window.open(url, '_blank');
}

function getById(id, type) {
    let params = ''
    const requestOptions = { method: 'GET', headers: authHeader() };
    return doFetch(`${config.apiUrl}/collection/${id}?${new URLSearchParams(params)}`, requestOptions);
}

function save(data) {
    const method = (data.id) ? 'PUT' : 'POST'
    data.owner_id =  usersService.currentUserValue.id
    data.archived = (data.archived) ? data.archived : false
    const requestOptions = { method: method, headers: authHeader(), body: JSON.stringify(data) };
    if (data.id!=null){
        return doFetch(`${config.apiUrl}/collection/${data.id}`, requestOptions)
    } else {
        return doFetch(`${config.apiUrl}/collections`, requestOptions)
    }
}

function remove(id) {
    const requestOptions = { method: 'DELETE', headers: authHeader() };
    return doFetch(`${config.apiUrl}/collection/${id}`, requestOptions)
}

function removeItem(id, objectType_id, target_id) {
    const requestOptions = { method: 'DELETE', headers: authHeader() };
    return doFetch(`${config.apiUrl}/collection/${id}/items/${objectType_id}/${target_id}`, requestOptions)
}

function addItem(id, objectType_id, target_id) {
    const data = JSON.stringify({item:{objectType:{id:objectType_id},target:{id:target_id}}})
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: data
    };
    return doFetch(`${config.apiUrl}/collection/${id}/items`, requestOptions)
}

function getPage(
    pagination,
    filterByName,
    filterByArchived) {

    let paginationObj = createPaginationObject(
        pagination.page, pagination.size, pagination.sort, pagination.order);
    if(filterByName) {
        paginationObj.filterByName = filterByName;
    }
    if(filterByArchived) {
        paginationObj.archived = filterByArchived;
    }
    paginationObj.ownerId = usersService.currentUserValue.id
    let queryString = objectToQueryString(paginationObj);
    let url = `${config.apiUrl}/collections` + (queryString && ("?" + queryString));
    const requestOptions = { method: 'GET', headers: authHeader() };
    return doFetch(url, requestOptions);
}

function updateItem(objectTypeId, targetId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    let ownerId = usersService.currentUserValue.id
    return doFetch(`${config.apiUrl}/collection/owner/${ownerId}/items/${objectTypeId}/${targetId}`,
                requestOptions)
}