import uuid from 'uuid/v1';

export default [
  {
    id: uuid(),
    type: 'Obra',
    organization: {
      name:'Ministério da Economia',
      governPower: 'Executivo',
      coverage: 'Nacional'
    },
    company: {
      name:'Empreiteira ABC do carvão',
      cnpj: '74.664.711/0001-05'
    },
    object: {
      description:'Construção de estrada',
      nr: '1.234.567/1234',
      value: 123454.00
    },
    status: 'risk_confirmed',
    responsible: 'perito@deepvacuity.org.br',
    createdAt: 1555016400000
  },
  {
    id: uuid(),
    type: 'Obra',
    organization: {
      name:'Ministério das Cidades',
      governPower: 'Executivo',
      coverage: 'Nacional'
    },
    company: {
      name:'Madereira do Luis Carlos',
      cnpj: '34.630.628/0001-92'
    },
    object: {
      description:'Reforma de praça',
      nr: '1.222.567/3242',
      value: 345000.00
    },
    status: 'risk_probable',
    responsible: '',
    createdAt: 1557016400000
  },
  {
    id: uuid(),
    type: 'Obra',
    organization: {
      name:'Universidade Estadual da Bahia',
      governPower: 'Executivo',
      coverage: 'Estadual'
    },
    company: {
      name:'Madereira do Luis Carlos',
      cnpj: '88.074.699/0001-85'
    },
    object: {
      description:'Reforma de rua',
      nr: '1.234.567/1234',
      value: 200000.00
    },
    status: 'non_risk_probable',
    responsible: '',
    createdAt: 1556016400000
  },
  {
    id: uuid(),
    type: 'Obra',
    organization: {
      name:'Superior Tribunal Federal',
      governPower: 'Legislativo',
      coverage: 'Federal'
    },
    company: {
      name:'Marmoraria São José',
      cnpj: '23.122.394/0001-56'
    },
    object: {
      description:'Reforma de banheiro',
      nr: '1.234.567/1234',
      value: 100000000.00
    },
    status: 'non_risk_confirmed',
    responsible: 'perito@deepvacuity.org.br',
    createdAt: 1556016400000
  },  
];
