import { usersService } from 'services';

export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                usersService.logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function Exception(message = "") {
    this.message = message;
}

function ForbiddenException(message = "") {
    this.message = message;
}

function FetchException(message, detailedMessage = "") {
    this.message = message;
    this.detailedMessage = detailedMessage;
}

export function doFetch(url, options) {
    return fetch(url, options)
        .then(
            (response) => {
                return response.text().then(
                    (text) => {
                        if (!response.ok) {
                            if ([401, 403].indexOf(response.status) !== -1) {
                                throw new ForbiddenException()
                            }
                            throw new FetchException(
                                `Error fetching with status error: ${response.statusText} (status '${response.status}')`,
                                `Error fetching URL [${url}] with options [${JSON.stringify(options)}] => response body [${text}])`)
                        }

                        try {
                            return text && JSON.parse(text);
                        } catch(except) {
                            throw new FetchException(
                                `Error parsing JSON content: ${except.message}`,
                                `Error parsing JSON content with URL [${url}] with options [${JSON.stringify(options)}] => response body [${text}])`)
                        }
                    },
                    (textError) => {
                        throw new Exception(`Error reading body text => ${textError}`)
                    }
                );
            },
            (error) => {
                throw new Exception(error)
            })
        .catch((except) => {
            if (except instanceof ForbiddenException) {
                usersService.logout();
                location.reload(true);
                return
            } else if(!(except instanceof FetchException)) {
                throw new FetchException(
                    `Error fetching: ${except.message || JSON.stringify(except)}`,
                    `Error fetching URL [${url}] with options [${JSON.stringify(options)}] => exception [${JSON.stringify(except)}]`)
            }
            throw except;
        })
}