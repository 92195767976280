import { AppBar, Hidden, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { accessControlService, usersService } from 'services';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { LanguageButton, TopbarLogo } from 'components';

const white = '#FFFFFF';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  topBarButtons: {
    marginLeft: theme.spacing(1),
    color: white
  }
}));

const Topbar = props => {
  const { keycloak, className, onSidebarOpen, ...rest } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const actualRole = usersService.getRole(keycloak)

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <Link to={accessControlService.getHomePageForRole(actualRole)}>
          <TopbarLogo />
        </Link>

        <div className={classes.flexGrow} />

        <LanguageButton />

        <Tooltip title={t('button.exit')}>
          <Link to={`/logout`}>
            <IconButton className={classes.topBarButtons}>
              <InputIcon />
            </IconButton>
          </Link>
        </Tooltip>

        {/************ Menu **************/}
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        {/********************************/}

      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
