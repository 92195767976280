import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { objectTypeService } from 'services';
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Box,
  Typography,
  Button,
  TextField
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'components/LoadingSpinner';
import { Save } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import BackButton from 'components/BackButton';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  tableContent: {
    marginTop: theme.spacing(2)
  },
  toolbar: {
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  spacer: {
    flexGrow: 1
  },
  formControl: {
    width: '100%'
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const ObjectTypeForm = ({ match }) => {
  const [loading, setLoading] = useState();
  const { t } = useTranslation();
  const classes = useStyles();
  const [data, setData] = useState({
    description:"",
    id:null
  });
  const [errors, setErrors] = useState({
    description:null
  });
  const history = useHistory();


  const handleChange = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  React.useEffect(() => {
    if (match.params.id && data.id == null){
      loadData()
    }
  });

  function loadData() {
    setLoading(true)
    objectTypeService.getById(match.params.id).then(data => {setData(data);setLoading(false)});
  }

  const handleSave = () => {
    if (!data.description){
      setErrors({...errors,description:t('form.requiredField')})
    } else {
      setErrors({...errors,description:null})
      objectTypeService.save(data).then(
        history.goBack()
      )
    }
  }

  return (
    <div className={classes.root}>
        <Box mb={2} display={(loading)?"none":"block"}>
          <Typography variant="h4">{(data.id)?t('objectType.operation.edit'):t('objectType.operation.create')} - {t('objectType.title')}</Typography>
        </Box>
        <Card>
          <LoadingSpinner loading={loading}/>
          <Box mx={2} my={2} display={(loading)?"none":"block"}>
            <CardContent className={classes.content}>
              <TextField
                  //fullWidth
                  margin="dense"
                  onChange={handleChange}
                  required
                  variant="standard"
                  InputProps={{ readOnly: false }}
                  label={t('objectType.label.description')}
                  name="description"
                  value={data.description}
                  error={errors.description != null}
                  helperText={!errors.description ? '' : errors.description}
                />
            </CardContent>
            <CardActions className={classes.actions}>
              <Box mx={0} my={1}>
                <Grid container justifyContent = "flex-end">
                        <Button
                        onClick={handleSave}
                        color="primary"
                        variant="contained">
                          <Save /> {t('button.save')}
                        </Button>
                </Grid>
              </Box>
            </CardActions>
          </Box>
        </Card>
        <Box mt={3}>
          <Grid
              container
              spacing={4}
            >
              <BackButton />
          </Grid>
        </Box>
    </div>
  //);
  )
  }
export default withRouter(ObjectTypeForm);