import { Box, Card, Tooltip, IconButton, CardHeader, CardContent, Typography, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { objectTypeService } from 'services';
import { withSnackbar } from 'hooks/withSnackbar';
import { List as ListIcon, Edit as EditIcon } from '@material-ui/icons';
import { genericService } from 'services';
import { capitalize } from 'helpers/format'
import { removeHtml, truncateRight } from 'components';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
  }
}));

const RenderRelationships = (props) => {
  const classes = useStyles();
  const objectId = props.id
  const objectEntity = props.entity

  const { t, i18n } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [relationships, setRelationships] = useState([]);
  const [pageSize, setPageSize] = useState(5);

  React.useEffect(() => {
    genericService.getRelationships(objectEntity, objectId)
      .then(data => {
        setRelationships(data)
      })
      .catch(error => {
        props.showMessageError(error)
      })
  }, []);

  const handleOpenRowInNewTab = (entity, id) => {
    window.open(`/#/${entity}/${id}`, "_blank")
  }

  const onEditData = (e) => {
  }

  return (
    <Box my={2}>
        <Card>
          <CardHeader
              title={t('label.relationships')}
              action={
                <div hidden={ true /*isEditing*/}>
                    <IconButton onClick={onEditData}>
                        <EditIcon />
                    </IconButton>
                </div>
            }/>
          <CardContent>
            {(!relationships || !relationships.length) ?
                <Box>
                  <Card>
                    <CardContent>
                      <Typography>{t('table.result.none')}</Typography>
                    </CardContent>
                  </Card>
                </Box>
                :
                <PerfectScrollbar>

                  <DataGrid
                    autoHeight={true}
                    rows={relationships}
                    density={"comfortable"}
                    columns={[
                      {
                        headerName: `${t('label.actions')}`,
                        disableColumnMenu: true,
                        field: 'actions',
                        editable: false,
                        filterable: false,
                        hideable: false,
                        sortable: false,
                        minWidth: 80,
                        renderCell: (params) => {
                          return (
                            <Tooltip title={t('button.detail')} aria-label={t('button.detail')}>
                              <IconButton  onClick={() => {handleOpenRowInNewTab(params.row.entity, params.row.id)}}>
                                <ListIcon fontSize="large" />
                              </IconButton>
                            </Tooltip>
                          );
                        }
                      },
                      {
                        field: 'entity',
                        headerName: `${t('label.entity')}`,
                        editable: false,
                        minWidth: 150,
                        valueGetter: (params) => {
                            return i18n.exists(`label.entity.${params.row.entity}`) ?
                                        t(`label.entity.${params.row.entity}`) : capitalize(params.row.entity)
                        }
                      },
                      {
                        field: 'id',
                        headerName: `${t('label.id')}`,
                        type: 'number',
                        minWidth: 100,
                        editable: false,
                      },
                      {
                        field: 'description',
                        headerName: `${t('label.description')}`,
                        editable: false,
                        minWidth: 1000,
                        renderCell: (params) => {
                          let description = truncateRight(removeHtml(params.row.description), 500)
                          return (
                            <Tooltip title={description}>
                              <span>
                                {description}
                              </span>
                            </Tooltip>
                          );
                        }
                      }
                    ]}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 25]}
                    componentsProps={{
                      pagination: { classes: null }
                    }}
                  />
            </PerfectScrollbar>

            }

        </CardContent>
        </Card>
    </Box>
  );
};

RenderRelationships.propTypes = {
};

export default withSnackbar(RenderRelationships);