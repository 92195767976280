import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { 
  useMediaQuery,

  Card,
  CardHeader,
  CardContent,
  Divider
} from '@material-ui/core';

import { Sidebar, Topbar } from './components';
import { Footer } from 'components';
import MuiPaper from 'theme/overrides/MuiPaper';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: '100%'
  },
  footer: {
    padding: theme.spacing(4)
  },
  card:{
    borderRadius: 0
  }
}));

const Main = props => {
  const { children, title, keycloak } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} keycloak={keycloak} />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        keycloak={keycloak}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
      <main className={classes.content}>

        { !!(title) ? 
              <Card className={classes.card}> 
                <CardHeader
                  title={title}
                />
                <Divider />
                <CardContent>       
                {children} 
                </CardContent>
              </Card> 

              :    

              <div>
                {children} 
              </div>
        }

        <Footer className={classes.footer}  />
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
};

export default Main;
