import config from 'config';
import { authHeader, handleResponse } from 'helpers';
import { createPaginationObject } from 'helpers/pagination';
import { objectToQueryString } from 'helpers/url';

export const jobExecutionService = {
    getById,
    getPage,
    cancel
};

function getById(id) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/settings/jobsExecutions/${id}`, requestOptions).then(handleResponse).catch((msg)=>{return {error:msg}});
}

function cancel(id) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/settings/jobsExecutions/${id}/cancel`, requestOptions).then(handleResponse).catch((msg)=>{return {error:msg}});
}

function getPage(
    pagination,
    automatic,
    filterByDescription,
    filterByJobConfig,
    filterByStatus,
    ) {

    let paginationObj = createPaginationObject(
        pagination.page, pagination.size, pagination.sort, pagination.order);
    if(automatic) {
        paginationObj.automatic = automatic;
    }
    if(filterByDescription) {
        paginationObj.description = filterByDescription;
    }
    if(filterByJobConfig) {
        paginationObj.job_config_id = filterByJobConfig;
    }
    if(filterByStatus) {
        let list = []
        for (var i in filterByStatus){
            if (filterByStatus[i]){
                list.push(i)
            }
        }
        paginationObj.status = list.join(",");
    }
    let queryString = objectToQueryString(paginationObj);
    let url = `${config.apiUrl}/settings/jobsExecutions` + (queryString && ("?" + queryString));
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(url, requestOptions).then(handleResponse).catch((msg)=>{return {error:msg}});
}