import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Error } from '@material-ui/icons';

const ErrorOccurred = props => {
  const {display, msg} = props
  const { t } = useTranslation();
  return (
    <Box component="div" display={display}>
      <Grid container justifyContent = "center">
        <Grid item>
          <Box mx={3} my={3}>
            <Grid container justifyContent = "center">
              <Error fontSize="large"/>
              <Grid item xs={12}>
                <Typography align="center" variant="h6">{t('table.result.error')}</Typography>
                <Typography align="center" >{msg}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

ErrorOccurred.propTypes = {
};

export default ErrorOccurred;