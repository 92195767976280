import config from 'config';
import { authHeader, handleResponse, doFetch } from 'helpers';
import { createPaginationObject } from 'helpers/pagination';
import { dynamicFilterToQueryString, objectToQueryString } from 'helpers/url';

export const publicationsService = {
  getById,
  getConstellationById,
  getPage,
  getByCompany,
  getCount,
  getByIds,
  getCompaniesAndAgenciesRelationship,
  getFilters,
  updatePublication,
  replaceProperties
};

function getById(id) {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return fetch(`${config.apiUrl}/publication/${id}`, requestOptions).then(handleResponse);
}

function getConstellationById(id) {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return fetch(`${config.apiUrl}/constellation/publication/${id}`, requestOptions).then(handleResponse);
}

function getCount() {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return fetch(`${config.apiUrl}/publications/getCount`, requestOptions).then(handleResponse);
}

function getCompaniesAndAgenciesRelationship(
  pagination,
  filterByName) {

  let paginationObj = createPaginationObject(
    pagination.page, pagination.size, pagination.sort, pagination.order);
  if (filterByName) {
    paginationObj.title = filterByName;
  }
  let queryString = objectToQueryString(paginationObj);
  let url = `${config.apiUrl}/publications/companiesAndAgenciesRelationship` + (queryString && ('?' + queryString));
  const requestOptions = { method: 'GET', headers: authHeader() };
  return fetch(url, requestOptions).then(handleResponse);
}

function getByCompany(id) {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return fetch(`${config.apiUrl}/publications/bycompany/${id}`, requestOptions).then(handleResponse);
}

function getByIds(list) {
  const requestOptions = {
    method: 'POST',
    headers: //{
      authHeader(),
    //      'Accept': 'application/json',
    //      'Content-Type': 'application/json',
    //   },
    mode: 'cors',
    body: JSON.stringify({ list: list })
  }
  return fetch(`${config.apiUrl}/publications/byIds/`, requestOptions).then(handleResponse);
}

function getPage(pagination, filters, toCsv) {
  let queryStringFilter = dynamicFilterToQueryString(filters);
  let paginationObj = createPaginationObject(
    pagination.page, pagination.size, pagination.sort, pagination.order);
  let queryString = objectToQueryString(paginationObj);
  if (queryStringFilter)
    queryString = [queryStringFilter, queryString].join('&')
  if (toCsv) {
    queryString += '&toCsv=true';
  }
  let url = `${config.apiUrl}/publications` + (queryString && ('?' + queryString));
  const requestOptions = { method: 'GET', headers: authHeader() };
  return fetch(url, requestOptions).then(handleResponse);
}

function getFilters() {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return fetch(`${config.apiUrl}/publications/filters`, requestOptions).then(handleResponse);
}

function updatePublication(publication, publicationId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    mode: 'cors',
    body: JSON.stringify({ content: publication })
  }
  const response = fetch(`${config.apiUrl}/publication/${publicationId}/content`, requestOptions);
  return handleResponse(response);
}

function replaceProperties(publicationId, jsonData) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(jsonData)
  }
  return doFetch(`${config.apiUrl}/publication/${publicationId}/properties`, requestOptions)
}