import moment from 'moment'

export function formatDate(value, format){
    if(!format) {
      format = 'DD/MM/YYYY HH:mm:ss'
    }
    return moment(value).format(format);
}

let ignoredChars = ["[", "]", "-", ":", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]

function isNumeric(value) {
  if (typeof value != "string") return false
  return !isNaN(value) &&
         !isNaN(parseFloat(value))
}

export function capitalize(label) {
  if(!label)
    return label;
  let res = label[0].toUpperCase()
  for (let i = 1; i < label.length; i++) {
    let character = label[i]
    // Char '_' is for "snake_case"
    if (character == '_' || character == ' ') {
      res += ' ';
      while(++i < label.length && (label[i] == '_' || label[i] == ' ')) { }
      if(i < label.length) {
        res += label[i].toUpperCase()
      }
      continue;
    }
    // For "camelCase"
    else if (character == character.toUpperCase() && !ignoredChars.includes(character)) {
      res += ' ';
    }
    res += character
  }
  return res;
}

export function formatNumber(value) {
  if(!value)
    return value
  if (typeof value !== 'string') {
    value = value.toString()
  }
  if(!isNumeric(value))
    return value;
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
