import config from 'config';
import { authHeader, handleResponse } from 'helpers';

export const dashboardService = {
    getByRole,
};

function getByRole(role) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/dashboard/${role}`, requestOptions).then(handleResponse);
}