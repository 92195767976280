import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { translation as translation_pt_BR } from './lang/pt_BR'
import { translation as translation_en } from './lang/en'

// The first in array is the fallback language
const languages = [
  { option: 'Português', lang: 'pt_BR', resource: translation_pt_BR},
  { option: 'English', lang: 'en', resource: translation_en},
]

const resources = {}
languages.map(entry => {
  resources[entry.lang]={}
  resources[entry.lang].translation = entry.resource
});

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    nsSeparator: false,
    keySeparator: false,
    fallbackLng: languages[0].lang,
    resources: resources,
  });

export {
  i18n, languages
};