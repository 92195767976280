import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Tooltip,
  Button,
  IconButton
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import CriticalIcon from '@material-ui/icons/Cancel';

import RiskIcon from '@material-ui/icons/Assignment';
import CompanyIcon from '@material-ui/icons/Business';
import AgencyIcon from '@material-ui/icons/HomeWork';
import PublicationIcon from '@material-ui/icons/InsertDriveFile';

import ListIcon from '@material-ui/icons/List';
import ConstellationIcon from '@material-ui/icons/AccountTree';
//import ConstellationIcon from '@material-ui/icons/DeviceHub';

import { Link as RouterLink } from 'react-router-dom';
import CustomTablePagination from 'components/CustomTablePagination';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

export const TypeIcons = {
  info: <InfoIcon color="primary" fontSize="small"/>,
  warning: <WarningIcon color="secondary" fontSize="small"/>,
  critical: <CriticalIcon color="error" fontSize="small"/>,
}

export const TypeItemIcons = {
  agency: <AgencyIcon />,
  company: <CompanyIcon />,
  risk: <RiskIcon />,
  publication: <PublicationIcon />,
}

export const TypeItemLabel = {
  agency: 'Órgão',
  company: 'Empresa',
  risk: 'Risco',
  publication: 'Publicação',
}

const DataTable = props => {
  const { className, tableData, ...rest } = props;

  const classes = useStyles();

  const [selectedRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Item</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell align="center">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.slice(0, rowsPerPage).map(row => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={row.id}
                    selected={selectedRows.indexOf(row.id) !== -1}
                  >
                    <TableCell>
                      <Grid item md={12} align="center" justifyContent="center">
                        {TypeItemIcons[row.itemType]}
                        <Typography
                          className={classes.name}
                          variant="h6"
                        >
                          {TypeItemLabel[row.itemType]}
                        </Typography>
                      </Grid>
                    </TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell align="center">
                      <Tooltip title="Constelação">
                        <RouterLink color="inherit" to={'/constellation'}>
                          <IconButton className={classes.topBarButtons}>
                            <ConstellationIcon fontSize="large" />
                          </IconButton>
                        </RouterLink>
                      </Tooltip>
                      <Tooltip title="Detalhar" aria-label="detalhar">
                        <Button>
                            <ListIcon fontSize="large" />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <CustomTablePagination
          component="div"
          count={tableData.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

DataTable.propTypes = {
  className: PropTypes.string,
  tableData: PropTypes.array.isRequired
};

export default DataTable;
