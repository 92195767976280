
import { dynamicsort } from 'helpers/ordering';
// import moment from 'moment';
import * as moment from 'moment';

export const fakeBackendLogs = {
  get,
  list
};

function get(id) {
  return data.find(x => x.id === id);
}

function list(
    pagination, 
    filterByGeneric,
    filterByType) {

  if(!pagination) 
    return {};

  let tempData = data;

  /* Filtering */
  if(filterByGeneric) {
    tempData = tempData.filter(
      (object) => (
        object.message.toUpperCase().includes(filterByGeneric.toUpperCase())
        || object.user.toUpperCase().includes(filterByGeneric.toUpperCase())
      ))
  }
  if(filterByType) {
    tempData = tempData.filter(
      (object) => (object.type.toUpperCase().includes(
        filterByType.toUpperCase())))
  }
  
  // using non string "when"
  let sortParamsTemp = pagination.sort.split("|");
  if(sortParamsTemp[0] == "when") {
    pagination.sort = "whenTemp" + "|" + sortParamsTemp[1];
  }

  /* Sorting */
  if(!pagination.sort) {
    tempData = tempData.sort(dynamicsort("id","asc"));
  } else {
    let sortParams = pagination.sort.split("|");
    if(sortParams.length == 1) {
      tempData = tempData.sort(dynamicsort(sortParams[0], "asc"));
    } else if(sortParams.length > 1) {
      tempData = tempData.sort(dynamicsort(sortParams[0], sortParams[1]));
    }
  }

  let result = {
    total: tempData.length
  };

  /* Pagination */
  let pageStart = (pagination.page) * pagination.size;
  let pageEnd = pageStart + Number(pagination.size);
  tempData = tempData.slice(pageStart, pageEnd);

  result.data = tempData;
  result.count = tempData.length;

  return result;
}

const DATE_FORMAT = "DD/MM/YYYY HH:mm:ss";

let id=0;
const data = [
  {
    id: ++id,
    type: 'error',
    when: moment()
            .format(DATE_FORMAT),
    message: 'Erro ao acessar o menu de monitoramento.',
    user: 'perito@deepvacuity.org.br'
  },
  {
    id: ++id,
    type: 'warn',
    when: moment()
              .subtract(1, "hours")
              .format(DATE_FORMAT),
    message: 'Problema com a exibição de resultados',
    user: 'externo@deepvacuity.org.br'
  },
  {
    id: ++id,
    type: 'info',
    when: moment()
              .subtract(2, "hours")
              .subtract(20, "minutes")
              .subtract(15, "seconds")
              .format(DATE_FORMAT),
    message: 'Capacidade de 50% do disco atingida',
    user: 'administrador@deepvacuity.org.br'
  },
  {
    id: ++id,
    type: 'info',
    when: moment()
              .subtract(7, "hours")
              .subtract(10, "minutes")
              .subtract(40, "seconds")
              .format(DATE_FORMAT),
    message: 'Capacidade de 51% do disco atingida',
    user: 'perito@deepvacuity.org.br'
  },
  {
    id: ++id,
    type: 'error',
    when: moment()
              .subtract(1, "days")
              .subtract(20, "seconds")
              .format(DATE_FORMAT),
    message: 'Erro na execução de modelo x',
    user: 'administrador@deepvacuity.org.br'
  },
  {
    id: ++id,
    type: 'error',
    when: moment()
              .subtract(10, "days")
              .subtract(5, "seconds")
              .format(DATE_FORMAT),
    message: 'Erro na comunicação com o Grafana',
    user: 'perito@deepvacuity.org.br'
  },
  {
    id: ++id,
    type: 'info',
    when: moment()
              .subtract(11, "days")
              .subtract(5, "hours")
              .subtract(5, "seconds")
              .format(DATE_FORMAT),
    message: 'Usuário perito acessou o sistema',
    user: 'externo@deepvacuity.org.br'
  },
  {
    id: ++id,
    type: 'error',
    when: moment()
              .subtract(20, "days")
              .subtract(6, "hours")
              .subtract(15, "seconds")
              .format(DATE_FORMAT),
    message: 'Engine de aprendizado inacessível',
    user: 'administrador@deepvacuity.org.br'
  },
  {
    id: ++id,
    type: 'warn',
    when: moment()
              .subtract(40, "days")
              .subtract(2, "hours")
              .subtract(15, "minutes")
              .format(DATE_FORMAT),
    message: 'Menu de dashboard com problemas de exibição',
    user: 'perito@deepvacuity.org.br'
  },
  {
    id: ++id,
    type: 'warn',
    when: moment()
              .subtract(2, "months")
              .subtract(2, "days")
              .subtract(2, "hours")
              .subtract(15, "minutes")
              .format(DATE_FORMAT),
    message: 'Sistema de coletas inacessível, tentando novamente...',
    user: 'administrador@deepvacuity.org.br'
  },
  {
    id: ++id,
    type: 'error',
    when: moment()
              .subtract(4, "months")
              .subtract(1, "days")
              .subtract(5, "hours")
              .subtract(15, "minutes")
              .format(DATE_FORMAT),
    message: 'Sistema Kafka offline',
    user: 'administrador@deepvacuity.org.br'
  },
  {
    id: ++id,
    type: 'error',
    when: moment()
              .subtract(5, "months")
              .subtract(14, "days")
              .subtract(2, "hours")
              .subtract(4, "minutes")
              .format(DATE_FORMAT),
    message: 'Erros reportados pelo Grafana',
    user: 'externo@deepvacuity.org.br'
  },
  {
    id: ++id,
    type: 'info',
    when: moment()
              .subtract(1, "years")
              .subtract(3, "months")
              .subtract(12, "days")
              .subtract(5, "hours")
              .subtract(34, "minutes")
              .format(DATE_FORMAT),
    message: '20 riscos encontrados',
    user: 'administrador@deepvacuity.org.br'
  },
  {
    id: ++id,
    type: 'error',
    when: moment()
              .subtract(2, "years")
              .subtract(11, "months")
              .subtract(2, "days")
              .subtract(8, "hours")
              .subtract(12, "minutes")
              .format(DATE_FORMAT),
    message: 'Erro desconhecido no dashboard',
    user: 'administrador@deepvacuity.org.br'
  },
  {
    id: ++id,
    type: 'error',
    when: moment()
              .subtract(3, "years")
              .subtract(3, "months")
              .subtract(6, "days")
              .subtract(1, "hours")
              .subtract(12, "minutes")
              .format(DATE_FORMAT),
    message: 'Erro de coleta',
    user: 'administrador@deepvacuity.org.br'
  },
  {
    id: ++id,
    type: 'error',
    when: moment()
              .subtract(5, "years")
              .subtract(11, "months")
              .subtract(3, "days")
              .subtract(2, "hours")
              .subtract(3, "minutes")
              .format(DATE_FORMAT),
    message: 'Erro no broker de coleta',
    user: 'externo@deepvacuity.org.br'
  },
  {
    id: ++id,
    type: 'warn',
    when: moment()
              .subtract(6, "years")
              .subtract(2, "months")
              .subtract(4, "days")
              .subtract(7, "hours")
              .subtract(4, "minutes")
              .format(DATE_FORMAT),
    message: 'Gráficos com problema de exibição',
    user: 'externo@deepvacuity.org.br'
  },
];

data.forEach(function(object) {
  object.whenTemp = moment(object.when, DATE_FORMAT);
});