import React from 'react';
import { accessControlService, usersService } from 'services';
import { Redirect, withRouter } from 'react-router-dom';
import { withKeycloak } from '@react-keycloak/web';

const Home = (props) => {
  const {keycloak} = props
  const actualRole = usersService.getRole(keycloak) 

  return <Redirect to={accessControlService.getHomePageForRole(actualRole)} />
};

export default withKeycloak(Home);
