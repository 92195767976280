import config from 'config';
import { authHeader, doFetch } from 'helpers';

export const settingsService = {
    getAll,
    getByName,
    resetByName,
    updateByName
};

function getAll() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return doFetch(`${config.apiUrl}/general/settings`, requestOptions)
}

function getByName(name) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return doFetch(`${config.apiUrl}/general/setting?` +
                    new URLSearchParams({
                        name: name
                    }),
                    requestOptions)
}

function resetByName(name) {
    const requestOptions = { method: 'DELETE', headers: authHeader() };
    return doFetch(`${config.apiUrl}/general/setting?` +
                    new URLSearchParams({
                        name: name
                    }),
                    requestOptions)
}

function updateByName(name, value, description) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({value: value, change_description: description}) };

    return doFetch(`${config.apiUrl}/general/setting?` +
                    new URLSearchParams({
                        name: name
                    }),
                    requestOptions)
}
