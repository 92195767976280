import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useConfirm } from 'material-ui-confirm';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import BackButton from 'components/BackButton';
import ConfirmDialog from 'components/ConfirmDialog'
import LoadingSpinner from 'components/LoadingSpinner';
import RenderAgencies from 'components/RenderAgencies';
import RenderCompanies from 'components/RenderCompanies';
import RenderConstellations from 'components/RenderConstellations';
import RenderPublications from 'components/RenderPublications';
import { collectionsService as mainService } from 'services';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  tableContent: {
    marginTop: theme.spacing(2)
  },
  toolbar: {
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },  
  spacer: {
    flexGrow: 1
  },
  formControl: {
    width: '100%'
  },  
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const DEFAULT_OBJECT = "collection"

const Collection = ({ match }) => {
  const [loading, setLoading] = useState();
  const { t } = useTranslation();
  const classes = useStyles();  
  const [data, setData] = useState({
    name:"",
    public: true,
    id:null
  });
  
  React.useEffect(() => {
    if (match.params.id && data.id == null){
      loadData()
    }
  }); 

  function loadData() {
    setLoading(true)
    mainService.getById(match.params.id).then(data => {setData(data);setLoading(false)});    
  }

  const confirm = useConfirm();

  const handleRemoveItem = (objectType_id, target_id) => {
    ConfirmDialog(confirm, t)
      .then(() => { 
          mainService.removeItem(data.id, objectType_id, target_id).then(loadData)
       })
      .catch(() => { /* ... */ });
  }
  
  return (
    <div className={classes.root} >
        <Box mb={2} display={(loading)?"none":"block"}>
          <Typography variant="h5">{t('collection.label.name') + ': ' + data.name}</Typography>
        </Box>
        <Card id="pdfdiv">
          <LoadingSpinner loading={loading}/>
          <Box mx={2} my={2} display={(loading)?"none":"block"}>
            <CardContent className={classes.content}>

              { (!data.items || !data.items.list || !data.items.list.length) ? 
              <>                
                <Typography>{t('table.result.none')}</Typography>
              </>
              :
              <>
                <Box my={3}>
                  <Grid item xs={12}>
                    <RenderPublications classes={classes} object={(data.items)?data.items.list:{}} handleRemoveItem={handleRemoveItem}/>
                  </Grid>
                </Box>
                <Box my={3}>
                  <Grid item xs={12}>
                    <RenderAgencies classes={classes} object={(data.items)?data.items.list:{}} handleRemoveItem={handleRemoveItem}/>
                  </Grid>
                </Box>
                <Box my={3}>
                  <Grid item xs={12}>
                    <RenderCompanies classes={classes} object={(data.items)?data.items.list:{}} handleRemoveItem={handleRemoveItem}/>
                  </Grid>
                </Box>
                <Box my={3}>
                  <Grid item xs={12}>
                    <RenderConstellations classes={classes} object={(data.items)?data.items.list:{}} handleRemoveItem={handleRemoveItem}/>
                  </Grid>
                </Box>
              </>
              }
            </CardContent> 
          </Box>
          </Card>
        <Box mt={3}>
          <Grid
              container
              spacing={4}
            >
              <BackButton />
          </Grid>
        </Box>
    </div>
  //);
  )
  }
export default withRouter(Collection);