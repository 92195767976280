
import uuid from 'uuid/v1';
import { dynamicsort } from 'helpers/ordering';

export const fakeBackendAgencies = {
  get,
  list
};

function get(id) {
  return data.find(x => x.id === id);
}

function list(
    pagination, 
    filterByAgencyName, 
    filterByPower,
    filterBySphere) {

  if(!pagination) 
    return {};

  let tempData = data;

  /* Filtering */
  if(filterByAgencyName) {
    tempData = tempData.filter(
      (object) => (object.publicAgency.toUpperCase().includes(
        filterByAgencyName.toUpperCase())))
  }

  /* Sorting */
  if(!pagination.sort) {
    tempData = tempData.sort(dynamicsort("id","asc"));
  } else {
    let sortParams = pagination.sort.split(",");
    if(sortParams.length == 1) {
      tempData = tempData.sort(dynamicsort(sortParams[0], "asc"));
    } else if(sortParams.length > 1) {
      tempData = tempData.sort(dynamicsort(sortParams[0], sortParams[1]));
    }
  }

  let result = {
    total: tempData.length
  };

  /* Pagination */  
  let pageStart = (pagination.page) * pagination.size;
  let pageEnd = pageStart + Number(pagination.size);
  tempData = tempData.slice(pageStart, pageEnd);

  result.data = tempData;
  result.count = tempData.length;

  return result;
}

let id=0;
const data = [
  {
    id: ++id,
    name: '[fake] Ministério das cidades',
    contracts: 186
  },
  {
    id: ++id,
    name: '[fake] Departamento de Estradas e Rodagens',
    contracts: 74
  },  
  {
    id: ++id,
    name: '[fake] Ministério da Cidadania',
    contracts: 20
  },  
  {
    id: ++id,
    name: '[fake] Centrais de Abastecimento do Distrito Federal - CEASA',
    contracts: 35
  },  
  {
    id: ++id,
    name: '[fake] Departamento de Trânsito - DETRAN',
    contracts: 12
  }, 
  {
    id: ++id,
    name: '[fake] Procuradoria Geral do Estado de São Paulo',
    contracts: 100
  },  
  {
    id: ++id,
    name: '[fake] Secretaria de Estado de Cultura de São Paulo',
    contracts: 300
  },  
  {
    id: ++id,
    name: '[fake] Secretaria de Estado de Defesa Civil do Rio de Janeiro',
    contracts: 25
  },  
  {
    id: ++id,
    name: '[fake] Câmara Municipal de Belo Horizonte',
    contracts: 10
  },  
  {
    id: ++id,
    name: '[fake] Administração de Belo Horizonte',
    contracts: 74
  },  
  {
    id: ++id,
    name: '[fake] Superior Tribunal Federal',
    contracts: 32
  }
];
