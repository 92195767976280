import pluralize from 'pluralize'

import config from 'config';
import { authHeader, doFetch } from 'helpers';
import { createPaginationObject } from 'helpers/pagination';
import { dynamicFilterToQueryString, objectToQueryString } from 'helpers/url';

export const genericService = {
  getById,
  getByIds,
  getPage,
  getPageUrl,
  getCount,
  getFilters,
  getConstellationById,
  getRelationships
};

function getById(entity, id) {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return doFetch(`${config.apiUrl}/${entity}/${id}`, requestOptions);
}

function getByIds(entity, ids) {
  let url = config.apiUrl + "?" + ids.map(id => `id=${id}`).join(",")
  const requestOptions = { method: 'GET', headers: authHeader() };
  return doFetch(`${url}/${entity}/${id}`, requestOptions);
}

function getCount(entity) {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return doFetch(`${config.apiUrl}/${pluralize(entity)}/getCount`, requestOptions);
}

function getPageUrl(entity, pagination, filters, toCsv) {
  let queryStringFilter = dynamicFilterToQueryString(filters);
  let paginationObj = createPaginationObject(
    pagination.page, pagination.size, pagination.sort, pagination.order);
  let queryString = objectToQueryString(paginationObj);
  if (queryStringFilter)
    queryString = [queryStringFilter, queryString].join('&')
  if (toCsv) {
    queryString += '&toCsv=true';
  }
  let url = `${config.apiUrl}/${pluralize(entity)}` + (queryString && ("?" + queryString));

  return url
}

function getPage(entity, pagination, filters, toCsv) {
  let url = getPageUrl(entity, pagination, filters, toCsv)
  const requestOptions = { method: 'GET', headers: authHeader() };
  return doFetch(url, requestOptions);
}

function getFilters(entity) {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return doFetch(`${config.apiUrl}/${pluralize(entity)}/filters`, requestOptions);
}

function getConstellationById(entity, id) {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return doFetch(`${config.apiUrl}/constellation/${entity}/${id}`, requestOptions);
}

function getRelationships(entity, id) {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return doFetch(`${config.apiUrl}/entity/${entity}/${id}/relationships`, requestOptions);
}