import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { jobExecutionService } from 'services';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ErrorOccurred, JsonTreeView, NoResultsFound, SearchInput } from 'components';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableSortLabel,
  TableHead,
  TableRow,
  Tooltip,
  Grid,
  IconButton,
  Box,
  Typography,
  Button,
  Collapse,
  LinearProgress
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as Constants from 'common/systemConstants';
import LoadingSpinner from 'components/LoadingSpinner';
import { Add, Cancel as CancelIcon, Delete, Edit, KeyboardArrowDown, KeyboardArrowUp, PlayCircleFilled, Refresh } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import BackButton from 'components/BackButton';
import CustomTablePagination from 'components/CustomTablePagination';
import { useConfirm } from 'material-ui-confirm';
import { formatDate } from 'helpers/format'
import { StatusFilterBar } from '../Configuration';
import moment from 'moment'
import DateDiff from 'helpers/dateDiff'
import { withSnackbar } from 'hooks/withSnackbar';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  tableContent: {
    marginTop: theme.spacing(2)
  },
  toolbar: {
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  spacer: {
    flexGrow: 1
  },
  formControl: {
    width: '100%'
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const DEFAULT_SORT_COLUMN = "updated_at";
const DEFAULT_ORDER = Constants.ORDER_DESC;

const Execution = (props) => {
  const [loading, setLoading] = useState();
  const { t } = useTranslation();
  const classes = useStyles();
  const [tableData, setTableData] = useState();
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [filters, setFilters] = useState({
    description: '',
    job_config_id:'',
    status: {
      created:true,
      running:true,
      error:true,
      canceled:false,
      finished:false,
    }
  });
  const [pagination, setPagination] = useState({
    size: Constants.DEFAULT_ROWS_SIZE,
    page: 0,
    sort: DEFAULT_SORT_COLUMN,
    order: DEFAULT_ORDER
  });

  React.useEffect(() => {
    updateTable();
    const interval=setInterval(()=>{
      updateTable(true)
     },30000)
    return()=>clearInterval(interval)
  }, [pagination, filters]);

  function updateTable(automatic) {
    setLoading(true)
    jobExecutionService.getPage(
      pagination,
      automatic,
      filters.description,
      filters.job_config_id,
      filters.status,
    ).then(tableData => {
      if (tableData.error){
        setErrorOccurred(tableData.error);
      } else {
        setErrorOccurred(false);
        setTableData(tableData);
      }
      setLoading(false)
    })
    .catch((e)=>{setErrorOccurred(true);setLoading(false)})
  }

  const handleFilterChange = (event, funcValue) => {
      setFilters({
        ...filters,
        [event.target.name]: (funcValue) ? funcValue() : event.target.value
      });
      updateTable()
  };
  const handlePaginationSizeChange = event => {
    setPagination({
      ...pagination,
      ["size"]: event.target.value
    });
  };
  const handlePaginationPageChange = (event, page) => {
    setPagination({
      ...pagination,
      ["page"]: page
    });
  };
  const handlePaginationSortChange = (property) => (event) => {
    let order = Constants.ORDER_ASC;
    if(property === pagination.sort
      && pagination.order == Constants.ORDER_ASC) {
        order = Constants.ORDER_DESC;
    }
    setPagination({
      ...pagination,
      ["sort"]: property,
      ["order"]: order
    });
  };

  const confirm = useConfirm();

  const handleCancel = (id) => {
    jobExecutionService.cancel(id).then(()=>{
      props.showMessageWarning(t('button.stopped'))
      setTimeout(updateTable, 3000)
    })
  }

  const [open, setOpen] = React.useState();

  const processingRate = (data_replicated, batch_size, done, started_at, updated_at, format=true) => {
      const itemsDone =  data_replicated ? done : done * batch_size
      const duration_minutes = DateDiff(updated_at, started_at, false) / 1000 / 60
      const rate = itemsDone/(duration_minutes == 0 ? 1 : duration_minutes)
      if (format){
        return `${rate.toFixed(0)} `+t('table.label.jobs.processRateSuffix')
      } else {
        return rate
      }
  }

  const timeLeft = (total, data_replicated, batch_size, done, started_at, updated_at) => {
    const itemsDone = data_replicated ? done : done * batch_size
    const itemsTotal = data_replicated ? total : total * batch_size
    const itemsLeft = itemsTotal - itemsDone
    const rate = processingRate(data_replicated, batch_size, done, started_at, updated_at, false)
    const timeLeftInMinutes = itemsLeft/(rate == 0 ? 1 : rate)
    const days = timeLeftInMinutes / 60 / 24
    let days_string = ""
    if (days >= 1){
        days_string = `${days.toFixed(0)}d `
    }
    return days_string + moment.utc(moment.duration(timeLeftInMinutes * 60, "seconds").asMilliseconds()).format("HH:mm:ss")
}

  const executionFormat = (duration) => {
    return duration.replace('seconds',t('datetime.seconds'))
                   .replace('minutes',t('datetime.minutes'))
                   .replace('hours',t('datetime.hours'))
                   .replace('days',t('datetime.days'))
  }

  return (
    <div className={classes.root}>

          <Grid container>
            <Grid item xs={2}>
              <Tooltip title={t("button.refresh")}>
                <Button onClick={()=>updateTable()}>
                  <Refresh />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={10}>
              <StatusFilterBar updateTable={updateTable} filters={filters} setFilters={setFilters}/>
            </Grid>
          </Grid>
          <Card>
          <CardContent className={classes.content}>
            <PerfectScrollbar>
              <div className={classes.inner}>
                  <LoadingSpinner loading={loading} />
                  <NoResultsFound show={(!loading && typeof tableData != "undefined" && tableData.count == 0)}/>
                  <ErrorOccurred display={(!loading && errorOccurred!=false)?"block":"none"} msg={errorOccurred}/>
                  <Box component="div" display={(!loading && typeof tableData != "undefined"  && tableData.count > 0)?"block":"none"}>
                    <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                            <TableSortLabel
                              active={pagination.sort === "id"}
                              direction={pagination.order}
                              onClick={handlePaginationSortChange("id")}
                            >
                              {t('table.label.jobs.id')}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={pagination.sort === "job_config_id"}
                              direction={pagination.order}
                              onClick={handlePaginationSortChange("job_config_id")}
                            >
                              {t('table.label.jobs.name')}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={pagination.sort === "created_at"}
                              direction={pagination.order}
                              onClick={handlePaginationSortChange("created_at")}
                            >
                              {t('table.label.jobs.created_at')}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={pagination.sort === "updated_at"}
                              direction={pagination.order}
                              onClick={handlePaginationSortChange("updated_at")}
                            >
                              {t('table.label.jobs.updated_at')}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={pagination.sort === "started_at"}
                              direction={pagination.order}
                              onClick={handlePaginationSortChange("started_at")}
                            >
                              {t('table.label.jobs.started_at')}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={pagination.sort === "finished_at"}
                              direction={pagination.order}
                              onClick={handlePaginationSortChange("finished_at")}
                            >
                              {t('table.label.jobs.finished_at')}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            {t('table.label.jobs.duration')}
                          </TableCell>
                          <TableCell>
                            {t('table.label.jobs.processRate')}
                          </TableCell>
                          {/* <TableCell>
                            {t('table.label.jobs.timeLeft')}
                          </TableCell> */}
                          <TableCell>
                              {t('table.label.jobs.progress')}
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={pagination.sort === "status"}
                              direction={pagination.order}
                              onClick={handlePaginationSortChange("status")}
                            >
                              {t('table.label.jobs.status')}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>

                          </TableCell>
                      </TableRow>
                    </TableHead>
                      <TableBody>
                        {((typeof tableData != "undefined") && tableData.count > 0) &&
                            tableData.data.map((row, index) => (
                              <React.Fragment>
                                  <TableRow
                                    className={classes.tableRow}
                                    hover
                                    key={row.id}
                                  >
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{row.job_config.name}</TableCell>
                                    <TableCell>{(row.created_at)?formatDate(row.created_at):""}</TableCell>
                                    <TableCell>{(row.updated_at)?formatDate(row.updated_at):""}</TableCell>
                                    <TableCell>{(row.status.description != 'created' && row.started_at)?formatDate(row.started_at):""}</TableCell>
                                    <TableCell>{(row.finished_at)?formatDate(row.finished_at):""}</TableCell>
                                    <TableCell>
                                      {
                                        (row.status.description != 'created' && row.started_at) && (
                                            (row.finished_at)?
                                                executionFormat(DateDiff(row.finished_at, row.started_at))
                                            :
                                              (row.updated_at)&&
                                                executionFormat(DateDiff(row.updated_at, row.started_at))
                                          )
                                      }
                                    </TableCell>
                                    <TableCell>
                                      {
                                        (row.status.description != 'created' && row.started_at) && (
                                          (row.finished_at)?
                                            processingRate(row.job_config.data_replicated, row.job_config.batch_size, row.itemsDone, row.finished_at, row.started_at)
                                          :
                                            (row.updated_at)&&
                                              processingRate(row.job_config.data_replicated, row.job_config.batch_size, row.itemsDone, row.updated_at, row.started_at)
                                        )
                                      }
                                    </TableCell>
                                    <TableCell>
                                      {(row.itemsDone && row.itemsCount)&&
                                          <Grid container alignItems="center" justifyContent="center">
                                             <Grid item xs={12}>
                                                <Typography variant="overline">{(row.itemsDone!=null) ? row.itemsDone : 0} / {(row.itemsCount!=null) ? row.itemsCount : 0} ({(row.itemsDone / row.itemsCount * 100).toFixed(0)}%)</Typography>
                                              </Grid>

                                            <Grid item xs={12}>
                                              <LinearProgress variant="determinate" value={row.itemsDone / row.itemsCount * 100} />
                                            </Grid>
                                          </Grid>
                                      }
                                    </TableCell>
                                    <TableCell>{(row.status.description)?t("status.job."+row.status.description):""}</TableCell>
                                    <TableCell>
                                      <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                          {row.status && (row.status.description!="running" && row.status.description!="canceled" && row.status.description!="finished")&&
                                            <Tooltip title={t("button.cancel")}>
                                                <IconButton aria-label="cancel" size="small" onClick={()=>{handleCancel(row.id)}}>
                                                  <CancelIcon />
                                                </IconButton>
                                            </Tooltip>
                                          }
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Tooltip title={t("button.detail")}>
                                            <IconButton aria-label="expand row" size="small" onClick={() => open==index?setOpen(null):setOpen(index)}>
                                              {open == index ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                            </IconButton>
                                          </Tooltip>
                                        </Grid>
                                      </Grid>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={row.id+"detail"}>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                                      <Collapse in={(open== index)} timeout="auto" unmountOnExit>
                                        <Box margin={1}>
                                        <Typography variant="h6" gutterBottom component="div">
                                          {t('jobs.info')}
                                        </Typography>
                                        <JsonTreeView json={row.status} capitalizeLabels={true} emptyLabel={t('jobs.info.empty')}/>
                                        </Box>
                                      </Collapse>
                                    </TableCell>
                                  </TableRow>
                              </React.Fragment>
                            ))}
                      </TableBody>
                      </Table>
                  </Box>
              </div>
            </PerfectScrollbar>
          </CardContent>
          <CardActions className={classes.actions}>
            { (typeof tableData != "undefined" && tableData.count > 0) &&
                <CustomTablePagination
                  component="div"
                  count={tableData.total}
                  onPageChange={handlePaginationPageChange}
                  onRowsPerPageChange={handlePaginationSizeChange}
                  page={pagination.page}
                  rowsPerPage={pagination.size}
                  rowsPerPageOptions={Constants.DEFAULT_ROWS_SIZE_OPTIONS}
                />
            }
          </CardActions>
        </Card>
        <Box mt={3}>
          <Grid
              container
              spacing={4}
            >
              <BackButton />
          </Grid>
        </Box>
    </div>
  //);
  )
  }
export default withSnackbar(withRouter(Execution));