import { IconButton, List, ListItem, ListItemText, Popover, Tooltip } from '@material-ui/core';
import { AddCircleOutline as AddCircleOutlineIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  list: {
    padding: theme.spacing(0)
  }
}));

const AddEntityContext = (props) => {
  
    const { t } = useTranslation();
    const classes = useStyles();  
    const [anchorEl, setAnchorEl] = useState(null);    
  
    return (
      <>
  
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >

          <List
            component="nav"
            className={classes.list}
          >

            {props.entities.map((item) => (
              <ListItem 
                button 
                key={item}  
                onClick={()=>{ 
                  props.onItemClicked(item) 
                  setAnchorEl(null) 
                }}>
                <ListItemText primary={t('label.' + item)} />
              </ListItem>
            ))}

          </List>
        </Popover>  

        <Tooltip title={t('button.add.entity')}>            
          <span>
            <IconButton 
                color="inherit"
                onClick={(e)=>{ setAnchorEl(e.currentTarget) }}>
              <AddCircleOutlineIcon fontSize="large" />                          
            </IconButton>  
          </span>
        </Tooltip>
      </>
    );
  }

AddEntityContext.propTypes = {
  onItemClicked: PropTypes.func,
  entities: PropTypes.array
};  

export default AddEntityContext;