import React from 'react';
import { Box, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';


const LoadingSpinner = props => {
  const {loading, color, size} = props

  return (
    <Box m={2} component="div" display={(loading)?"block":"none"}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={3}>
          <CircularProgress size={(size)?size:30} color={(color)?color:"primary"} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoadingSpinner;
