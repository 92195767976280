import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { TableToolbar, DataTable } from './components';
import mockData from './data';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

const Search = () => {
  const classes = useStyles();

  const [tableData] = useState(mockData);

  return (
    <div className={classes.root}>
      <TableToolbar />
      <div className={classes.content}>
        <DataTable tableData={tableData} />
      </div>
    </div>
  );
};

export default Search;
