import React from 'react';

import * as Constants from 'common/systemConstants';
import { EntityList, LabelCPFCNPJ } from 'components';

const Agencies = (props) => {
  return (
    <EntityList
      objectTypeId={Constants.OBJECT_TYPE_IDS.AGENCY}
      entity={"agency"}
      exportCsv={false}
      sort={{
        column: "id",
        order: Constants.ORDER_DESC
      }}
      columns={[
        {
          name: "name",
          ordered: true
        },
        {
          name: "code",
          ordered: true,
          component: (row) => (<LabelCPFCNPJ value={row.code} />)
        }
      ]}
    />
  )
}

Agencies.propTypes = {
};

export default Agencies;