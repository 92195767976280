import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button
} from '@material-ui/core';
import { SearchInput } from 'components';
import CollectionIcon from '@material-ui/icons/AddToPhotosOutlined';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {},
  spacer: {
    flexGrow: 1
  },
  formControl: {
    width: '100%'
  }
}));

const Toolbar = props => {
  const { className, ...rest } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [values, setValues] = useState({
    scope: '',
    power: ''
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
          container
          spacing={4}
        >

          <Grid
            item
            xs={12}
            md={3}
          >
            <FormControl className={classes.formControl}>
              <TextField
                id="beginDate"
                label="Data inicial"
                type="date"
                defaultValue="2020-01-02"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
          >
            <FormControl className={classes.formControl}>
              <TextField
                id="endDate"
                label="Data final"
                type="date"
                defaultValue="2020-04-12"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
          >
            <FormControl className={classes.formControl}>
              <TextField
                id="beginValue"
                label="Valor inicial"
                defaultValue=""
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
          >
            <FormControl className={classes.formControl}>
              <TextField
                id="endValue"
                label="Valor final"
                defaultValue=""
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            md={2}
          >
            <FormControl className={classes.formControl}>
              <InputLabel>Tipo</InputLabel>
              <Select
                name="itemType"
                value={values.itemType}
                onChange={handleChange}
              >
                <MenuItem value={1}>Obra</MenuItem>
                <MenuItem value={2}>Serviço</MenuItem>
                <MenuItem value={3}>Venda</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            md={2}
          >
            <FormControl className={classes.formControl}>
              <InputLabel>Poder</InputLabel>
              <Select
                name="power"
                value={values.power}
                onChange={handleChange}
              >
                <MenuItem value={1}>Executivo</MenuItem>
                <MenuItem value={2}>Legislativo</MenuItem>
                <MenuItem value={3}>Judiciário</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            md={2}
          >
            <FormControl className={classes.formControl}>
              <InputLabel>Abrangência</InputLabel>
              <Select
                name="scope"
                value={values.scope}
                onChange={handleChange}
              >
                <MenuItem value={1}>Nacional</MenuItem>
                <MenuItem value={2}>Estadual</MenuItem>
                <MenuItem value={3}>Municipal</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={10}
            md={4}
          >
            <SearchInput
              className={classes.searchInput}
              placeholder="Procurar risco"
            />

          </Grid>

          <Grid item
              xs={2}
              md={2}
              align="right"
              justifyContent="right">
            <Button
              color="primary"
              variant="contained"
              startIcon={<CollectionIcon />}
            >
              {t('collection.add')}
            </Button>

          </Grid>

        </Grid>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
