import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import config from 'config';

const useStyles = makeStyles(theme => ({
  root: {
  }
}));

const Footer = props => {
  const { className, ...rest } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography variant="body1">

        {t('footer.copyright.prefix')}

        <Link
          component="a"
          href={t('system.copyright.url')}
          target="_blank"
        >          
          {t('system.title')}
        </Link>

        . {t('system.copyright.year')}

      </Typography>
      <Typography variant="caption">
        {t('system.subtitle')}
      </Typography>
      <Typography variant="caption">
        {t('footer.version.label')}: {config.app_version} / Build: {config.builddate} / Env: {config.environment}
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
