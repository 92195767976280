import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid, TextField,Divider, Typography, Box, Tooltip, IconButton, Link
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { truncateMiddle, BackButton, LoadingSpinner, RenderRelationships, DetailsNavigation } from 'components';
import { MenuBook, FilterNone as CopyIcon  } from '@material-ui/icons';
import RiskTable from './Components/RisksTable';
import PropertiesTable from './Components/PropertiesTable';
import SourceTable from './Components/SourceTable';
import { gazettesService, publicationsService } from 'services';
import parse from 'html-react-parser';
import * as Constants from 'common/systemConstants';
import CollectionsContext from 'components/CollectionsContext';
import { collectionsService } from 'services';
import { withSnackbar } from 'hooks/withSnackbar';
import { formatDate } from 'helpers/format'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  heading: {
    marginBottom: theme.spacing(2)
  }
}));

const Publication = (props) => {
  const { match } = props
  const objectId = match.params.id
  const { t } = useTranslation();
  const classes = useStyles();
  const defaultObject = {
    id: '',
    title: '',
    date: '',
    content: {text:''},
    created_at: '',
    url: '',
    properties: '',
    lastAnalysis: ''
  }
  const [object, setObject] = useState(null);
  const [isLoadingProperties, setIsLoadingProperties] = useState(false);
  const [collectionsData, setCollectionsData] = useState();

  React.useEffect(() => {
    updateCollections();
  }, []);

  const updateCollections = () => {
    if(props.disableCollections)
      return
    collectionsService.getPage(
      {
        size: 100,
        page: 0,
        sort: 'name',
        order: 'asc'
      },
      false,
      'false'
    )
      .then((data) => {
        setCollectionsData(data)
      })
      .catch((except) => {
        props.showMessageError(except.message, except.detailedMessage)
      });
  }

  const handleClickOnCollection = () => {
    updateCollections()
  };

  function handleOnItemChanged(item) {
    if (item && item.id_gazette){
      gazettesService
        .getById(item.id_gazette)
        .then(async gazette => {
          item.gazette = gazette
          setObject(item)
        });
    } else {
      setObject(item)
    }
  }

  function copy(text){
    navigator.clipboard.writeText(text)
    props.showMessageSuccess(t('label.copied'))
  }

  function onPropertiesUpdated(jsonData) {
    setIsLoadingProperties(true)
    const properties = {
      properties: jsonData
    }
    publicationsService.replaceProperties(objectId, properties)
      .then(results => {
        const tempObject = {
          ...object,
          ...{ properties: properties }
        }
        setObject(tempObject)
        setIsLoadingProperties(false)
      })
      .catch((except) => {
        setIsLoadingProperties(false)
        props.showMessageError(except.message, except.detailedMessage)
      });
  }

  function getTitle(object) {
    if (object.content.Identifica) {
      return object.content.Identifica
    }
    if (object.title) {
      return object.title
    }
    if (object.content.Identifica) {
      return object.content.text.substr(0,50)
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.tableHeader}>

        <Box
          display={(!object) ? 'none' : 'block'}
          p={2}
        >
          <Grid
            container
            justifyContent="center"
            spacing={4}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <DetailsNavigation
                entity={'publication'}
                initialItemId={objectId}
                onItemChanged={handleOnItemChanged}
              />
            </Grid>
          </Grid>
        </Box>

        {!object ?
          <LoadingSpinner
            color="inherit"
            loading={!object}
            size={25}
          />
          :
          <Box p={2}>
            <Grid
              container
              justifyContent="center"
              spacing={4}
            >
              <Grid
                item
                xs={2}
                md={1}
              >
                  <CollectionsContext
                    collections = {collectionsData}
                    itemId={parseInt(objectId)}
                    objectTypeId={Constants.OBJECT_TYPE_IDS.PUBLICATION}
                    onCollectionClicked = {handleClickOnCollection}
                  />
              </Grid>
              <Grid
                item
                xs={10}
                md={2}
              >
                <TextField
                  InputProps={{readOnly: true}}
                  fullWidth
                  label={t('label.id')}
                  margin="dense"
                  name="id"
                  value={object.id}
                  variant="standard"
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={7}
              >
                <div>
                  <TextField
                    InputProps={{readOnly: true}}
                    fullWidth
                    label={t('label.title')}
                    margin="dense"
                    name="title"
                    value={getTitle(object)}
                    variant="standard"
                  />
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                md={2}
              >
                <TextField
                  InputProps={{readOnly: true}}
                  fullWidth
                  label={t('label.obtained_at')}
                  margin="dense"
                  name="created_at"
                  value={formatDate(object.created_at)}
                  variant="standard"
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={3}
              >
                <TextField
                  InputProps={{readOnly: true}}
                  fullWidth
                  label={t('label.publication.date')}
                  margin="dense"
                  name="date"
                  value={(object.date)?formatDate(object.date,'DD/MM/YYYY'):''}
                  variant="standard"
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={7}
              >
                <TextField
                  InputProps={{readOnly: true}}
                  fullWidth
                  label={t('label.gazette')}
                  margin="dense"
                  name="gazette"
                  value={(object.gazette)?object.gazette.name:''}
                  variant="standard"
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={2}
              >
                <TextField
                  InputProps={{readOnly: true}}
                  fullWidth
                  label={t('label.updated_at')}
                  margin="dense"
                  name="updated_at"
                  value={object.updated_at ? formatDate(object.updated_at) : ''}
                  variant="standard"
                />
              </Grid>

              <Grid
                item
                md={12}
                xs={12}
              >
                <Typography
                  className={classes.heading}
                  variant={'h5'}
                >{t('label.url')}
                </Typography>

                <Box display='flex' alignItems='center'>
                  <Tooltip title={t('button.copy.clipboard')}>
                    <IconButton onClick={() => {copy(object.url)}}>
                      <CopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Link href={object.url}
                        target="_blank"
                        style={{fontFamily: 'arial'}}>
                      {truncateMiddle(object.url, 100, 100)}
                  </Link>
                </Box>

              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <Typography
                  className={classes.heading}
                  variant={'h5'}
                >{t('label.text')}
                </Typography>
                <div>
                  {parse(object.content.text)}
                </div>
                <Divider />
              </Grid>
              <Divider />
              <Grid
                item
                md={12}
                xs={12}
              >
                <RiskTable
                  classes={classes}
                  object={object}
                />
              </Grid>
              <Divider />
              <Grid
                item
                md={12}
                xs={12}
              >

                {isLoadingProperties ?
                  <LoadingSpinner
                    color="inherit"
                    loading
                    size={25}
                  />
                  :
                  <PropertiesTable
                    jsonData={(object.properties)?object.properties.properties:null}
                    onJsonDataUpdated={onPropertiesUpdated}
                    title={t('label.properties') + ((typeof object != 'undefined' && object.lastAnalysis) ? `(${t('label.lastAnalysis')}: ${formatDate(object.lastAnalysis)})` : '')}
                  />
                }

              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <RenderRelationships
                  classes={classes}
                  entity={'publication'}
                  id={object.id}
                />
              </Grid>
              <Divider />
              <Grid
                item
                md={12}
                xs={12}
              >
                <SourceTable
                  classes={classes}
                  object={object}
                />
              </Grid>
            </Grid>
          </Box>
        }

      </div>

      <Box mt={3}>
        <Grid
          container
          spacing={4}
        >
          <BackButton />
        </Grid>
      </Box>

    </div>
  );
};

export default withSnackbar(withRouter(Publication));
