import { Divider, Drawer } from '@material-ui/core';
import { Info as InfoIcon, Business as BusinessIcon, Category, Dashboard as DashboardIcon, DynamicFeed, HomeWork as HomeWorkIcon, Announcement as LogIcon, MenuBook as MenuBookIcon, Settings as SettingsIcon, WatchSharp,
  Apps as EntitiesIcon, NewReleases, BlurCircular as SphereIcon, Gavel, Watch, Timer } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Constants from 'common/systemConstants';
import { usersService } from 'services';

import { Profile, SidebarNav } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { keycloak, open, variant, onClose, className, ...rest } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  let pages = []

  if (usersService.getRole(keycloak).includes(Constants.DEFAULT_MAIN_PROFILE)){
    let mainPages = [
      {
        title: t('menu.home'),
        href: '/dashboard',
        icon: <DashboardIcon />
      },
      {
        title: t('menu.publications'),
        href: '/publications',
        icon: <MenuBookIcon />
      },
      {
        title: t('menu.agencies'),
        href: '/agencies',
        icon: <HomeWorkIcon />
      },
      {
        title: t('menu.companies'),
        href: '/companies',
        icon: <BusinessIcon />
      },
      {
        title: t('menu.collections'),
        href: '/collections',
        icon: <DynamicFeed />
      }
    ];
    pages = pages.concat(mainPages)
  }

  if (usersService.getRole(keycloak).includes(Constants.DEFAULT_ADMIN_PROFILE)){
    let adminPages = [
      {
        title: t('menu.jobs'),
        href: '/jobs',
        icon: <Timer />
      },
      {
        title: t('menu.logs'),
        href: '/logs',
        icon: <LogIcon />
      },
      {
        title: t('menu.configs'),
        href: '/settings',
        icon: <SettingsIcon />
      },
      {
        title: t('menu.gazettes'),
        href: '/gazettes',
        icon: <MenuBookIcon />
      },
      {
        title: t('menu.spheres'),
        href: '/spheres',
        icon: <SphereIcon />
      },
      {
        title: t('menu.objectTypes'),
        href: '/objectTypes',
        icon: <EntitiesIcon />
      },
      {
        title: t('menu.riskTypes'),
        href: '/riskTypes',
        icon: <NewReleases />
      },
    ];
    pages = pages.concat(adminPages)
  }

  let defaultPages = [
       {
        title: t('menu.otherServices'),
        href: '/other-services',
        icon: <Category />
      },
      {
        title: t('menu.about'),
        href: '/about',
        icon: <InfoIcon />
      },
    ];
  pages = pages.concat(defaultPages)

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
