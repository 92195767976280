import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { gazettesService, spheresService } from 'services';
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'components/LoadingSpinner';
import { Save } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import BackButton from 'components/BackButton';
import { useHistory } from "react-router-dom";
import validator from 'validator'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  tableContent: {
    marginTop: theme.spacing(2)
  },
  toolbar: {
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  spacer: {
    flexGrow: 1
  },
  formControl: {
    width: '100%'
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const GazetteForm = ({ match }) => {
  const [loading, setLoading] = useState();
  const { t } = useTranslation();
  const classes = useStyles();
  const [data, setData] = useState({
    name:"",
    id_sphere:"",
    uri:"",
    id:null
  });
  const [spheres, setSpheres] = useState()

  const [errors, setErrors] = useState({
    name:null
  });
  const history = useHistory();


  const handleChange = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  React.useEffect(() => {
    if (match.params.id && data.id == null){
      loadData()
    }
    if (!spheres){
      loadDataSpheres()
    }
  });

  function loadData() {
    setLoading(true)
    gazettesService.getById(match.params.id).then(data => {setData(data);setLoading(false)});
  }

  function loadDataSpheres() {
    setLoading(true)
    let pagination = {
      size: 50,
      page: 0,
      sort: 'name',
      order: 'asc'
    }
    spheresService.getPage(pagination).then(data => {setSpheres(data);setLoading(false)});
  }



  const handleSave = () => {
    let hasError = false
    let errors = {}

    if (!data.name){
      errors = {...errors,name:t('form.requiredField')}
      hasError = true
    }

    if (!data.uri){
      errors = {...errors,uri:t('form.requiredField')}
      hasError = true
    }

    if (data.uri && !validator.isURL(data.uri)){
      errors = {...errors,uri:t('form.shouldBeURL')}
      hasError= true
    }

    if (!data.id_sphere){
      errors = {...errors,id_sphere:t('form.requiredField')}
      hasError = true
    }

    if (!hasError) {
      setErrors({
        name:null,
        uri:null,
        id_sphere:null,
      })
      gazettesService.save(data).then(
        history.goBack()
      )
    } else {
      setErrors(errors)
    }
  }

  return (
    <div className={classes.root}>
        <Box mb={2} display={(loading)?"none":"block"}>
          <Typography variant="h4">{(data.id)?t('gazette.operation.edit'):t('gazette.operation.create')} - {t('gazette.title')}</Typography>
        </Box>
        <Card>
          <LoadingSpinner loading={loading}/>
          <Box mx={2} my={2} display={(loading)?"none":"block"}>
            <CardContent className={classes.content}>
              <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      margin="dense"
                      onChange={handleChange}
                      required
                      variant="standard"
                      InputProps={{ readOnly: false }}
                      label={t('gazette.label.name')}
                      name="name"
                      value={data.name}
                      error={errors.name != null}
                      helperText={!errors.name ? '' : errors.name}
                    />
                  </Grid>
                  <Grid item  xs={12}>
                    <TextField
                      fullWidth
                      margin="dense"
                      onChange={handleChange}
                      required
                      variant="standard"
                      InputProps={{ readOnly: false }}
                      label={t('gazette.label.uri')}
                      name="uri"
                      value={data.uri}
                      error={errors.uri != null}
                      helperText={!errors.uri ? '' : errors.uri}
                    />
                  </Grid>

                  <Grid item  xs={12}>
                    <TextField
                      select
                      fullWidth
                      margin="dense"
                      onChange={handleChange}
                      required
                      variant="standard"
                      InputProps={{ readOnly: false }}
                      label={t('gazette.label.sphere')}
                      name="id_sphere"
                      value={data.id_sphere}
                      error={errors.id_sphere != null}
                      helperText={!errors.id_sphere ? '' : errors.id_sphere}
                    >
                      {
                          (typeof spheres != 'undefined' && spheres.count) && spheres.data.map(row =>
                            <MenuItem value={row.id}>{row.name}</MenuItem>
                          )
                      }
                    </TextField>
                  </Grid>
              </Grid>
            </CardContent>
            <CardActions className={classes.actions}>
              <Box mx={0} my={1}>
                <Grid container justifyContent = "flex-end">
                        <Button
                        onClick={handleSave}
                        color="primary"
                        variant="contained">
                          <Save /> {t('button.save')}
                        </Button>
                </Grid>
              </Box>
            </CardActions>
          </Box>
        </Card>
        <Box mt={3}>
          <Grid
              container
              spacing={4}
            >
              <BackButton />
          </Grid>
        </Box>
    </div>
  )
  }
export default withRouter(GazetteForm);