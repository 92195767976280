import React from 'react';
import { i18n, languages } from 'translation/i18n';
import PublicIcon from '@material-ui/icons/Public';
import { withStyles } from '@material-ui/styles';
import { 
  IconButton, 
  Menu, 
  ListItemText, 
  ListItem
} from '@material-ui/core';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const LanguageButton = props => {
  
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [currentLang, setCurrentLang] = React.useState(i18n.language);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleLanguageClick = (event, lang) => {    
    i18n.changeLanguage(lang);
    setCurrentLang(i18n.language);
    handleClose();
  };

  return (
    <div>  

      <StyledMenu
        id="customized-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languages.map(language => (
          <ListItem
            key={language.lang}
            button
            selected={currentLang === language.lang}
            onClick={(event) => handleLanguageClick(event, language.lang)}
          >
            <ListItemText primary={language.option} />
          </ListItem>
        ))}
      </StyledMenu>

      <IconButton color="inherit" onClick={handleClick}>
          <PublicIcon />
      </IconButton> 

    </div>
  );
};

export default LanguageButton;
