import { IconButton, Tooltip } from '@material-ui/core';
import { AccountTree } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import * as Constants from 'common/systemConstants';
import { EntityList } from 'components';

const SettingsHistory = () => {
  const { t } = useTranslation();
  return (
    <EntityList
      disableCollections={true}
      disableDetails={true}
      objectTypeId={Constants.OBJECT_TYPE_IDS.UNKNOWN}
      entity={"general/settings/history"}
      exportCsv={false}
      sort={{
        column : "created_at",
        order : Constants.ORDER_DESC
      }}
      columns={[
          {
            name: "label",
            ordered: false
          },
          {
            name: "username",
            ordered: false
          },
          {
            name: "created_at",
            ordered: false,
          },
          {
            name: "change_description",
            ordered: false
          },
        ]}
    />
  )
}

SettingsHistory.propTypes = {
};

export default SettingsHistory;
