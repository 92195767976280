
import uuid from 'uuid/v1';

export default [
  {
    id: uuid(),
    itemType: 'agency',
    description: 'Ministério das cidades',
  },
  {
    id: uuid(),
    itemType: 'company',
    description: 'Empresa ABCD',
  },
  {
    id: uuid(),
    itemType: 'risk',
    description: 'Obra - Processo: 1.234.567/1234',
  },
  {
    id: uuid(),
    itemType: 'publication',
    description: 'Portaria nº 300, de 21 de Maio de 2020',
  }    
];
