import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';

import sampleAvatarImage from "public/images/avatars/avatar_11.png";

import { usersService } from 'services';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const user = {
    name: (usersService.currentUserValue) ? usersService.currentUserValue.name : null,
    avatarImage: (usersService.currentUserValue) ?  usersService.currentUserValue.avatarImage : null,
    position: (usersService.currentUserValue) ?  usersService.currentUserValue.position : null
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        src={user.avatarImage}
        to="/dashboard"
        //to="/account"
      />
      <Typography
        className={classes.name}
        variant="h4"
      >
        {user.name}
      </Typography>

      { user.position &&
        <Typography variant="body2">
          {Array.isArray(user.position) ? user.position.join(" / ") : user.position}
        </Typography>
      }
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
