import config from 'config';
import { authHeader, handleResponse, doFetch } from 'helpers';
import { createPaginationObject } from 'helpers/pagination';
import { objectToQueryString } from 'helpers/url';

export const logsService = {
    getById,
    getPage,
    getBackendLog,
};

function getById(id) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/log/${id}`, requestOptions).then(handleResponse);
}

function getPage(
    pagination,
    filterByGeneric,
    filterByType) {

    let paginationObj = createPaginationObject(
        pagination.page, pagination.size, pagination.sort, pagination.order);
    if(filterByGeneric) {
        paginationObj.filterByGeneric = filterByGeneric;
    }
    if(filterByType) {
        paginationObj.filterByType = filterByType;
    }
    let queryString = objectToQueryString(paginationObj);
    let url = `${config.apiUrl}/logs` + (queryString && ("?" + queryString));
    const requestOptions = { method: 'GET', headers: authHeader() };
    const result = fetch(url, requestOptions).then(handleResponse);
    return result
}

function getBackendLog(service) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return doFetch(`${config.apiUrl}/info/logs/${service}`, requestOptions);
}