import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';

const focusInColor = '#ad9200';
const errorColor = '#e53935';

export default {
  MuiButton: {
    containedPrimary: {
      "&:hover": {
        backgroundColor: focusInColor,
        '@media (hover: none)': {
          backgroundColor: focusInColor,
        },    
      }
    }, 
  },
  MuiTextField: {
    root: {
      "& .MuiInput-underline.Mui-error:after": {
        borderBottomColor: errorColor
      },
      "& .MuiFormLabel-root.Mui-error": {
        color: errorColor
      },
      "& label.Mui-focused": {
        color: focusInColor
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: focusInColor
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottomColor: focusInColor
      },
    }
  },
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiTypography
};
