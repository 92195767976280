import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools"

const SourceTable = props => {
  const { object, classes } = props
  const { t } = useTranslation();
  return (
    <div>
        <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                    variant={"h5"}
                    className={classes.heading}>{t('label.content')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={12}
                  >
                    <AceEditor
                        style={{width: "100%"}}
                        mode="json"
                        theme="github"
                        readonly
                        wrapEnabled
                        tabSize={2}
                        value={JSON.stringify(object.content, null, 2)}/>
                    <Divider />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
    </div>
  );
};

SourceTable.propTypes = {
};

export default SourceTable;