import { Box, Card, Checkbox, FormControlLabel, Grid, MenuItem, TextField } from '@material-ui/core';
import { formatDate } from 'helpers/format'
import React, { useState }  from 'react';
import { useTranslation } from 'react-i18next';
import { jobConfigService, jobExecutionService } from 'services';


const StatusFilterBar = (props) => {
  const { filters, setFilters, showFilterExecution } = props
  const { t } = useTranslation();
  const [job_configs, setJob_configs] = useState();
  const [job_executions, setJob_executions] = useState();

  const handleChange = (event) =>{
    if (event.target.name == "job_config_id" || event.target.name == "job_execution_id"){
      setFilters({
        ...filters,
        [event.target.name]:event.target.value
      })
      if (event.target.name == "job_config_id" && showFilterExecution){
        loadJobExecutions(event.target.value)
      }
    } else {
      setFilters({
        ...filters,
        status:{...filters.status,[event.target.name]:event.target.checked}
      })
    }
  }

  const [paginationJobConfig, setPaginationJobConfig] = useState({
    size: 500,
    page: 0,
    sort: "name",
    order: "asc"
  });

  const [paginationJobExecution, setPaginationJobExecution] = useState({
    size: 500,
    page: 0,
    sort: "id",
    order: "desc"
  });

  function loadJobConfigs() {
    jobConfigService.getPage(
      paginationJobConfig
    ).then(tableData => {
      setJob_configs(tableData)
    })
    .catch((e)=>{})
  }

  function loadJobExecutions(job_config_id) {
    jobExecutionService.getPage(
      paginationJobExecution,
      null,
      null,
      job_config_id
    ).then(tableData => {
      setJob_executions(tableData)
    })
    .catch((e)=>{})
  }

  React.useEffect(() => {
    if (typeof job_configs == "undefined"){
      loadJobConfigs()
    }
  });


  return (

    <Grid container justifyContent="flex-end">
      <Box my={2}>
          <Card>
            <Box mx={2}>
                <FormControlLabel
                    control={
                      <TextField
                        select
                        style={{marginRight:"20px",marginLeft:"10px"}}
                        onChange={handleChange}
                        value={filters.job_config_id}
                        name="job_config_id"
                        color="primary"
                      >
                        <MenuItem value="">---</MenuItem>
                        {((typeof job_configs != "undefined" && job_configs.count > 0)) && job_configs.data.map((row, index)=>(
                            <MenuItem id={row.id} value={row.id}>{row.name}</MenuItem>
                          ))}
                      </TextField>
                    }
                    label={t(`status.job.config`)}
                    labelPlacement="start"
                />
                {(showFilterExecution) && (
                  <FormControlLabel
                      control={
                        <TextField
                          select
                          style={{marginRight:"20px",marginLeft:"10px"}}
                          onChange={handleChange}
                          value={filters.job_execution_id}
                          name="job_execution_id"
                          color="primary"
                        >
                          <MenuItem value="">---</MenuItem>
                          {((typeof job_executions != "undefined" && job_executions.count > 0)) && job_executions.data.map((row, index)=>(
                              <MenuItem id={row.id} value={row.id}>{row.id} - {formatDate(row.created_at)}</MenuItem>
                            ))}
                        </TextField>
                      }
                      label={t(`status.job.execution`)}
                      labelPlacement="start"
                  />
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.status.created}
                      onClick={handleChange}
                      name="created"
                      color="primary"
                    />
                  }
                  label={t(`status.job.created`)}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.status.running}
                      onClick={handleChange}
                      name="running"
                      color="primary"
                    />
                  }
                  label={t(`status.job.running`)}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.status.canceled}
                      onClick={handleChange}
                      name="canceled"
                      color="primary"
                    />
                  }
                  label={t(`status.job.canceled`)}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.status.error}
                      onClick={handleChange}
                      name="error"
                      color="primary"
                    />
                  }
                  label={t(`status.job.error`)}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.status.finished}
                      onClick={handleChange}
                      name="finished"
                      color="primary"
                    />
                  }
                  label={t(`status.job.finished`)}
                />
            </Box>
          </Card>
        </Box>
    </Grid>
  )
  }
export default StatusFilterBar;