import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import * as Constants from 'common/systemConstants';
import { dashboardService, usersService } from 'services';

import { CountAgencies, CountCompanies, CountCompaniesAndAgenciesRelationship, CountPublications, CountRiskCollusionInPublication } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Dashboard = (props) => {
  const { keycloak } = props
  const classes = useStyles();
  const actualRole = usersService.getRole(keycloak)
  const [data, setData] = useState();
  function updateTable () {
     dashboardService.getByRole("Perito Criminal").then(dash => {
        setData(dash)
       });
  }

  React.useEffect(() => {
    if (!data){
      updateTable();
    }
  }, []);


  return (
    (actualRole.includes(Constants.DEFAULT_MAIN_PROFILE))?(
    <div className={classes.root}>
      <Grid style={{textTransform: "uppercase"}}
        container
        spacing={4}
      >
        <Grid
          item
          xs={12}
        >
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              xs={12}
              md={4}
            >
              <CountPublications data={data} />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <CountAgencies data={data} />
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
            >
              <CountCompanies data={data} />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
        >

          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              xs={12}
              md={4}
            >
              <CountRiskCollusionInPublication data={data}  />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <CountCompaniesAndAgenciesRelationship data={data}  />
            </Grid>
          </Grid>

        </Grid>



      </Grid>
    </div>
    )
    :
    (
      <div></div>
    )
  );
};

export default withRouter(Dashboard);
