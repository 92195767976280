import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import theme from 'theme';

const CustomLink = props => {
  const { children, color, to, ...rest } = props;
  if (typeof color == 'undefined' ){
    const color = theme.palette.text.primary
  }
  return (
    <NavLink style={{color:color}} to={to}>{children}</NavLink>
  );
};

CustomLink.propTypes = {
  to: PropTypes.string
};

export default CustomLink;