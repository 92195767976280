import * as Constants from 'common/systemConstants';

export function createPaginationObject(page, size, sort, order) {
    let res = {};
    if(!page || isNaN(page) || page < 1){
        page = 0;
    }
    res.page = page

    if(!size || isNaN(size) || size <= 0) {
        size = Constants.DEFAULT_ROWS_SIZE;
    }
    res.size = size;

    if(sort){        
        res.sort = sort;
        if(order === Constants.ORDER_ASC || order === Constants.ORDER_DESC) {
            res.sort += "," + order;
        } else {
            res.sort += "," + Constants.ORDER_DESC;
        }
    }
    return res;
}