export function dynamicFilterToQueryString(filters){
    const keyValuePairs  = [];
    filters.forEach((item) => {
        keyValuePairs.push("filter=" + encodeURIComponent(JSON.stringify(item)));
    })
    return keyValuePairs.join('&');
}

export function objectToQueryString(object){
    const keyValuePairs  = [];
    for (const key in object) {
        keyValuePairs.push(
            encodeURIComponent(key) +
            '=' + encodeURIComponent(object[key]));
    }
    return keyValuePairs.join('&');
}

export function extractPagination(url) {
    let urlTemp = new URL(url);
    let pagination = {};
    pagination.page = urlTemp.searchParams.get("page");
    pagination.size = urlTemp.searchParams.get("size");
    pagination.sort = urlTemp.searchParams.get("sort");
    return pagination;
}

export function getCurrentPath() {
    let path = window.location.hash
    if(path && path.startsWith('#')) {
        return path.slice(1)
    }
    return window.location.pathname
}