import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { jobChunkService } from 'services';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ErrorOccurred, JsonTreeView, NoResultsFound, SearchInput } from 'components';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableSortLabel,
  TableHead,
  TableRow,
  Tooltip,
  Grid,
  IconButton,
  Box,
  Typography,
  Button,
  Collapse,
  LinearProgress
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as Constants from 'common/systemConstants';
import LoadingSpinner from 'components/LoadingSpinner';
import { Cancel as CancelIcon, KeyboardArrowDown, KeyboardArrowUp, Refresh } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import BackButton from 'components/BackButton';
import CustomTablePagination from 'components/CustomTablePagination';
import ConfirmDialog from 'components/ConfirmDialog'
import { useConfirm } from 'material-ui-confirm';
import { formatDate } from 'helpers/format'
import { StatusFilterBar } from '../Configuration';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  tableContent: {
    marginTop: theme.spacing(2)
  },
  toolbar: {
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  spacer: {
    flexGrow: 1
  },
  formControl: {
    width: '100%'
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const DEFAULT_SORT_COLUMN = "updated_at";
const DEFAULT_ORDER = Constants.ORDER_ASC;

const Batch = () => {
  const [loading, setLoading] = useState();
  const { t } = useTranslation();
  const classes = useStyles();
  const [tableData, setTableData] = useState();
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [filters, setFilters] = useState({
    description: '',
    job_config_id: '',
    job_execution_id: '',
    status: {
      created:true,
      running:true,
      error:true,
      canceled:false,
      finished:false,
    }
  });
  const [pagination, setPagination] = useState({
    size: Constants.DEFAULT_ROWS_SIZE,
    page: 0,
    sort: DEFAULT_SORT_COLUMN,
    order: DEFAULT_ORDER
  });

  React.useEffect(() => {
    updateTable();
    const interval=setInterval(()=>{
      updateTable()
     },30000)
    return()=>clearInterval(interval)
  }, [pagination, filters]);

  function updateTable () {
    setLoading(true)
    jobChunkService.getPage(
      pagination,
      filters.description,
      filters.job_config_id,
      filters.job_execution_id,
      filters.status
    ).then(tableData => {
      if (tableData.error){
        setErrorOccurred(tableData.error);
      } else {
        setErrorOccurred(false);
        setTableData(tableData);
      }
      setLoading(false)
    })
    .catch((e)=>{setErrorOccurred(true);setLoading(false)})
  }

  const handleFilterChange = event => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value
    });
  };
  const handlePaginationSizeChange = event => {
    setPagination({
      ...pagination,
      ["size"]: event.target.value
    });
  };
  const handlePaginationPageChange = (event, page) => {
    setPagination({
      ...pagination,
      ["page"]: page
    });
  };
  const handlePaginationSortChange = (property) => (event) => {
    let order = Constants.ORDER_ASC;
    if(property === pagination.sort
      && pagination.order == Constants.ORDER_ASC) {
        order = Constants.ORDER_DESC;
    }
    setPagination({
      ...pagination,
      ["sort"]: property,
      ["order"]: order
    });
  };

  const confirm = useConfirm();

  const handleRemove = (id) => {
    ConfirmDialog(confirm, t)
      .then(() => {
        jobExecutionService.remove(id).then(updateTable)
       })
      .catch(() => { /* ... */ });
  }

  const handleCancel = (id) => {
    jobChunkService.cancel(id).then(updateTable)
  }

  const handleRestart = (id) => {
    jobChunkService.restart(id).then(updateTable)
  }

  const [open, setOpen] = React.useState();


  return (
    <div className={classes.root}>
          <Grid container>
            <Grid item xs={2}>
              <Tooltip title={t("button.refresh")}>
                <Button onClick={()=>updateTable()}>
                  <Refresh />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={10}>
              <StatusFilterBar updateTable={updateTable} filters={filters} setFilters={setFilters} showFilterExecution={true}/>
            </Grid>
          </Grid>
          <Card>
          <CardContent className={classes.content}>
            <PerfectScrollbar>
              <div className={classes.inner}>
                  <LoadingSpinner loading={loading} />
                  <NoResultsFound show={(!loading && typeof tableData != "undefined" && tableData.count == 0)}/>
                  <ErrorOccurred display={(!loading && errorOccurred!=false)?"block":"none"} msg={errorOccurred}/>
                  <Box component="div" display={(!loading && typeof tableData != "undefined"  && tableData.count > 0)?"block":"none"}>
                    <Table>
                    <TableHead>
                      <TableRow>
                          <TableCell>
                            <TableSortLabel
                              active={pagination.sort === "id"}
                              direction={pagination.order}
                              onClick={handlePaginationSortChange("id")}
                            >
                              {t('table.label.jobs.id')}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={pagination.sort === "job_config_id"}
                              direction={pagination.order}
                              onClick={handlePaginationSortChange("job_config_id")}
                            >
                              Job {t('table.label.jobs.name')}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={pagination.sort === "job_execution_id"}
                              direction={pagination.order}
                              onClick={handlePaginationSortChange("job_execution_id")}
                            >
                              {t('table.label.jobs.execution')} {t('table.label.jobs.id')}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={pagination.sort === "created_at"}
                              direction={pagination.order}
                              onClick={handlePaginationSortChange("created_at")}
                            >
                              {t('table.label.jobs.created_at')}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={pagination.sort === "updated_at"}
                              direction={pagination.order}
                              onClick={handlePaginationSortChange("updated_at")}
                            >
                              {t('table.label.jobs.updated_at')}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={pagination.sort === "started_at"}
                              direction={pagination.order}
                              onClick={handlePaginationSortChange("started_at")}
                            >
                              {t('table.label.jobs.started_at')}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={pagination.sort === "finished_at"}
                              direction={pagination.order}
                              onClick={handlePaginationSortChange("finished_at")}
                            >
                              {t('table.label.jobs.finished_at')}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                              {t('table.label.jobs.progress')}
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={pagination.sort === "page"}
                              direction={pagination.order}
                              onClick={handlePaginationSortChange("page")}
                            >
                              {t('table.label.jobs.page')}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={pagination.sort === "status->>\'description\'"}
                              direction={pagination.order}
                              onClick={handlePaginationSortChange("status->>\'description\'")}
                            >
                              {t('table.label.jobs.status')}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>

                          </TableCell>
                      </TableRow>
                    </TableHead>
                      <TableBody>
                        {((typeof tableData != "undefined") && tableData.count > 0) &&
                            tableData.data.map((row,index) => (
                              <React.Fragment>
                                <TableRow
                                  className={classes.tableRow}
                                  hover
                                  key={row.id}
                                >
                                  <TableCell>{row.id}</TableCell>
                                  <TableCell>{row.job_execution.job_config.name}</TableCell>
                                  <TableCell>{row.job_execution_id} - {formatDate(row.created_at)}</TableCell>
                                  <TableCell>{(row.created_at)?formatDate(row.created_at):""}</TableCell>
                                  <TableCell>{(row.updated_at)?formatDate(row.updated_at):""}</TableCell>
                                  <TableCell>{(row.started_at)?formatDate(row.started_at):""}</TableCell>
                                  <TableCell>{(row.finished_at)?formatDate(row.finished_at):""}</TableCell>
                                  <TableCell>
                                    <Typography variant="overline" align="center">{(row.itemsDone!=null)?row.itemsDone:0}/{row.itemsCount}</Typography>
                                    <LinearProgress variant="determinate" value={row.itemsDone / row.itemsCount * 100} />
                                  </TableCell>
                                  <TableCell>{row.page}</TableCell>
                                  <TableCell>{(row.status.description)?t("status.job."+row.status.description):""}</TableCell>
                                  <TableCell>
                                    <Grid container spacing={1}>
                                      <Grid item xs={4}>
                                        {row.status && (row.status.description!="running" && row.status.description!="canceled" && row.status.description!="created" && row.status.description!="finished")&&
                                          <Box mx={2}>
                                            <Tooltip title={t("button.restart")}>
                                                <IconButton aria-label="restart" size="small" onClick={()=>{handleRestart(row.id)}}>
                                                  <Refresh />
                                                </IconButton>
                                            </Tooltip>
                                          </Box>
                                        }
                                      </Grid>
                                      <Grid item xs={4}>
                                         {row.status && (row.status.description!="running" && row.status.description!="canceled" && row.status.description!="finished")&&
                                            <Box mx={2}>
                                              <Tooltip title={t("button.cancel")}>
                                                  <IconButton aria-label="cancel" size="small" onClick={()=>{handleCancel(row.id)}}>
                                                    <CancelIcon />
                                                  </IconButton>
                                              </Tooltip>
                                            </Box>
                                          }
                                      </Grid>
                                      <Grid item xs={4}>
                                        <Box mx={2}>
                                          <Tooltip title={t("button.detail")}>
                                            <IconButton aria-label="expand row" size="small" onClick={() => open==index?setOpen(null):setOpen(index)}>
                                              {open == index ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                </TableRow>
                                <TableRow key={row.id+"detail"}>
                                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
                                    <Collapse in={(open== index)} timeout="auto" unmountOnExit>
                                      <Box margin={1}>
                                      <Typography variant="h6" gutterBottom component="div">
                                        {t('table.label.jobs.previousChunkId')}
                                      </Typography>
                                      <Typography variant="overline" gutterBottom component="div">
                                        {(row.previous_job_chunk_id)?row.previous_job_chunk_id:"-"}
                                      </Typography>
                                      {(row.nextCursorMark)&&(
                                        <div>
                                          <Typography variant="h6" gutterBottom component="div">
                                            {t('table.label.jobs.nextCursorMark')}
                                          </Typography>
                                          <Typography variant="overline" gutterBottom component="div">
                                            {row.nextCursorMark}
                                          </Typography>
                                        </div>
                                        )
                                      }

                                      {(row.queryDuration)&&(
                                        <div>
                                          <Typography variant="h6" gutterBottom component="div">
                                            {t('table.label.jobs.queryDuration')}
                                          </Typography>
                                          <Typography variant="overline" gutterBottom component="div">
                                            {row.queryDuration}
                                          </Typography>
                                        </div>
                                        )
                                      }

                                      <Typography variant="h6" gutterBottom component="div">
                                        {t('table.label.jobs.status')}
                                      </Typography>
                                      <JsonTreeView json={row.status} capitalizeLabels={true} emptyLabel={t('jobs.info.empty')}/>
                                      <Typography variant="h6" gutterBottom component="div">
                                        {t('table.label.jobs.content')}
                                      </Typography>
                                      <JsonTreeView json={row.content} capitalizeLabels={true} emptyLabel={t('jobs.info.empty')}/>
                                      </Box>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                      </TableBody>
                      </Table>
                  </Box>
              </div>
            </PerfectScrollbar>
          </CardContent>
          <CardActions className={classes.actions}>
            { (typeof tableData != "undefined" && tableData.count > 0) &&
                <CustomTablePagination
                  component="div"
                  count={tableData.total}
                  onPageChange={handlePaginationPageChange}
                  onRowsPerPageChange={handlePaginationSizeChange}
                  page={pagination.page}
                  rowsPerPage={pagination.size}
                  rowsPerPageOptions={Constants.DEFAULT_ROWS_SIZE_OPTIONS}
                />
            }
          </CardActions>
        </Card>
        <Box mt={3}>
          <Grid
              container
              spacing={4}
            >
              <BackButton />
          </Grid>
        </Box>
    </div>
  )
  }
export default withRouter(Batch);