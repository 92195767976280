
// Workaround for empty combo selection error on React UI
export const OPTION_EMPTY = '-';
export const OPTION_DEFAULT = 'content';
export const OPTION_CODE = 'code';

export const ORDER_ASC = 'asc';
export const ORDER_DESC = 'desc';

export const CLAUSE_AND = 'AND';
export const CLAUSE_OR = 'OR';
export const CLAUSE_NOT = 'NOT';

export const OBJECT_TYPE_IDS = {
  UNKNOWN:        0,
  PUBLICATION:    1,
  AGENCY:         2,
  COMPANY:        3,
  CONSTELLATION:  4,
}

export const DEFAULT_FILTER_VALUE = {
  value: '',
  beginValue: '',
  endValue: '',
}

export const FIELD_TYPES = {
  TEXT: 'text',
  NUMBER: 'number',
  DATE: 'date',
  SELECT: 'select',
  MULTI_SELECT: 'multiselect',
  BOOLEAN: 'boolean'
}

export const DEFAULT_ROWS_SIZE = 5;
export const DEFAULT_ROWS_SIZE_OPTIONS = [5, 10, 25];

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const DEFAULT_MAIN_PROFILE = 'Perito Criminal';
export const DEFAULT_ADMIN_PROFILE = 'Administrador';
export const DEFAULT_EXTERNAL_PROFILE = 'Externo';

export const APP_URL = 'https://deepvacuity.cic.unb.br';

export function getDefaultFilterValue(type) {

  let defaultValue = {
    ...DEFAULT_FILTER_VALUE
  }
  if(type === FIELD_TYPES.BOOLEAN) {
    defaultValue.value = false
  } else if(type === FIELD_TYPES.SELECT){
    defaultValue.value = 0
  } else if(type === FIELD_TYPES.MULTI_SELECT){
    defaultValue.value = []
  }
  return defaultValue;
}