import React, { useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import {
  Button, Grid
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { genericService } from 'services';
import { withSnackbar } from 'hooks/withSnackbar';

const DetailsNavigation = (props) => {

    const { initialItemId } = props

    let location = useLocation();
    let history = useHistory();

    const { t } = useTranslation();

    const [currentItem, setCurrentItem] = useState(null);
    const [currentItemPos, setCurrentItemPos] = useState(-1);
    const [pagination, setPagination] = useState({});
    const [filterValues, setFilterValues] = useState({});
    const [tableData, setTableData] = useState(null);

    React.useEffect(() => {
        let tempPagination = JSON.parse(localStorage.getItem("tempPagination"))
        localStorage.setItem("tempPagination", null)
        setPagination(tempPagination)
        let tempFilterValues = JSON.parse(localStorage.getItem("tempFilters"))
        localStorage.setItem("tempFilters", null)
        setFilterValues(tempFilterValues)

        if(tempFilterValues) {
            genericService
                .getPage(
                    props.entity,
                    tempPagination,
                    tempFilterValues
                )
                .then((data) => {
                    setTableData(data);
                    setCurrentItemPos(0)
                    setCurrentItem(data.data[0])
                    setPagination(tempPagination)

                    for(let i = 0;i<data.data.length;i++) {
                        let item = data.data[i]
                        if(item.id.toString() === initialItemId.toString()) {
                            setCurrentItem(item)
                            setCurrentItemPos(i)
                            break;
                        }
                    }
                })
                .catch((except) => {
                    props.showMessageError(except.message, except.detailedMessage)
                });
        } else {
            genericService
                .getById(props.entity, initialItemId)
                .then(async object => {
                    setCurrentItem(object)
                })
                .catch((except) => {
                    props.showMessageError(except.message, except.detailedMessage)
                });
        }

    }, []);

    React.useEffect(() => {
        props.onItemChanged(currentItem)
        updateLocation()
    }, [currentItem]);

    function updateLocation() {

        if(!currentItem || !currentItem.id) {
            return
        }
        let newLocation = `/${props.entity}/${currentItem.id}`
        //history.push(newLocation);
        history.replace(newLocation)
    }

    const handleLoadPreviousItem = () => {
        if(currentItemPos > 0) {
            let tempCurrentItemPos = currentItemPos-1
            setCurrentItem(tableData.data[tempCurrentItemPos])
            setCurrentItemPos(tempCurrentItemPos)
            return
        }
        if(pagination.page == 0) {
            return;
        }
        setCurrentItem(null)
        let tempPagination = {...pagination}
        tempPagination.page--;
        genericService
            .getPage(
                props.entity,
                tempPagination,
                filterValues
            )
            .then((data) => {
                setTableData(data);
                setCurrentItemPos(tempPagination.size-1)
                setCurrentItem(data.data[tempPagination.size-1])
                setPagination(tempPagination)
            })
            .catch((except) => {
                props.showMessageError(except.message, except.detailedMessage)
            });
    }

    const handleLoadNextItem = () => {
        if(currentItemPos < pagination.size-1) {
            let tempCurrentItemPos = currentItemPos+1
            setCurrentItem(tableData.data[tempCurrentItemPos])
            setCurrentItemPos(tempCurrentItemPos)
            return;
        }
        setCurrentItem(null)
        let tempPagination = {...pagination}
        tempPagination.page++;
        genericService
            .getPage(
                props.entity,
                tempPagination,
                filterValues
            )
            .then((data) => {
                setTableData(data);
                setCurrentItemPos(0)
                setCurrentItem(data.data[0])
                setPagination(tempPagination)
            })
            .catch((except) => {
                props.showMessageError(except.message, except.detailedMessage)
            });
    }

    function isButtonPreviousEnabled() {
        return (currentItemPos !== 0 || pagination.page !== 0)
    }

    function isButtonNextEnabled() {
        return true
    }

    return (
        <>
        {tableData &&
        <Grid container spacing={4} justifyContent="center">
            <Grid item xs={6} md={3}>
            <Button
                color="primary"
                variant="contained"
                onClick={() => {handleLoadPreviousItem()}}
                startIcon={<ArrowBackIos />}
                style={{width: '100%'}}
                disabled={!isButtonPreviousEnabled()}
            >
                {t('label.details.previous')}
            </Button>
            </Grid>

            <Grid item xs={6} md={3}>
            <Button
                color="primary"
                variant="contained"
                onClick={() => {handleLoadNextItem()}}
                endIcon={<ArrowForwardIos />}
                style={{width: '100%'}}
                disabled={!isButtonNextEnabled()}
            >
                {t('label.details.next')}
            </Button>
            </Grid>

        </Grid>
        }
        </>

    );
}

DetailsNavigation.propTypes = {
    initialItemId: PropTypes.string,
    entity: PropTypes.string,
    onItemChanged: PropTypes.func
};

export default withSnackbar(DetailsNavigation);