import { Box, Card, CardContent, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import { AccountTree as AccountTreeIcon, Delete, List } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import CustomLink from 'components/CustomLink';

const RenderConstellations = props => {
  const { classes, object, handleRemoveItem } = props
  const { t } = useTranslation();
  let mainList = []
  if (object && object.length){
    object.forEach((item)=>{
      if (item.objectType && item.objectType.description == 'Constellation'){
        mainList.push(item)
      }
    })
  }

  return (
    <Box display={(mainList.length)?"block":"none"} my={2}>
        <Card>
            <CardContent>
                 <Typography  className={classes.heading}>{t('label.constellation')} ({mainList.length})</Typography>

                {(mainList.length > 0)?
                <PerfectScrollbar>
                    <div className={classes.inner}>
                        <Table style={{minWidth:'100%'}}>
                        <TableHead>
                            <TableRow style={{minWidth:'100%'}}>
                                <TableCell>

                                </TableCell>
                                <TableCell>
                                    {t('label.id')}
                                </TableCell>
                                <TableCell>
                                    {t('label.name')}
                                </TableCell>
                                <TableCell>
                                    {t('label.actions')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(mainList.length > 0) &&
                                mainList.map(row => (
                                <TableRow className={classes.tableRow} hover key={row.target.id}>
                                    <TableCell style={{width:100}}>
                                      <Grid
                                        container
                                        spacing={0}
                                        direction="column"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <Grid item xs={12}>
                                          <AccountTreeIcon />
                                        </Grid>
                                        <Grid item xs={12}>
                                          <Typography>{t('label.constellation')}</Typography>
                                        </Grid>
                                      </Grid>
                                    </TableCell>
                                    <TableCell>{row.target.id}</TableCell>
                                    <TableCell>{row.target.name}</TableCell>
                                    <TableCell style={{width:150}}>
                                      <CustomLink to={ '/constellation/' + row.target.id}>
                                        <Tooltip title={t('button.detail')} aria-label={t('button.detail')}>
                                          <IconButton className={classes.topBarButtons}>
                                            <List fontSize="large" />
                                          </IconButton>
                                        </Tooltip>
                                      </CustomLink>
                                      {(handleRemoveItem)&&(
                                        <Tooltip title={t('button.remove')} aria-label={t('button.remove')}>
                                            <IconButton className={classes.topBarButtons} onClick={()=>handleRemoveItem(row.objectType.id, row.target.id)}>
                                              <Delete fontSize="large" />
                                            </IconButton>
                                        </Tooltip>
                                      )}
                                    </TableCell>
                                </TableRow>
                                ))}
                        </TableBody>
                        </Table>

                    </div>
                </PerfectScrollbar>
                :
                <Typography>{t('table.result.none')}</Typography>
                }
                </CardContent>
        </Card>
    </Box>
  );
};

RenderConstellations.propTypes = {
};

export default RenderConstellations;