import { CircularProgress, Grid } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HashRouter, Redirect, Route, Switch, useHistory } from 'react-router-dom';

import * as Constants from 'common/systemConstants';
import { usersService } from 'services';

import { RouteWithLayout } from '../components';
import { Main as MainLayout, Minimal as MinimalLayout } from '../layouts';
import { SettingsHistory as SettingsHistoryView, Settings as SettingsView, About as AboutView, Account as AccountView, Agencies as AgenciesView, Agency as AgencyView, CollectionForm, Collections as CollectionsView, Collection as CollectionView, Companies as CompaniesView, Company as CompanyView, Constellation as ConstellationView, Dashboard as DashboardView, Gazette, GazetteForm, Home as HomeView, JobsConfig, Login as LoginView, Logs as LogsView, ErrorPage as ErrorPageView, ObjectType, ObjectTypeForm, OtherServices as OtherServicesView, Publications as PublicationsView, Publication as PublicationView, Risks as RisksView, RiskType, RiskTypeForm, Search as SearchView, Sphere, SphereForm } from '../views';

const Routes = () => {
  const [keycloak, initialized] = useKeycloak();
  const { t } = useTranslation();
  const history = useHistory();

  if (!initialized) {
    return (
      <h3>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid item xs={3}>
            <CircularProgress />
          </Grid>
        </Grid>
      </h3>
    );
  }

  keycloak.onAuthLogout = () => {
    history.push("/#/session-expired")
    history.go()
  }

  const externalProfileRoutes = [
    {
      exact: true,
      path: "/dashboard",
      component: () => <DashboardView keycloak={keycloak}/>
    },
    {
      exact: true,
      path: "/other-services",
      title: t('menu.otherServices'),
      component: () => <OtherServicesView keycloak={keycloak}/>
    },
    {
      exact: true,
      path: "/account",
      component: AccountView
    }
  ]

  const mainProfileRoutes = [
    {
      component: RisksView,
      exact: true,
      title: "Análise de Risco de Fraude",
      path: "/risks"
    },
    {
      component: AgenciesView,
      exact: true,
      title: t('menu.agencies'),
      path: "/agencies"
    },
    {
      component: AgencyView,
      title: t('menu.agency'),
      path: "/agency/:id"
    },
    {
      component: CompaniesView,
      exact: true,
      title: t('menu.companies'),
      path: "/companies"
    },
    {
      component: CompanyView,
      exact: true,
      title: t('menu.company'),
      path: "/company/:id"
    },
    {
      component: ConstellationView,
      exact: true,
      title: t('menu.constellation'),
      path: [
        "/publication/:publicationId/constellation",
        "/constellation/:constellationId/"]
    },
    {
      component: PublicationsView,
      exact: true,
      title: t('menu.publications'),
      path: "/publications"
    },
    {
      component: PublicationsView,
      exact: true,
      title: t('menu.publications'),
      path: "/publications/riskType/:riskType/minRisk/:minRisk"
    },
    {
      component: PublicationsView,
      exact: true,
      title: t('menu.publication'),
      path: "/publication"
    },
    {
      component: PublicationView,
      exact: true,
      title: t('menu.publication'),
      path: "/publication/:id"
    },
    {
      component: SearchView,
      exact: true,
      title: "Pesquisa Amplificada",
      path: "/search"
    },
    {
      component: CollectionsView,
      exact: true,
      title: t('menu.collections'),
      path: "/collections"
    },
    {
      component: CollectionForm,
      exact: true,
      title: t('menu.collection'),
      path: "/collections/create"
    },
    {
      component: CollectionView,
      exact: true,
      title: t('menu.collection'),
      path: "/collection/:id"
    },
    {
      component: CollectionForm,
      exact: true,
      title: t('menu.collection'),
      path: "/collection/:id/edit"
    }
  ]

  const adminProfileRoutes = [
    {
      component: LogsView,
      exact: true,
      title: t('menu.logs'),
      path: "/logs"
    },
    {
      component: ObjectTypeForm,
      exact: true,
      title: t('menu.objectTypes'),
      path: "/objectType/create"
    },
    {
      component: ObjectTypeForm,
      exact: true,
      title: t('menu.objectTypes'),
      path: "/objectType/:id/edit"
    },
    {
      component: ObjectType,
      exact: true,
      title: t('menu.objectTypes'),
      path: "/objectTypes"
    },
    {
      component: RiskTypeForm,
      exact: true,
      title: t('menu.riskTypes'),
      path: "/riskType/create"
    },
    {
      component: RiskTypeForm,
      exact: true,
      title: t('menu.riskTypes'),
      path: "/riskType/:id/edit"
    },
    {
      component: RiskType,
      exact: true,
      title: t('menu.riskTypes'),
      path: "/riskTypes"
    },
    {
      component: SphereForm,
      exact: true,
      title: t('menu.spheres'),
      path: "/sphere/create"
    },
    {
      component: SphereForm,
      exact: true,
      title: t('menu.spheres'),
      path: "/sphere/:id/edit"
    },
    {
      component: Sphere,
      exact: true,
      title: t('menu.spheres'),
      path: "/spheres"
    },
    {
      component: GazetteForm,
      exact: true,
      title: t('menu.gazettes'),
      path: "/gazette/create"
    },
    {
      component: GazetteForm,
      exact: true,
      title: t('menu.gazettes'),
      path: "/gazette/:id/edit"
    },
    {
      component: Gazette,
      exact: true,
      title: t('menu.gazettes'),
      path: "/gazettes"
    },
    {
      component: JobsConfig,
      title: t('menu.jobs'),
      path: "/jobs"
    },
    {
      component: SettingsView,
      exact: true,
      title: t('menu.configs'),
      path: "/settings"
    },
    {
      component: SettingsHistoryView,
      exact: true,
      title: t('menu.configs.history'),
      path: "/settings/history"
    },
  ]

  return (
    <HashRouter>
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <RouteWithLayout
          component={LoginView}
          exact
          layout={MinimalLayout}
          path="/login"
        />
        <RouteWithLayout
          component={ErrorPageView}
          layout={MinimalLayout}
          exact
          path={["/logout", "/session-expired"]}
          keycloak={keycloak}
        />
        <Route
          exact
          path="/home"
          render={matchProps => <HomeView/>}
        />

        {keycloak && keycloak.authenticated
          && externalProfileRoutes.map((item) =>
          <RouteWithLayout
            key={Array.isArray(item.path) ? item.path[0] : item.path}
            component={item.component}
            exact={!!item.exact}
            path={item.path}
            title={item.title}
            layout={MainLayout}
            keycloak={keycloak}
          />)
        }

        {keycloak && keycloak.authenticated
          && usersService.getRole(keycloak).includes(Constants.DEFAULT_MAIN_PROFILE)
          && mainProfileRoutes.map((item) =>
          <RouteWithLayout
            key={Array.isArray(item.path) ? item.path[0] : item.path}
            component={item.component}
            exact={!!item.exact}
            path={item.path}
            title={item.title}
            layout={MainLayout}
            keycloak={keycloak}
          />)
        }

        {keycloak && keycloak.authenticated
         && usersService.getRole(keycloak).includes(Constants.DEFAULT_ADMIN_PROFILE)
         && adminProfileRoutes.map((item) =>
         <RouteWithLayout
           key={Array.isArray(item.path) ? item.path[0] : item.path}
           component={item.component}
           exact={!!item.exact}
           path={item.path}
           title={item.title}
           layout={MainLayout}
           keycloak={keycloak}
         />)
        }

        <RouteWithLayout
           component={AboutView}
           exact
           path="/about"
           title={t('menu.about')}
           layout={MainLayout}
           keycloak={keycloak}
         />

        <RouteWithLayout
          component={() => <ErrorPageView/>}
          layout={MinimalLayout}
          path="*"
          keycloak={keycloak}
        />

{/*

        <RouteWithLayout
          component={ErrorPageView}
          layout={MinimalLayout}
          path="/not-found"
        />

        <Route path="*">
          <Redirect to="/not-found" />
        </Route>
  */}

      </Switch>
    </HashRouter>
  );
};

export default Routes;
