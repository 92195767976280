import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { withKeycloak } from '@react-keycloak/web';

import { usersService } from 'services';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    paddingTop: 150,
    textAlign: 'center'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  },
  warning: {
    marginTop: theme.spacing(4)
  }
}));

const ErrorPage = (props) => {
  const { keycloak, match } = props
  const classes = useStyles();
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {

    if(match.url === "/logout") {
      resetCredentials();
      setLoading(true)
      setTimeout(() => {
        setRedirect(true)
      }, 500);
      return
    }

    if(match.url === "/session-expired") {
      setErrorTitle(t("session.expired.title"))
      setErrorMessage(t("session.expired.message"))
    } else {
      setErrorTitle(t("page.not.found.title"))
      setErrorMessage(t("page.not.found.message"))
    }

    if(document.hasFocus()){
      doReload()
      return
    }
    window.addEventListener('focus', onFocus);
    document.addEventListener('focus', onFocus )
    return () => {
      window.removeEventListener('focus', onFocus);
      document.removeEventListener('focus', onFocus )
    };
  }, []);

  function onFocus() {
    doReload()
  }

  function doReload() {
    setTimeout(() => {
      setLoading(true)
      setTimeout(() => {
        setRedirect(true)
      }, 500);
    }, 10000);
  }

  function resetCredentials() {
    if(keycloak && keycloak.authenticated) {
      keycloak.logout();
    }
    usersService.logout();
  }

  {/***************************************************/}

  if(redirect) {
    return (
      <Redirect to={"/"} />
    )
  }

  if(loading) {
    return (
      <h3>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid item xs={3}>
            <CircularProgress />
          </Grid>
        </Grid>
      </h3>
    )
  }

  return (
  <div className={classes.root}>
    <Grid
      container
      justifyContent="center"
      spacing={4}
    >
      <Grid
        item
        lg={8}
        xs={12}
      >
        <div className={classes.content}>
          <Typography variant="h1">
            {errorTitle}
          </Typography>
          <Typography className={classes.warning} variant="h4">
            {errorMessage}
          </Typography>
          <Typography className={classes.warning} variant="h3">
            {t("page.redirecting")}
          </Typography>
          <Typography variant="h4">
            <Trans
              i18nKey="page.click.here"
              components={{ linkTo: <Link to="/"/> }}
            />
          </Typography>
        </div>
      </Grid>
    </Grid>
  </div>
  )

};

export default withRouter(withKeycloak(ErrorPage));