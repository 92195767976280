
import React from 'react';

import * as Constants from 'common/systemConstants';
import { LabelCPFCNPJ, EntityList } from 'components';
import { Checkbox } from '@material-ui/core';

const Companies = (props) => {
  return (
    <EntityList
      objectTypeId={Constants.OBJECT_TYPE_IDS.COMPANY}
      entity={"company"}
      exportCsv={false}
      sort={{
        column : "id",
        order : Constants.ORDER_DESC
      }}
      columns={[
          {
            name: "name",
            ordered: true
          },
          {
            name: "code",
            ordered: true,
            component: (row) => ( <LabelCPFCNPJ value={row.code} /> )
          },
          {
            name: "head_office",
            ordered: true,
            component: (row) => ( <Checkbox readOnly={true} checked={(row.head_office=='1') ? true : false}/> )
          }
        ]}
    />
  )
}

Companies.propTypes = {
};

export default Companies;