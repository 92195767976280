import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Warning } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  icon: {
    textAlign: "right"
  }
}));

const NoResultsFound = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if(!props.show) {
    return (<></>);
  }
  return (
    <Grid className={classes.root} container justifyContent="center" alignItems="center" spacing={5}>
      <Grid item xs={2} classes={{root: classes.icon}} >
        <Warning fontSize={"large"}/>
      </Grid>
      <Grid item xs={4} >
        <Typography align="left" variant="h6">{t('table.result.none')}</Typography>
      </Grid>
    </Grid>
  );
};

NoResultsFound.propTypes = {
  show: PropTypes.bool
};

export default NoResultsFound;