import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// import moment from 'moment/min/moment-with-locales';
import * as moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles, withStyles } from '@material-ui/styles';
import GavelIcon from '@material-ui/icons/Gavel';
import FlagIcon from '@material-ui/icons/Flag';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListIcon from '@material-ui/icons/List';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import green from '@material-ui/core/colors/green';
import brown from '@material-ui/core/colors/brown';
import blue from '@material-ui/core/colors/blueGrey';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ReevaluateIcon from '@material-ui/icons/Replay';

import {
  Divider,
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Tooltip,
  Button,
  Hidden,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import CustomTablePagination from 'components/CustomTablePagination';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

 const StyledMenuItem = withStyles((theme) => ({
 }))(MenuItem);

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const DataTable = props => {
  const { className, users, ...rest } = props;

  const classes = useStyles();

  const [selectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  function getStatusColor(status){
    switch (status) {
      case 'risk_confirmed':
        return 'red';
      case 'non_risk_confirmed':
        return 'green';
      case 'risk_probable':
        return 'PeachPuff';
      case 'non_risk_probable':
      default:
        return 'paleGreen';
    }
  }

  function getPhaseDescription(phase){
    switch (phase) {
      case 'risk_confirmed':
        return 'Confirmado'
      case 'risk_probable':
        return 'Provável'
      case 'non_risk_probable':
        return 'Provável Inexistência'
      case 'non_risk_confirmed':
        return 'Inexistência Confirmada'
    }
  }

  const [anchorElWaiting, setAnchorElWaiting] = React.useState(null);
  const [anchorElActive, setAnchorElActive] = React.useState(null);
  const [anchorElClosed, setAnchorElClosed] = React.useState(null);

  const handleClickWaiting = (event) => {
    setAnchorElWaiting(event.currentTarget);
  };

  const handleClickActive = (event) => {
    setAnchorElActive(event.currentTarget);
  };

  const handleClickClosed = (event) => {
    setAnchorElClosed(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElWaiting(null);
    setAnchorElActive(null);
    setAnchorElClosed(null);
  };

  var listHandleClicks = {
    'risk_confirmed' : handleClickWaiting,
    'risk_probable' : handleClickActive,
    'non_risk_probable' : handleClickActive,
    'non_risk_confirmed' : handleClickWaiting
  };

  return (

    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
          <StyledMenu
            id='submenu-waiting'
            anchorEl={anchorElWaiting}
            keepMounted
            open={Boolean(anchorElWaiting)}
            onClose={handleClose}
          >
            <StyledMenuItem>
              <ListItemIcon>
                <ReevaluateIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Reavaliar" />
            </StyledMenuItem>

            <Divider />

            <StyledMenuItem>
              <ListItemIcon>
                <AssignmentTurnedInIcon fontSize="small" style={{ color: brown[500] }}/>
              </ListItemIcon>
              <ListItemText primary="Contratos" />
            </StyledMenuItem>

            <StyledMenuItem>
              <ListItemIcon>
                <QueuePlayNextIcon fontSize="small" style={{ color: brown[500] }}/>
              </ListItemIcon>
              <ListItemText primary="Publicações" />
            </StyledMenuItem>

            <StyledMenuItem>
              <ListItemIcon>
                <ListIcon fontSize="small" style={{ color: blue[500] }}/>
              </ListItemIcon>
              <ListItemText primary="Detalhar" />
            </StyledMenuItem>

          </StyledMenu>

          <StyledMenu
            id='submenu-active'
            anchorEl={anchorElActive}
            keepMounted
            open={Boolean(anchorElActive)}
            onClose={handleClose}
          >
            <StyledMenuItem>
              <ListItemIcon>
                <ReevaluateIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Reavaliar" />
            </StyledMenuItem>

            <StyledMenuItem className="finish">
              <ListItemIcon>
                <FlagIcon fontSize="small" color="error"/>
              </ListItemIcon>
              <ListItemText primary="Confirmar Risco" />
            </StyledMenuItem>

            <StyledMenuItem className="finish">
              <ListItemIcon>
                <FlagIcon fontSize="small" style={{ color: green[700] }}/>
              </ListItemIcon>
              <ListItemText primary="Risco Inexistente" />
            </StyledMenuItem>

            <Divider />

            <StyledMenuItem>
              <ListItemIcon>
                <AssignmentTurnedInIcon fontSize="small" style={{ color: brown[500] }}/>
              </ListItemIcon>
              <ListItemText primary="Contratos" />
            </StyledMenuItem>

            <StyledMenuItem>
              <ListItemIcon>
                <QueuePlayNextIcon fontSize="small" style={{ color: brown[500] }}/>
              </ListItemIcon>
              <ListItemText primary="Publicações" />
            </StyledMenuItem>

            <StyledMenuItem className="detail">
              <ListItemIcon>
                <ListIcon fontSize="small" style={{ color: blue[500] }}/>
              </ListItemIcon>
              <ListItemText primary="Detalhar" />
            </StyledMenuItem>

          </StyledMenu>

          <StyledMenu
            id='submenu-closed'
            anchorEl={anchorElClosed}
            keepMounted
            open={Boolean(anchorElClosed)}
            onClose={handleClose}
          >
            <StyledMenuItem>
              <ListItemIcon>
                <ReevaluateIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Reavaliar" />
            </StyledMenuItem>

            <StyledMenuItem className="finish">
              <ListItemIcon>
                <FlagIcon fontSize="small" color="error"/>
              </ListItemIcon>
              <ListItemText primary="Confirmar Risco" />
            </StyledMenuItem>

            <StyledMenuItem className="finish">
              <ListItemIcon>
                <FlagIcon fontSize="small" style={{ color: green[700] }}/>
              </ListItemIcon>
              <ListItemText primary="Risco Inexistente" />
            </StyledMenuItem>

            <Divider />

            <StyledMenuItem>
              <ListItemIcon>
                <AssignmentTurnedInIcon fontSize="small" style={{ color: brown[500] }}/>
              </ListItemIcon>
              <ListItemText primary="Contratos" />
            </StyledMenuItem>

            <StyledMenuItem>
              <ListItemIcon>
                <QueuePlayNextIcon fontSize="small" style={{ color: brown[500] }}/>
              </ListItemIcon>
              <ListItemText primary="Publicações" />
            </StyledMenuItem>

            <StyledMenuItem className="detail">
              <ListItemIcon>
                <ListIcon fontSize="small" style={{ color: blue[500] }}/>
              </ListItemIcon>
              <ListItemText primary="Detalhar" />
            </StyledMenuItem>

          </StyledMenu>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <Hidden smDown><TableCell>Tipo</TableCell></Hidden>
                  <Hidden smDown><TableCell>Situação</TableCell></Hidden>
                  <Hidden smDown><TableCell>Órgão</TableCell></Hidden>
                  <Hidden smDown><TableCell>Empresa</TableCell></Hidden>
                  <TableCell>Objeto</TableCell>
                  <Hidden mdDown><TableCell>Valor</TableCell></Hidden>
                  <Hidden mdDown><TableCell>Data</TableCell></Hidden>
                  <TableCell align="center">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.slice(0, rowsPerPage).map(user => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={user.id}
                    selected={selectedUsers.indexOf(user.id) !== -1}
                  >
                    <TableCell style={{borderLeftColor: getStatusColor(user.status), borderLeftWidth: '1em', borderLeftStyle: 'solid' }}></TableCell>
                    <Hidden smDown>
                      <TableCell>
                        <Grid item md={12} align="center" justifyContent="center">
                            <GavelIcon />
                            <Typography variant="h6" >
                              {user.type}
                            </Typography>
                        </Grid>
                      </TableCell>
                    </Hidden>
                    <Hidden smDown>
                      <TableCell>
                        <Grid ><Typography variant="body1" inline display="inline">{getPhaseDescription(user.status)}</Typography></Grid>
                        <Typography variant="subtitle2" fontSize="small" inline display="inline">{ user.responsible && "(" + user.responsible + ")"}</Typography>
                      </TableCell>
                    </Hidden>
                    <Hidden smDown>
                      <TableCell>
                          <Grid ><Typography variant="body1" inline display="inline">{user.organization.name}</Typography></Grid>
                          <Typography variant="subtitle2" fontSize="small" inline display="inline">{user.organization.governPower} / {user.organization.coverage}</Typography>
                      </TableCell>
                    </Hidden>
                    <TableCell>
                      <Grid ><Typography variant="body1" inline display="inline">{user.company.name}</Typography></Grid>
                      <Typography variant="subtitle2" fontSize="small" inline display="inline">CNPJ: {user.company.cnpj}</Typography>
                    </TableCell>
                    <Hidden smDown>
                    <TableCell>
                      <Grid ><Typography variant="body1" inline display="inline">{user.object.description}</Typography></Grid>
                      <Typography variant="subtitle2" fontSize="small" inline display="inline">Processo: {user.object.nr}</Typography>
                    </TableCell>
                    </Hidden>
                    <Hidden mdDown>
                      <TableCell>{user.object.value}</TableCell>

                      <TableCell>
                        {moment(user.createdAt).format('DD/MM/YYYY')}
                      </TableCell>
                    </Hidden>
                    <TableCell align="center">
                      <Tooltip title="Menu" aria-label="menu">
                        <Button className={classes.MenuButton} onClick={listHandleClicks[user.status]}>
                            <MoreVertIcon />
                        </Button>
                      </Tooltip>
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <CustomTablePagination
          component="div"
          count={users.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

DataTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default DataTable;
