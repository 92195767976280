import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Switch, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import * as Constants from 'common/systemConstants';

const useStyles = makeStyles(() => ({
  fieldBoolean: {
    paddingLeft: '0 !important'
  },
}))

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'
const DEFAULT_MIN_TEXT_VALUE = 3;
const DEFAULT_MAX_TEXT_VALUE = 100;
const DEFAULT_MIN_DATE_VALUE = '1900-01-01'
const DEFAULT_MAX_DATE_VALUE = '2200-01-01'
const DEFAULT_TODAY_DATE_VALUE = moment().format(DEFAULT_DATE_FORMAT)

const FilterSelect = (props) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const [filterType, setFilterType] = useState((props.defaultFilterName && props.filters)
    ? (props.filters[props.defaultFilterName] ? props.filters[props.defaultFilterName].type : '')
    : '');
  const [filterCondition, setFilterCondition] = useState(props.defaultFilterCondition || Constants.CLAUSE_AND);
  const [filterName, setFilterName] = useState(props.defaultFilterName || Constants.OPTION_DEFAULT);
  const [filterValue, setFilterValue] = useState(props.defaultFilterValue || Constants.DEFAULT_FILTER_VALUE);
  const [fieldError, setFieldError] = useState(false);
  const [fieldHelperText, setFieldHelperText] = useState('');
  const dateBeginRef = useRef();
  const dateEndRef = useRef();

  const updateFilter = (name, value, valid, condition) => {
    props.updateFilter(props.index, name, value, valid, condition)
  }

  const validateField = (filterValue) => {
    setFieldError(false)
    setFieldHelperText('')

    try {
      /**
       * Filter empty validation
       */
      if(fieldHasRange(filterName)) {
        if(filterValue.beginValue === '' && filterValue.endValue === '') {
          throw t('validation.no.value')
        }
      } else if(filterType === Constants.FIELD_TYPES.SELECT) {
        if(filterValue.value.length == 0) {
          throw t('validation.no.value')
        }
      } else if(filterType === Constants.FIELD_TYPES.DATE) {
        if(filterValue.beginValue === '' && filterValue.endValue === '') {
          throw t('validation.invalid.date')
        }
      } else if(filterValue.value === '') {
        throw t('validation.no.value')
      }

      /**
       * Min Max validation
       */
      let minMax = getFieldMinMaxValues()
      if(filterType === Constants.FIELD_TYPES.TEXT) {
        let isEmpty = !filterValue.value || !filterValue.value.length
        if(minMax.min && (isEmpty || filterValue.value.length < minMax.min)) {
          throw t('validation.min.text', {value: minMax.min})
        }
        if(filterValue.value.length > minMax.max) {
          throw t('validation.max.text', {value: minMax.max})
        }
      } else if (filterType === Constants.FIELD_TYPES.NUMBER) {
        if(minMax.min || minMax.min === 0){
          if(fieldHasRange(filterName)) {
            if(filterValue.beginValue !== ''
                  && filterValue.beginValue < minMax.min) {
              throw t('validation.min.number', {value: minMax.min})
            }
            if(filterValue.endValue !== ''
                  && filterValue.endValue < minMax.min) {
              throw t('validation.min.number', {value: minMax.min})
            }
          } else {
            if(filterValue.value !== ''
                  && filterValue.value < minMax.min) {
              throw t('validation.min.number', {value: minMax.min})
            }
          }
        }

        if(minMax.max || minMax.max === 0){
          if(fieldHasRange(filterName)) {
            if(filterValue.beginValue !== ''
                  && filterValue.beginValue > minMax.max) {
              throw t('validation.max.number', {value: minMax.max})
            }
            if(filterValue.endValue !== ''
                  && filterValue.endValue > minMax.max) {
              throw t('validation.max.number', {value: minMax.max})
            }
          } else {
            if(filterValue.value !== ''
                  && filterValue.value > minMax.max) {
              throw t('validation.max.number', {value: minMax.max})
            }
          }
        }

        if(fieldHasRange(filterName)) {
          if((filterValue.beginValue !== '' && filterValue.endValue !== '')
                && filterValue.beginValue > filterValue.endValue) {
            throw t('validation.begin.greater.than.end')
          }
        }
      } else if (filterType === Constants.FIELD_TYPES.DATE) {
        if(filterValue.beginValue !== '' && filterValue.endValue !== '') {
          let beginDate = moment(filterValue.beginValue, DEFAULT_DATE_FORMAT)
          let endDate = moment(filterValue.endValue, DEFAULT_DATE_FORMAT)
          if(beginDate.isAfter(endDate)) {
            throw t('validation.begin.greater.than.end')
          }
        }
      }
    } catch (validationError) {
      setFieldError(true)
      setFieldHelperText(validationError)
      return false;
    }
    return true;
  }

  const handleFilterConditionChange = event => {
    const value = event.target.value
    setFilterCondition(value)
    updateFilter(filterName, filterValue, true, value)
  };

  const handleValueChange = event => {
    let tempValue = {...filterValue}
    if(filterType === Constants.FIELD_TYPES.BOOLEAN) {
      tempValue.value = event.target.checked
      tempValue.beginValue = ''
      tempValue.endValue = ''
    } else {
      tempValue[event.target.name] = event.target.value
      let valueField = event.target.name
      if(valueField === 'value') {
        tempValue.beginValue = ''
        tempValue.endValue = ''
      } else if(valueField === 'beginValue' || valueField === 'endValue') {
        tempValue.value = ''
      }
    }
    setFilterValue(tempValue)
    updateFilter(filterName, tempValue, validateField(tempValue), filterCondition)
  };

  const handleFilterChange = event => {
    let name = event?.target?.value
    if (!name) {
      name = Constants.OPTION_DEFAULT
    }
    let type = name !== Constants.OPTION_EMPTY ? props.filters[name].type : ''
    setFilterName(name)
    setFilterType(type)
    setFilterCondition(filterCondition)
    let defaultValue = Constants.getDefaultFilterValue(type)
    setFilterValue(defaultValue)
    updateFilter(name, defaultValue, type === Constants.FIELD_TYPES.BOOLEAN, filterCondition)
    setFieldError(false)
    setFieldHelperText('')
  };

  const getSelectOptions = (selectFieldName) => {
    let selectValues = props.filters[selectFieldName].values
    if(!selectValues) {
      return []
    }
    return selectValues;
  }

  const fieldHasRange = () => {
    return props.filters[filterName].range
  }

  const getFieldMinMaxValues = () => {
    let min = props.filters[filterName].min
    let max = props.filters[filterName].max

    if(filterType === Constants.FIELD_TYPES.TEXT) {
      if((!min && min !== 0) || min < 0) {
        min = DEFAULT_MIN_TEXT_VALUE
      }
      if(!max) {
        max = DEFAULT_MAX_TEXT_VALUE
      }
    } else if(filterType === Constants.FIELD_TYPES.DATE) {
      if(!min) {
        min = DEFAULT_MIN_DATE_VALUE
      }
      if (max === 'today') {
        max = DEFAULT_TODAY_DATE_VALUE
      } else if(!max) {
        max = DEFAULT_MAX_DATE_VALUE
      }

      let minDate = moment(min, DEFAULT_DATE_FORMAT)
      let maxDate = moment(max, DEFAULT_DATE_FORMAT)

      if(minDate.isAfter(maxDate)) {
        let temp = min
        min = max
        max = temp
      }
      return {min: min, max: max}
    } else if(filterType !== Constants.FIELD_TYPES.NUMBER) {
      min = 0
      max = 0
    }
    if(min > max) {
      let temp = min
      min = max
      max = temp
    }
    return {
      min: min,
      max: max
    }
  }

  return (
    <Grid
      container
      spacing={2}
    >
      {
        props.index === String(props.firstFilterIndex) ?
          <Grid
            item
            md={1}
            xs={12}
          />
          :
          <Grid
            align="center"
            item
            md={1}
            xs={12}
          >
            <FormControl
              error={fieldError}
              fullWidth
            >
              <InputLabel>{t('label.filters.condition')}</InputLabel>
              <Select
                fullWidth
                name="clauseCondition"
                onChange={handleFilterConditionChange}
                value={filterCondition}
                variant="standard"
              >
                <MenuItem value={Constants.CLAUSE_AND}>{t('label.filters.and.condition')}</MenuItem>
                <MenuItem value={Constants.CLAUSE_OR}>{t('label.filters.or.condition')}</MenuItem>
                <MenuItem value={Constants.CLAUSE_NOT}>{t('label.filters.not.condition')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
      }
      <Grid
        align="left"
        item
        md={3}
        xs={12}
      >
        <FormControl
          error={fieldError}
          fullWidth
        >
          <InputLabel>{t('label.field')}</InputLabel>
          <Select
            fullWidth
            onChange={handleFilterChange}
            value={filterName}
            variant="standard"
          >
            {
              Object.keys(props.filters).map(function(filterName, index) {
                let translationLabel = props.filters[filterName].label
                if(!translationLabel) {
                  translationLabel = (props.translationPrefix || '') + filterName
                }
                return <MenuItem
                  key={index}
                  value={filterName}
                >
                  {t(translationLabel)}
                </MenuItem>
              })
            }
          </Select>
          <FormHelperText>{fieldHelperText}</FormHelperText>
        </FormControl>
      </Grid>

      {(() => {
        switch (filterType) {
          case Constants.FIELD_TYPES.TEXT:
            return (
              <Grid
                align="left"
                item
                md={4}
                xs={12}
              >
                <TextField
                  error={fieldError}
                  fullWidth
                  label={t('label.value')}
                  name={'value'}
                  onChange={handleValueChange}
                  type={'text'}
                  value={filterValue.value}
                  variant="standard"
                />
              </Grid>
            );
          case Constants.FIELD_TYPES.NUMBER:
            return ( !fieldHasRange() ?
              <Grid
                align="left"
                item
                md={4}
                xs={12}
              >
                <FormControl
                  error={fieldError}
                  fullWidth
                >
                  <TextField
                    error={fieldError}
                    fullWidth
                    label={t('label.value')}
                    name={'value'}
                    onChange={handleValueChange}
                    type={'number'}
                    value={filterValue.value}
                    variant="standard"
                  />
                </FormControl>
              </Grid>
              :
              <>
                <Grid
                  align="left"
                  item
                  md={4}
                  xs={12}
                >
                  <FormControl
                    error={fieldError}
                    fullWidth
                  >
                    <TextField
                      error={fieldError}
                      fullWidth
                      label={t('label.begin')}
                      name={'beginValue'}
                      onChange={handleValueChange}
                      type={'number'}
                      value={filterValue.beginValue}
                      variant="standard"
                    />
                  </FormControl>
                </Grid>
                <Grid
                  align="left"
                  item
                  md={4}
                  xs={12}
                >
                  <FormControl
                    error={fieldError}
                    fullWidth
                  >
                    <TextField
                      error={fieldError}
                      fullWidth
                      label={t('label.end')}
                      name={'endValue'}
                      onChange={handleValueChange}
                      type={'number'}
                      value={filterValue.endValue}
                      variant="standard"
                    />
                  </FormControl>
                </Grid>
              </>
            );
          case Constants.FIELD_TYPES.DATE: {
            let minMax = getFieldMinMaxValues();
            return (
              <>
                <Grid
                  align="left"
                  item
                  md={4}
                  xs={12}
                >
                  <FormControl
                    error={fieldError}
                    fullWidth
                  >
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={fieldError}
                      fullWidth
                      id="date"
                      inputProps={{
                        min: minMax.min,
                        max: minMax.max,
                        onKeyDown: () => {
                          //event.preventDefault();
                        },
                      }}
                      inputRef={dateBeginRef}
                      label={t('label.begin')}
                      name={'beginValue'}
                      onBlur={() => {
                        if(!dateBeginRef.current.value) {
                          dateBeginRef.current.value = null
                        }
                      }}
                      onChange={handleValueChange}
                      type={'date'}
                      value={filterValue.beginValue}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  align="left"
                  item
                  md={4}
                  xs={12}
                >
                  <FormControl
                    error={fieldError}
                    fullWidth
                  >
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={fieldError}
                      fullWidth
                      id="date"
                      inputProps={{
                        min: minMax.min,
                        max: minMax.max,
                        onKeyDown: () => {
                          //event.preventDefault();
                        },
                      }}
                      inputRef={dateEndRef}
                      label={t('label.end')}
                      name={'endValue'}
                      onBlur={() => {
                        if(!dateEndRef.current.value) {
                          dateEndRef.current.value = null
                        }
                      }}
                      onChange={handleValueChange}
                      type="date"
                      value={filterValue.endValue}
                    />
                  </FormControl>
                </Grid>
              </>
            );
          }
          case Constants.FIELD_TYPES.SELECT:
            return (
              <Grid
                align="left"
                item
                md={4}
                xs={12}
              >
                <FormControl
                  error={fieldError}
                  fullWidth
                >
                  <InputLabel>{t('label.value')}</InputLabel>
                  <Select
                    fullWidth
                    name={'value'}
                    onChange={handleValueChange}
                    value={filterValue.value}
                    variant="standard"
                  >
                    {
                      getSelectOptions(filterName).map((option, index) => {
                        let translationLabel = option.label
                        if (!filterName.includes('risk')) {
                          translationLabel = (props.translationPrefix || '') + filterName + '.' + option.label
                        }
                        return <MenuItem
                          key={index}
                          value={option.value}
                        >
                          {t(translationLabel)}
                        </MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
            );

          case Constants.FIELD_TYPES.BOOLEAN:
            return (
              <Grid
                align="left"
                className={classes.fieldBoolean}
                item
                md={4}
                xs={12}
              >
                <Switch
                  checked={filterValue.value}
                  color="primary"
                  name={'value'}
                  onChange={handleValueChange}
                />
              </Grid>
            );
          default:
            return <></>;
        }
      })()}

    </Grid>
  )
}

FilterSelect.propTypes = {
  defaultFilterCondition: PropTypes.string,
  defaultFilterName: PropTypes.string,
  defaultFilterValue: PropTypes.object,
  filters: PropTypes.object,
  firstFilterIndex: PropTypes.number,
  index: PropTypes.string,
  translationPrefix: PropTypes.string,
  updateFilter: PropTypes.func
};

export default FilterSelect;