
import React, { useState } from 'react';

const LabelNumberSeparator = ({ value, separator }) => {
  
    function format(value, separator) {
      if(!value)
        return ""
      if(!separator)
        separator = '.'
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    }
  
    return (
        <div> 
          {format(value, separator)}   
        </div>
    );
  }
  
export default LabelNumberSeparator;