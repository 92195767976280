import { Box, Button, Card, CardActions, CardContent, Collapse, Grid, Hidden, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from '@material-ui/core';
import { ArrowBackIos, CheckCircleOutline as CheckCircleOutlineIcon, FilterList as FilterListIcon, ListAlt, List as ListIcon, Search } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import config from 'config';
import * as Constants from 'common/systemConstants';
import { NoResultsFound } from 'components';
import BackButton from 'components/BackButton';
import CustomTablePagination from 'components/CustomTablePagination';
import DynamicFilters from 'components/Filter';
import LoadingSpinner from 'components/LoadingSpinner';
import CollectionsContext from 'components/CollectionsContext';
import { withSnackbar } from 'hooks/withSnackbar';
import { collectionsService, genericService } from 'services';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  cardFilter: {
    '&:last-child': {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0
    }
  },
  tableContent: {
    marginTop: theme.spacing(2)
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const EntityList = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState();
  const [tableData, setTableData] = useState();
  const [collectionsData, setCollectionsData] = useState();
  const [filtersData, setFiltersData] = useState();
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [searchButtonDisabled, setSearchButtonDisabled] = useState(false);
  const [filters, setFilters] = useState([]);
  const [pagination, setPagination] = useState({
    size: Constants.DEFAULT_ROWS_SIZE,
    page: 0,
    sort: props.sort && props.sort.column,
    order: props.sort && props.sort.order
  });
  const prefix = props.entity.replaceAll('/', '.')

  React.useEffect(() => {
    updateCollections();
    updateFilters();
    updateTable (pagination)
  }, []);

  const updateFilters = () => {
    genericService.getFilters(props.entity)
      .then((data) => {
        setFiltersData(data)
      })
      .catch((except) => {
        props.showMessageError(except.message, except.detailedMessage)
      });
  }

  const updateCollections = () => {
    if(props.disableCollections)
      return
    collectionsService.getPage(
      {
        size: 100,
        page: 0,
        sort: 'name',
        order: 'asc'
      },
      false,
      'false'
    )
      .then((data) => {
        setCollectionsData(data)
      })
      .catch((except) => {
        props.showMessageError(except.message, except.detailedMessage)
      });
  }

  function updateTable (pagination) {
    setFiltersExpanded(false)
    setLoading(true)
    setTableData()
    genericService.getPage(
      props.entity,
      pagination,
      filters
    ).then((data) => {
      setTableData(data);
      setLoading(false)
    }).catch((except) => {
      props.showMessageError(except.message, except.detailedMessage)
    });
  }

  function handleCsv () {
    let url = genericService.getPageUrl(
      props.entity,
      pagination,
      filters,
      true
    )
    window.open(url, '_blank');
  }

  const handleClickOnCollection = () => {
    updateCollections()
  };

  const handleUpdateFilters = (updatedFilters) => {
    setFilters(updatedFilters.filters)
    setSearchButtonDisabled(!updatedFilters.valid)
  };

  const handlePaginationSizeChange = event => {
    const tempPagination = {
      ...pagination,
      ['size']: event.target.value
    }
    setPagination(tempPagination);
    updateTable(tempPagination);
  };

  const handlePaginationPageChange = (event, page) => {
    const tempPagination = {
      ...pagination,
      ['page']: page
    }
    setPagination(tempPagination);
    updateTable(tempPagination);
  };

  const handlePaginationSortChange = (property) => () => {
    let order = Constants.ORDER_ASC;
    if(property === pagination.sort
      && pagination.order == Constants.ORDER_ASC) {
      order = Constants.ORDER_DESC;
    }
    const tempPagination = {
      ...pagination,
      ['sort']: property,
      ['order']: order
    }
    setPagination(tempPagination);
    updateTable(tempPagination);
  };

  const handleToggleFilters = () => {
    setFiltersExpanded(filtersExpanded ? false : true);
  };

  const handleOpenRowInNewTab = (itemId) => {
    localStorage.setItem('tempPagination', JSON.stringify(pagination))
    localStorage.setItem('tempFilters', JSON.stringify(filters))
    window.open(`/#/${props.entity}/${itemId}`, '_blank')
  }

  const isFiltersDataEmpty = () => {
    return (!filtersData || JSON.stringify(filtersData) === JSON.stringify({}))
  }

  return (
    <div className={classes.root}>
      <div>
        <Card>
          <CardContent>
            <Grid
              container
              spacing={2}
            >

              <Grid
                align="left"
                item
                md={3}
                xs={12}
              >
                <Button
                  color="primary"
                  disabled={isFiltersDataEmpty()}
                  fullWidth
                  onClick={handleToggleFilters}
                  startIcon={<FilterListIcon />}
                  variant="contained"
                >
                  {t('label.filters') + (filters && filters.length > 0 ? ` (${filters.length})` : '' )}
                </Button>
              </Grid>

              <Grid
                item
                md={3}
                xs={12}
              >
                <Button
                  color="primary"
                  disabled={searchButtonDisabled}
                  fullWidth
                  onClick={() => { updateTable(pagination) }}
                  startIcon={<Search />}
                  variant="contained"
                >
                  {t('label.list.screen')}
                </Button>
              </Grid>

              {/** Spacing for empty grid column */}
              <Hidden smDown>
                <Grid
                  item
                  md={3}
                />
              </Hidden>

              { ( !props.selection && props.exportCsv ) &&
              <Grid
                align="right"
                item
                md={3}
                xs={12}
              >

                <Button
                  color="primary"
                  fullWidth
                  onClick={handleCsv}
                  startIcon={<ListAlt />}
                  variant="contained"
                >
                  {t('label.list.csv')}
                </Button>

              </Grid>
              }

            </Grid>

            <Collapse
              in={filtersExpanded}
              timeout="auto"
            >
              <CardContent classes={{root: classes.cardFilter}}>
                { (!isFiltersDataEmpty()) &&
                    <DynamicFilters
                      filters={filtersData}
                      onFiltersChanged={handleUpdateFilters}
                      translationPrefix={`filter.${prefix}.`}
                    />
                }
                <></>
              </CardContent>
            </Collapse>

          </CardContent>
        </Card>
      </div>

      <div className={classes.tableContent}>
        <Card>
          <LoadingSpinner loading={loading} />
          <NoResultsFound show={(!loading && typeof tableData != 'undefined' && tableData.count == 0)}/>
          <Box
            component="div"
            display={(!loading && typeof tableData != 'undefined' && tableData.count > 0) ? 'block' : 'none'}
          >
            <CardContent className={classes.content}>
              <PerfectScrollbar>
                <div className={classes.inner}>
                  <Table>
                    <TableHead>
                      <TableRow>

                        { props.selection &&
                          <TableCell align="center">{t('label.select')}</TableCell>
                        }

                        { props.columns.map((column, index) => (
                          column.ordered ?
                            <TableCell key={index}>
                              <TableSortLabel
                                active={pagination.sort === column.name}
                                direction={pagination.order}
                                onClick={handlePaginationSortChange(column.name)}
                              >
                                {t(`${prefix}.${column.name}`)}
                              </TableSortLabel>
                            </TableCell>
                            :
                            <TableCell key={index}>
                              {t(`${prefix}.${column.name}`)}
                            </TableCell>
                        ))}

                        { !props.disableDetails && !props.selection &&
                          <TableCell align="center">{t('label.actions')}</TableCell>
                        }
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {((typeof tableData != 'undefined') && tableData.count > 0) &&
                        tableData.data.map((row, index) => (
                          <TableRow
                            hover
                            key={index}
                          >
                            { props.selection &&
                              <TableCell align="center">
                                <Tooltip
                                  aria-label={t('label.select')}
                                  title={t('label.select')}
                                >
                                  <IconButton onClick={()=>{ props.onRowSelected(row.id) }}>
                                    <CheckCircleOutlineIcon fontSize="large" />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            }

                            { props.columns.map((column, indexColumn) => {
                              return (
                                <TableCell key={indexColumn}>
                                  {
                                    column.component ?
                                      column.component(row)
                                      :
                                      row[column.name]
                                  }
                                </TableCell>);
                            })}

                            { !props.disableDetails && !props.selection &&
                              <TableCell
                                align="center"
                                style={{minWidth:250}}
                              >
                                { props.actionsColumn && props.actionsColumn.map((action, indexAction) => {
                                  return (
                                    <React.Fragment key={indexAction}>
                                      {action(row)}
                                    </React.Fragment>)
                                })
                                }

                                { !props.disableCollections &&
                                  <CollectionsContext
                                    collections = {collectionsData}
                                    itemId={parseInt(row.id)}
                                    objectTypeId={props.objectTypeId}
                                    onCollectionClicked = {handleClickOnCollection}
                                  />
                                }
                                <Tooltip
                                  aria-label={t('button.detail')}
                                  title={t('button.detail')}
                                >
                                  {/*<RouterLink color="inherit" to={`/${props.entity}/` + row.id}>*/}
                                  <IconButton  onClick={() => {handleOpenRowInNewTab(row.id)}}>
                                    <ListIcon fontSize="large" />
                                  </IconButton>
                                  {/*</RouterLink>*/}
                                </Tooltip>
                              </TableCell>
                            }
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>

                </div>
              </PerfectScrollbar>
            </CardContent>
            <CardActions className={classes.actions}>
              { typeof tableData != 'undefined' &&
                <CustomTablePagination
                  component="div"
                  count={tableData.total}
                  onPageChange={handlePaginationPageChange}
                  onRowsPerPageChange={handlePaginationSizeChange}
                  page={pagination.page}
                  rowsPerPage={pagination.size}
                />
              }
            </CardActions>
          </Box>
        </Card>
      </div>

      <Box mt={3}>
        <Grid
          container
          spacing={4}
        >
          {props.selection ?
            <Grid
              item
              md={12}
              xs={12}
            >
              <Button
                color="primary"
                onClick={() => props.onCancel()}
                variant="contained"
              >
                <ArrowBackIos /> {t('button.back')}
              </Button>
            </Grid>
            :
            <BackButton />
          }

        </Grid>
      </Box>

    </div>
  )
};

EntityList.propTypes = {
  actionsColumn : PropTypes.array,
  columns : PropTypes.array,
  disableCollections : PropTypes.bool,
  disableDetails : PropTypes.bool,
  entity: PropTypes.string,
  exportCsv: PropTypes.bool,
  objectTypeId : PropTypes.number,
  onCancel: PropTypes.func,
  onRowSelected: PropTypes.func,
  selection: PropTypes.bool,
  sort : PropTypes.object,
};

export default withSnackbar(EntityList)
