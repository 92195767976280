import config from 'config';
import { authHeader, doFetch } from 'helpers';
import { createPaginationObject } from 'helpers/pagination';
import { objectToQueryString } from 'helpers/url';

export const objectTypeService = {
  getById,
  getPage,
  getAll,
  remove,
  save
};

function getById(id) {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return doFetch(`${config.apiUrl}/settings/objectType/${id}`, requestOptions)
}

function save(data) {
  const method = (data.id) ? 'PUT' : 'POST'
  const requestOptions = { method: method, headers: authHeader(), body: JSON.stringify(data) };
  if (data.id!=null){
    return doFetch(`${config.apiUrl}/settings/objectType/${data.id}`, requestOptions);
  } else {
    return doFetch(`${config.apiUrl}/settings/objectTypes`, requestOptions);
  }
}

function remove(id) {
  const requestOptions = { method: 'DELETE', headers: authHeader() };
  return doFetch(`${config.apiUrl}/settings/objectType/${id}`, requestOptions)
}

function getPage(
  pagination,
  filterByName) {

  let paginationObj = createPaginationObject(
    pagination.page, pagination.size, pagination.sort, pagination.order);
  if(filterByName) {
    paginationObj.name = filterByName;
  }
  let queryString = objectToQueryString(paginationObj);
  let url = `${config.apiUrl}/settings/objectTypes` + (queryString && ('?' + queryString));
  const requestOptions = { method: 'GET', headers: authHeader() };
  return doFetch(url, requestOptions)
}

function getAll() {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return doFetch(`${config.apiUrl}/settings/all/objectTypes`, requestOptions)
}