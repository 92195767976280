import { Box, IconButton, List, ListItem, ListItemIcon, ListItemText, Popover, Tooltip } from '@material-ui/core';
import { CheckBoxOutlineBlank, CheckBoxOutlined, DynamicFeed } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { collectionsService } from 'services';

const useStyles = makeStyles(theme => ({
  list: {
    padding: theme.spacing(3)
  },
  button: {    
  }
}));

const CollectionsContext =  (props) => {
  
    const { t } = useTranslation();
    const classes = useStyles(props);  
    const [anchorEl, setAnchorEl] = useState(null);
    
    const handleOpenContext = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleCloseContext = () => {
      setAnchorEl(null);
    };
  
    const handleClickOnCollection = (collectionId) => {
      let itemId = props.itemId
      if (isObjectInCollection(collectionId)){
        collectionsService.removeItem(collectionId, props.objectTypeId, itemId).then(()=>{
          props.onCollectionClicked(collectionId)
        })
      } else {
        collectionsService.addItem(collectionId, props.objectTypeId, itemId).then(()=>{
          props.onCollectionClicked(collectionId)
        })
      }
    }
    
    const isObjectInCollection = (collectionId)=>{
      const collection = 
          props.collections.data.find(collection => collection.id == collectionId);
      if(!collection || !collection.items || !collection.items.list)
        return false;
      const item = collection.items.list.find(item => 
          (item.target.id == props.itemId && item.objectType.id == props.objectTypeId))
      return !!item;
    }  
  
    return (
      <>
  
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleCloseContext}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.list}
          >
            <Box display={(typeof props.collections != "undefined" && props.collections.count == 0)?"block":"none"}>
              <ListItem>  
                <ListItemText primary={t('table.result.none')} />
              </ListItem>
  
            </Box>
            
            {((typeof props.collections != "undefined") && props.collections.count > 0) 
                  && props.collections.data.map((row) => (
              <ListItem 
                button 
                key={row.id}  
                onClick={()=>{ handleClickOnCollection(row.id) }}>
                <Box display={(isObjectInCollection(row.id))?"block":"none"}>
                  <ListItemIcon>
                    <CheckBoxOutlined />
                  </ListItemIcon>
                </Box>
                <Box display={(isObjectInCollection(row.id))?"none":"block"}>
                  <ListItemIcon>
                    <CheckBoxOutlineBlank />
                  </ListItemIcon>
                </Box>
                <ListItemText primary={row.name} />
              </ListItem>
            ))}
          </List>
        </Popover>  
  
        <Tooltip title={t('button.collections')} aria-label={t('button.collections')}>
          <IconButton 
              classes={ { root: classes.button }}
              onClick={(e)=>{
                  handleOpenContext(e)
                }}>
            <DynamicFeed fontSize="large" />
          </IconButton>
        </Tooltip>   
  
      </>
    );
  }

CollectionsContext.propTypes = {
    itemId : PropTypes.number,
    objectTypeId : PropTypes.number,
    collections : PropTypes.object,
};  

export default CollectionsContext;