import moment from 'moment'
export function DateDiff (date1,date2,formated=true) {
    const d1 = new Date(date1); 
    const d2 = new Date(date2);  
    const diff_milisecs = Math.abs(d2-d1);
    if (formated){
        const days = diff_milisecs / 1000 / 60 / 60 / 24
        let days_string = ""
        if (days >= 1){
            days_string = `${days.toFixed(0)}d `
        }
        return days_string + moment.utc(moment.duration(diff_milisecs / 1000, "seconds").asMilliseconds()).format("HH:mm:ss")
    } else {
        return diff_milisecs
    }
}
export default DateDiff
