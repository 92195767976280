import { TablePagination } from '@material-ui/core';
import React from 'react';
import * as Constants from 'common/systemConstants';
import { useTranslation } from 'react-i18next';
import { formatNumber } from 'helpers/format'

const CustomTablePagination = props => {
  const { ...rest } = props
  const { t } = useTranslation();
  return (
    <TablePagination
      {...rest}
      backIconButtonText={t('table.page.previous')}
      nextIconButtonText={t('table.page.next')}
      labelRowsPerPage={t('table.page.rowsPerPage')}
      labelDisplayedRows={
        ({ from, to, count }) => {
          return `${t('table.page.rowsFrom')} ${formatNumber(from)} ${t('table.page.rowsTo')} ${formatNumber(to)} ${t('table.page.rowsCount')} ${formatNumber(count)}`
        }
      }
      rowsPerPageOptions={Constants.DEFAULT_ROWS_SIZE_OPTIONS}
    />
  );
};

CustomTablePagination.propTypes = {
};

export default CustomTablePagination;