import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { collectionsService } from 'services';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ListIcon from '@material-ui/icons/List';
import { ErrorOccurred, NoResultsFound, SearchInput } from 'components';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableSortLabel,
  TableHead,
  TableRow,
  Tooltip,
  Grid,
  IconButton,
  Box,
  Typography,
  CardHeader,
  Button
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as Constants from 'common/systemConstants';
import LoadingSpinner from 'components/LoadingSpinner';
import { Add, Archive, Delete, Edit, Label, LabelOff, List, PictureAsPdf, UnarchiveOutlined } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import BackButton from 'components/BackButton';
import CustomLink from 'components/CustomLink';
import CustomTablePagination from 'components/CustomTablePagination';
import ConfirmDialog from 'components/ConfirmDialog'
import { useConfirm } from 'material-ui-confirm';
import config from 'config';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  tableContent: {
    marginTop: theme.spacing(2)
  },
  toolbar: {
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  spacer: {
    flexGrow: 1
  },
  formControl: {
    width: '100%'
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const DEFAULT_OBJECT = "collection"
const DEFAULT_SORT_COLUMN = "name";
const DEFAULT_ORDER = Constants.ORDER_ASC;

const Collections = () => {
  const [loading, setLoading] = useState();
  const { t } = useTranslation();
  const classes = useStyles();
  const [tableData, setTableData] = useState();
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [filters, setFilters] = useState({
    description: '',
  });
  const [pagination, setPagination] = useState({
    size: Constants.DEFAULT_ROWS_SIZE,
    page: 0,
    sort: DEFAULT_SORT_COLUMN,
    order: DEFAULT_ORDER
  });
  React.useEffect(() => {
    updateTable();
  }, [pagination, filters]);

  function updateTable () {
    setLoading(true)
    collectionsService.getPage(
      pagination,
      filters.description,
    ).then(tableData => {
      if (tableData.error || tableData.data.error ){
        setErrorOccurred(tableData.error);
      } else {
        setErrorOccurred(false);
        setTableData(tableData);
      }
      setLoading(false)
    })
    .catch((e)=>{setErrorOccurred(true);setLoading(false)})
  }

  const handleFilterChange = event => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value
    });
  };
  const handlePaginationSizeChange = event => {
    setPagination({
      ...pagination,
      ["size"]: event.target.value
    });
  };
  const handlePaginationPageChange = (event, page) => {
    setPagination({
      ...pagination,
      ["page"]: page
    });
  };
  const handlePaginationSortChange = (property) => (event) => {
    let order = Constants.ORDER_ASC;
    if(property === pagination.sort
      && pagination.order == Constants.ORDER_ASC) {
        order = Constants.ORDER_DESC;
    }
    setPagination({
      ...pagination,
      ["sort"]: property,
      ["order"]: order
    });
  };

  const confirm = useConfirm();

  const handleRemove = (id) => {
    ConfirmDialog(confirm, t)
      .then(() => {
        collectionsService.remove(id).then(updateTable)
       })
      .catch(() => { /* ... */ });
  }

  const handlePDF = (object) => {
    collectionsService.downloadPDFById(object.id)
  }

  const handleArchive = (row) => {
    row.archived = !row.archived
    collectionsService.save(row).then(updateTable)
  }

  return (
    <div className={classes.root}>
          <Box mx={0} my={2}>
            <Grid container justifyContent = {"flex-end"}>
              <Grid item >
                <CustomLink
                  to={`${DEFAULT_OBJECT}s/create`}
                  >
                  <Button
                  color="primary"
                  variant="contained">
                    <Add /> {t('button.add')}
                  </Button>
                </CustomLink>
              </Grid>
            </Grid>
          </Box>
          <Card>
          <CardContent className={classes.content}>
            <PerfectScrollbar>
              <div className={classes.inner}>
                  <LoadingSpinner loading={loading} />
                  <NoResultsFound show={(!loading && typeof tableData != "undefined" && tableData.count == 0)}/>
                  <ErrorOccurred display={(!loading && errorOccurred!=false)?"block":"none"} msg={errorOccurred}/>
                  <Box component="div" display={(!loading && typeof tableData != "undefined"  && tableData.count > 0)?"block":"none"}>
                    <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                            <TableSortLabel
                              active={pagination.sort === "id"}
                              direction={pagination.order}
                              onClick={handlePaginationSortChange("id")}
                            >
                              {t(`table.label.${DEFAULT_OBJECT}.id`)}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={pagination.sort === "name"}
                            direction={pagination.order}
                            onClick={handlePaginationSortChange("name")}
                          >
                            {t(`table.label.${DEFAULT_OBJECT}.name`)}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            {t(`table.label.${DEFAULT_OBJECT}.itemsCount`)}
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={pagination.sort === "archived"}
                            direction={pagination.order}
                            onClick={handlePaginationSortChange("archived")}
                          >
                            {t(`table.label.${DEFAULT_OBJECT}.archived`)}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">{t(`table.label.${DEFAULT_OBJECT}.actions`)}</TableCell>
                      </TableRow>
                    </TableHead>
                      <TableBody>
                        {((typeof tableData != "undefined") && tableData.count > 0) &&
                            tableData.data.map(row => (
                              <TableRow
                                className={classes.tableRow}
                                hover
                                key={row.id}
                              >
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{(row.items && row.items.list && row.items.list.length > 0)?row.items.list.length:0}</TableCell>
                                <TableCell>{row.archived?t(`table.label.${DEFAULT_OBJECT}.archived.true`):t(`table.label.${DEFAULT_OBJECT}.archived.false`)}</TableCell>
                                <TableCell align="center">

                                   {(row.items && row.items.list && row.items.list.length > 0)&&
                                      <Tooltip title={t('button.pdf')} aria-label={t('button.pdf')}>
                                        <IconButton className={classes.topBarButtons} onClick={()=>{handlePDF(row)}}>
                                          <PictureAsPdf fontSize="large" />
                                        </IconButton>
                                      </Tooltip>
                                    }
                                    <Tooltip title={(row.archived)?t('button.unarchive'):t('button.archive')} aria-label={(row.archived)?t('button.unarchive'):t('button.archive')}>
                                        <IconButton className={classes.topBarButtons} onClick={()=>handleArchive(row)}>
                                          {(row.archived)?
                                            <UnarchiveOutlined fontSize="large" />
                                            :
                                            <Archive fontSize="large" />
                                          }
                                        </IconButton>
                                    </Tooltip>
                                      <CustomLink to={DEFAULT_OBJECT + '/' + row.id+'/edit'}>
                                        <Tooltip title={t('button.rename')} >
                                          <IconButton className={classes.topBarButtons}>
                                            <Edit fontSize="large" />
                                          </IconButton>
                                        </Tooltip>
                                      </CustomLink>

                                    <Tooltip title={t('button.remove')}>
                                        <IconButton className={classes.topBarButtons} onClick={()=>handleRemove(row.id)}>
                                          <Delete fontSize="large" />
                                        </IconButton>
                                    </Tooltip>

                                    <CustomLink to={DEFAULT_OBJECT + '/' + row.id}>
                                      <Tooltip title={t('button.detail')}>
                                        <IconButton className={classes.topBarButtons}>
                                          <List fontSize="large" />
                                        </IconButton>
                                      </Tooltip>
                                    </CustomLink>

                                </TableCell>
                              </TableRow>
                            ))}
                      </TableBody>
                      </Table>
                  </Box>
              </div>
            </PerfectScrollbar>
          </CardContent>
          <CardActions className={classes.actions}>
            { (typeof tableData != "undefined" && tableData.count > 0) &&
                <CustomTablePagination
                  component="div"
                  count={tableData.total}
                  onPageChange={handlePaginationPageChange}
                  onRowsPerPageChange={handlePaginationSizeChange}
                  page={pagination.page}
                  rowsPerPage={pagination.size}
                />
            }
          </CardActions>
        </Card>
        <Box mt={3}>
          <Grid
              container
              spacing={4}
            >
              <BackButton />
          </Grid>
        </Box>
    </div>
  //);
  )
  }
export default withRouter(Collections);