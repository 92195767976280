export const translation = {

    "agency.code": "Code",
    "agency.name": "Name",
    "agency.qt_publications": "Publication Qty",
    "button.add" : "Add",
    "button.add.entity" : "Add Entity",
    "button.archive" : "Arquive",
    "button.back" : "Back",
    "button.cancel": "Cancel",
    "button.centralize" : "Centralize",
    "button.collections" : "Collections",
    "button.connect.nodes" : "Connect Entities",
    "button.constelation" : "Constellation",
    "button.continue" : "Continue",
    "button.copy.clipboard": "Copy to clipboard",
    "button.delete" : "Delete",
    "button.delete.edges" : "Delete Connections",
    "button.detail": "Details",
    "button.download.image" : "Download Image",
    "button.edit" : "Edit",
    "button.edit.edge": "Edit Connection",
    "button.enter": "ENTER",
    "button.executing": "Running",
    "button.exit" : "Exit",
    "button.pause" : "Pause",
    "button.pdf" : "Export PDF",
    "button.play": "Run",
    "button.properties" : "Properties",
    "button.redo" : "Redo Changes",
    "button.refresh" : "Refresh",
    "button.rename" : "Rename",
    "button.remove" : "Remove",
    "button.removed" : "Removido",
    "button.restart" : "Restart",
    "button.save" : "Save",
    "button.search" : "Search",
    "button.search.relationships": "Search Relationships",
    "button.started" : "Started",
    "button.stopped" : "Parado",
    "button.unarchive" : "Unarchive",
    "button.undo" : "Undo Changes",
    "button.undo.delete" : "Undo Delete",
    "button.view.details" : "View",
    "collection.add": "COLLECTION",
    "collection.label.name": "Name",
    "collection.operation.create": "Create",
    "collection.operation.detail": "Detail",
    "collection.operation.edit": "Change",
    "collection.operation.itemCount": "Items count",
    "collection.title": "Collection",
    "company.code": "CNPJ",
    "company.countpublications": "Publication Qty",
    "company.head_office": "Headquarters",
    "company.name": "Company Name",
    "dashboard.count.agencies": "Public bodies",
    "dashboard.count.agencies.companies.relations": "Relationships",
    "dashboard.count.collusionRiskInPublications": "High Risk of Collusion",
    "dashboard.count.companies": "Companies",
    "dashboard.count.publications": "Publications",
    "dashboard.publications.newer.analysis": "Most recent analysis:",
    "dashboard.publications.older.analysis": "Most recent analysis:",
    "dashboard.publications.waiting.analysis": "Awaiting review:",
    "datetime.ago": "ago",
    "datetime.day": "day",
    "datetime.days": "days",
    "datetime.hour": "hour",
    "datetime.hours": "hours",
    "datetime.minute": "minute",
    "datetime.minutes": "minutes",
    "datetime.second": "second",
    "datetime.seconds": "seconds",
    "dialog.confirm.delete.cancel" : "Cancel",
    "dialog.confirm.delete.description" : "This action can not be undone",
    "dialog.confirm.delete.ok" : "Confirm",
    "dialog.confirm.delete.selected" : "Are you sure you want to delete the selected item(s)?",
    "dialog.confirm.delete.title" : "Are you sure?",
    "false":"False",
    "filter.agency.code": "Code",
    "filter.agency.name": "Name",
    "filter.company.code": "Code",
    "filter.company.name": "Company Name",
    "filter.general.settings.history.label": "Name",
    "filter.general.settings.history.username": "User",
    "filter.publication.content": "Content",
    "filter.publication.created_at": "Creation Date",
    "filter.publication.date": "Publication Date",
    "filter.publication.id": "Id",
    "filter.publication.obtainment_method": "Obtainment Method",
    "filter.publication.obtainment_method.crawler": "Crawler",
    "filter.publication.obtainment_method.json_file": "JSON File",
    "filter.publication.title": "Title",
    "filter.publication.url": "URL",
    "footer.copyright.prefix": "Copyright ©",
    "footer.version.label": "Version",
    "form.requiredField" : "Required Field",
    "form.shouldBeInteger" : "Should be integer",
    "form.shouldBeURL" : "Should be URL",
    "gazette.label.name": "Name",
    "gazette.label.sphere": "Sphere",
    "gazette.label.uri": "URI",
    "gazette.operation.create": "Create",
    "gazette.operation.edit": "Change",
    "gazette.title": "Gazette",
    "general.settings.history.change_description": "Description",
    "general.settings.history.created_at": "Creation",
    "general.settings.history.label": "Name",
    "general.settings.history.username": "User",
    "jobConfig.label.active": "Active",
    "jobConfig.label.batch_size": "Data batch size",
    "jobConfig.label.concurrence": "Concurrence",
    "jobConfig.label.data_replicated": "Data replicated",
    "jobConfig.label.description": "Description",
    "jobConfig.label.execution_day":"Beginning day to execute",
    "jobConfig.label.execution_day_end":"Final day to execute",
    "jobConfig.label.execution_time":"Execution time (UTC)",
    "jobConfig.label.extra_config": "Configuration Extra",
    "jobConfig.label.inactive": "Inactive",
    "jobConfig.label.interval_seconds": "Interval (seconds)",
    "jobConfig.label.name": "Name",
    "jobConfig.label.recurrent": "Recurrent",
    "jobConfig.label.specific.day":"Day to execute",
    "jobConfig.label.url_data_destination": "Data batch destination URL",
    "jobConfig.label.url_data_source": "Data batch source URL",
    "jobConfig.label.window_days": "Days window in the past from today",
    "jobConfig.operation.create": "Create",
    "jobConfig.operation.edit": "Change",
    "jobConfig.title": "Job Configuration",
    "jobs.identifier": "identifier",
    "jobs.info":" Information: ",
    "jobs.info.empty": "No information to display.",
    "jobs.last.update": "Last updated",
    "jobs.name": "Name",
    "jobs.status": "Situation",
    "jobs.subtitle": "Jobs running in the background",
    "label.actions" : "Actions",
    "label.add.filter.field": "Add filter field",
    "label.advanced": "Advanced",
    "label.agencies" : "Organs",
    "label.agency" : "Organ",
    "label.all" : "All",
    "label.analisys.resume" : "Analisys",
    "label.begin": "Start",
    "label.code" : "Code",
    "label.collection" : "Collection",
    "label.collections" : "Collections",
    "label.comments" : "Comments",
    "label.companies" : "Companies",
    "label.company" : "Company",
    "label.constellation" : "Constellation",
    "label.content" : "Content",
    "label.copied" : "Copied!",
    "label.created_at" : "Created at",
    "label.dashed" : "Dashed",
    "label.date" : "Date",
    "label.date.maximal" : "Maximal date",
    "label.date.minimal" : "Minimal date",
    "label.density": "Density",
    "label.description" : "Description",
    "label.details.next": "Next",
    "label.details.previous": "Previous",
    "label.empty.items" : "No items to show.",
    "label.end": "Finish",
    "label.entity": "Entity",
    "label.entity.agency": "Agency",
    "label.entity.company": "Company",
    "label.entity.publication": "Publication",
    "label.field": "Field",
    "label.filters" : "Filters",
    "label.filters.and.condition" : "And",
    "label.filters.condition" : "Condition",
    "label.filters.not.condition" : "Not In",
    "label.filters.or.condition" : "Or",
    "label.gazette" : "Gazette",
    "label.id" : "Id",
    "label.items" : "Items",
    "label.lastAnalysis" : "Last Analysed",
    "label.list.csv" : "Export to CSV",
    "label.list.screen" : "List on screen",
    "label.name" : "Name",
    "label.nextUpdate": "Next update",
    "label.node.color" : "Color",
    "label.node.connection" : "Entity Connection",
    "label.obtained_at" : "Obtained at",
    "label.only.active" : "Only active",
    "label.paused" : "paused",
    "label.properties" : "Properties",
    "label.properties" : "Properties",
    "label.property" : "Property",
    "label.publication" : "Publication",
    "label.publications" : "Publications",
    "label.rate" : "Rate",
    "label.register.saved": "Register saved!",
    "label.relationship" : "Relationship",
    "label.relationships" : "Relationships",
    "label.risk" : "Risk",
    "label.risk.maximal" : "Maximal risk",
    "label.risk.minimal" : "Minimal risk",
    "label.risks" : "Risks",
    "label.search" : "Search",
    "label.select": "Select",
    "label.select.field": "Select field",
    "label.select.service" : "Select the service to be monitored",
    "label.setting" : "Setting",
    "label.source" : "Source",
    "label.status.active" : "Active",
    "label.status.inactive" : "Inactive",
    "label.target" : "Target",
    "label.term" : "Term",
    "label.text" : "Text",
    "label.title" : "Title",
    "label.type" : "Type",
    "label.updated.at" : "Updated at: ",
    "label.updated_at" : "Last update",
    "label.url" : "URL",
    "label.value": "Value",
    "login.access": "Access",
    "login.email": "Email",
    "login.forgot": "I forgot my password",
    "login.passwd": "password",
    "label.publication.date" : "Publication date",
    "login.remember.me": "Remember me",
    "login.song": "\"...I focus on the present concentrate on what I find...\"",
    "login.song.artist": "Joseph Duplantier and Mario Duplantier",
    "login.song.title": "Gojira",
    "menu.about": "About",
    "menu.agencies": "Organs",
    "menu.agency": "Organ",
    "menu.collection" : "Collection",
    "menu.collections" : "Collections",
    "menu.companies": "Companies",
    "menu.company": "Company",
    "menu.configs": "Settings",
    "menu.configs.history": "Changes History",
    "menu.constellation": "Constellation",
    "menu.gazettes": "Gazettes",
    "menu.home": "Home",
    "menu.jobs": "Jobs",
    "menu.logs": "System Logs",
    "menu.objectTypes": "Entities",
    "menu.otherServices" : "Other Services",
    "menu.publication": "Publication",
    "menu.publications": "Publications",
    "menu.riskTypes": "Risk Types",
    "menu.smartModels" : "Smart Models",
    "menu.spheres": "Spheres",
    "objectType.label.description": "Description",
    "objectType.operation.create": "Create",
    "objectType.operation.edit": "Change",
    "objectType.title": "Entity",
    "otherServices.classifiers": "Classifier",
    "otherServices.cnpj": "CNPJ Inquiry",
    "otherServices.database": "Data Admin",
    "otherServices.docs": "API documentation",
    "otherServices.grafana": "Grafana Dashboard",
    "otherServices.keycloak": "User Admin",
    "page.click.here": "(or click <linkTo>here</linkTo>)",
    "page.not.found.message" : "You have accessed an invalid address or came here by mistake. Whichever it is, try using the navigation.",
    "page.not.found.title" : "Page not found",
    "page.redirecting": "Wait, you will be redirected...",
    "publication.content" : "Content",
    "publication.date": "Publication Date",
    "publication.title" : "Title",
    "publication.url" : "URL",
    "riskType.label.description": "Description",
    "riskType.operation.create": "Create",
    "riskType.operation.edit": "Change",
    "riskType.title": "Risk type",
    "session.expired.message" : "Your session has expired, please login again.",
    "session.expired.title" : "Session expired",
    "settings.ai.models": "Smart Models",
    "settings.ai.models": "Smart Models",
    "settings.collects": "Collect",
    "settings.crawlers": "Collect",
    "settings.integrations": "Integrations",
    "settings.jobs": "Jobs",
    "settings.jobs.configuration": "Configurations",
    "settings.jobs.executions": "Executions",
    "settings.jobs.batches": "Batches",
    "settings.objectType": "Entity",
    "settings.objectType": "Entities",
    "settings.riskType": "Risk Type",
    "settings.riskType": "Risk Types",
    "sphere.label.name": "Name",
    "sphere.operation.create": "Create",
    "sphere.operation.edit": "Change",
    "sphere.title": "Sphere",
    "status.job.canceled":"Canceled",
    "status.job.config":"Configuration",
    "status.job.created":"Created",
    "status.job.error":"Error",
    "status.job.execution":"Execution",
    "status.job.finished":"Finished",
    "status.job.initialized":"Initialized",
    "status.job.initializing":"Initializing",
    "status.job.running":"Running",
    "system.copyright.url": "https://deepvacuity.cic.unb.br",
    "system.copyright.year": "2021",
    "system.subtitle": "",
    "system.title": "Deep Vacuity",
    "table.content.Agency" : "Agency",
    "table.content.Company" : "Company",
    "table.content.Publication" : "Publication",
    "table.content.undefined" : "(undefined)",
    "table.label.collection.actions": "Actions",
    "table.label.collection.archived": "Archived",
    "table.label.collection.archived.false": "False",
    "table.label.collection.archived.true": "Yes",
    "table.label.collection.id": "Id",
    "table.label.collection.itemsCount": "Items Count",
    "table.label.collection.name": "Name",
    "table.label.gazette.actions": "Actions",
    "table.label.gazette.id": "Id",
    "table.label.gazette.name": "Name",
    "table.label.gazette.sphere": "Sphere",
    "table.label.gazette.uri": "URI",
    "table.label.jobs.actions": "Actions",
    "table.label.jobs.active": "Active",
    "table.label.jobs.batch_size": "Batch Size",
    "table.label.jobs.concurrence": "Concurrence",
    "table.label.jobs.content": "Content",
    "table.label.jobs.created_at": "Created at",
    "table.label.jobs.description": "Description",
    "table.label.jobs.duration": "Duration",
    "table.label.jobs.execution": "Execution",
    "table.label.jobs.execution.mode": "Execution mode",
    "table.label.jobs.finished_at": "Finished at",
    "table.label.jobs.id": "Id",
    "table.label.jobs.interval": "Interval (sec)",
    "table.label.jobs.itemsCount": "Items count",
    "table.label.jobs.itemsDone": "Items done",
    "table.label.jobs.name": "Name",
    "table.label.jobs.nextCursorMark": "Next Cursor Mark",
    "table.label.jobs.page": "Page",
    "table.label.jobs.previousChunkId": "Previous Chunk Id",
    "table.label.jobs.processRate": "Processment Rate",
    "table.label.jobs.processRateSuffix": "Items per minute",
    "table.label.jobs.progress": "Progress",
    "table.label.jobs.queryDuration": "Query Duration (ms)",
    "table.label.jobs.recurrence": "Recurrence",
    "table.label.jobs.recurrence.daily": "Daily",
    "table.label.jobs.recurrence.interval": "By interval",
    "table.label.jobs.recurrence.single": "Single",
    "table.label.jobs.recurrence.specific": "By specific day",
    "table.label.jobs.size": "Size",
    "table.label.jobs.started_at": "Started at",
    "table.label.jobs.status": "Status",
    "table.label.jobs.tasksCount": "Tasks count",
    "table.label.jobs.tasksDone": "Tasks done",
    "table.label.jobs.timeLeft": "Time left",
    "table.label.jobs.updated_at": "Updated at",
    "table.label.objectType.actions": "Actions",
    "table.label.objectType.description": "Description",
    "table.label.objectType.id": "Id",
    "table.label.riskType.actions": "Actions",
    "table.label.riskType.description": "Description",
    "table.label.riskType.id": "Id",
    "table.label.smart-model.actions": "Actions",
    "table.label.smart-model.default_runners": "Executor Qty",
    "table.label.smart-model.description": "Description",
    "table.label.smart-model.id": "Id",
    "table.label.smart-model.url": "URL",
    "table.label.sphere.actions": "Actions",
    "table.label.sphere.id": "Id",
    "table.label.sphere.name": "Name",
    "table.page.next": "Next page",
    "table.page.previous": "Previous page",
    "table.page.rowsCount": "in a count of",
    "table.page.rowsFrom": "Rows from",
    "table.page.rowsPerPage": "Rows per page",
    "table.page.rowsTo": "to",
    "table.result.error": "Request failed",
    "table.result.none": "No records found",
    "toggle.active" : "Enable",
    "toggle.inactive" : "Disable",
    "true":"True",
    "validation.begin.greater.than.end": "The starting value is greater than the ending value.",
    "validation.invalid.date": "The date entered is invalid.",
    "validation.max.number": "The value must be less than or equal to {{value}}.",
    "validation.max.text": "The field must have at most {{value}} characters.",
    "validation.min.number": "The value must be greater than or equal to {{value}}.",
    "validation.min.text": "The field must have at least {{value}} characters.",
    "validation.no.value": "No values were entered.",
}