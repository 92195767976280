import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { jobConfigService } from 'services';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ListIcon from '@material-ui/icons/List';
import { ErrorOccurred, NoResultsFound, SearchInput } from 'components';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableSortLabel,
  TableHead,
  TableRow,
  Tooltip,
  Grid,
  IconButton,
  Box,
  Typography,
  CardHeader,
  Button,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as Constants from 'common/systemConstants';
import LoadingSpinner from 'components/LoadingSpinner';
import { Add, Delete, Edit, PlayCircleFilled } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import BackButton from 'components/BackButton';
import CustomLink from 'components/CustomLink';
import CustomTablePagination from 'components/CustomTablePagination';
import ConfirmDialog from 'components/ConfirmDialog'
import { useConfirm } from 'material-ui-confirm';
import { withSnackbar } from 'hooks/withSnackbar';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  tableContent: {
    marginTop: theme.spacing(2)
  },
  toolbar: {
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  spacer: {
    flexGrow: 1
  },
  formControl: {
    width: '100%'
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const DEFAULT_SORT_COLUMN = 'active';
const DEFAULT_ORDER = Constants.ORDER_DESC;

const Configurations = (props) => {
  const [loading, setLoading] = useState();
  const { t } = useTranslation();
  const classes = useStyles();
  const [tableData, setTableData] = useState();
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [filters, setFilters] = useState({
    description: '',
    active: true
  });
  const [pagination, setPagination] = useState({
    size: Constants.DEFAULT_ROWS_SIZE,
    page: 0,
    sort: DEFAULT_SORT_COLUMN,
    order: DEFAULT_ORDER
  });
  React.useEffect(() => {
    updateTable();
  }, [pagination, filters]);

  function updateTable () {
    setLoading(true)
    jobConfigService.getPage(
      pagination,
      filters.description,
      filters.active
    ).then(tableData => {
      if (tableData.error){
        setErrorOccurred(tableData.error);
      } else {
        setErrorOccurred(false);
        setTableData(tableData);
      }
      setLoading(false)
    })
    .catch((e)=>{setErrorOccurred(true);setLoading(false)})
  }

  const handleFilterChange = event => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.hasOwnProperty("checked") ?
        event.target.checked : event.target.value
    });
  };

  const handlePaginationSizeChange = event => {
    setPagination({
      ...pagination,
      ["size"]: event.target.value
    });
  };
  const handlePaginationPageChange = (event, page) => {
    setPagination({
      ...pagination,
      ["page"]: page
    });
  };
  const handlePaginationSortChange = (property) => (event) => {
    let order = Constants.ORDER_ASC;
    if(property === pagination.sort
      && pagination.order == Constants.ORDER_ASC) {
        order = Constants.ORDER_DESC;
    }
    setPagination({
      ...pagination,
      ["sort"]: property,
      ["order"]: order
    });
  };

  const confirm = useConfirm();

  const handleRemove = (id) => {
    ConfirmDialog(confirm, t)
      .then(() => {
        jobConfigService.remove(id).then(()=>{
          props.showMessageSuccess(t('button.removed'))
          updateTable()
        })
       })
      .catch((exception) => {
        props.showMessageError(exception.message)
      });
  }

  const handlePlay = (id) => {
    jobConfigService.start(id).then(res=>{
      props.showMessageSuccess(t('button.started'))
    })
  }

  return (
    <div className={classes.root}>
          <Box mx={0} my={2}>
            <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.active}
                    onClick={handleFilterChange}
                    name="active"
                    color="primary"
                  />
                }
                label={t(`label.only.active`)}
              />
            <Grid container justifyContent = "flex-end">
                  <CustomLink
                    to="config/create"
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<Add/>}
                    >
                      {t('button.add')}
                    </Button>
                  </CustomLink>
            </Grid>
          </Box>
          <Card>
          <CardContent className={classes.content}>
            <PerfectScrollbar>
              <div className={classes.inner}>
                  <LoadingSpinner loading={loading} />
                  <NoResultsFound show={(!loading && typeof tableData != "undefined" && tableData.count == 0)}/>
                  {/* {<ErrorOccurred display={(!loading && errorOccurred!=false)?"block":"none"} msg={errorOccurred}/>} */}
                  <Box component="div" display={(!loading && typeof tableData != "undefined"  && tableData.count > 0)?"block":"none"}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                              <TableSortLabel
                                active={pagination.sort === "id"}
                                direction={pagination.order}
                                onClick={handlePaginationSortChange("id")}
                              >
                                {t('table.label.smart-model.id')}
                              </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={pagination.sort === "active"}
                              direction={pagination.order}
                              onClick={handlePaginationSortChange("active")}
                            >
                              {t('table.label.jobs.active')}
                            </TableSortLabel>
                          </TableCell>

                          <TableCell>
                            <TableSortLabel
                              active={pagination.sort === "name"}
                              direction={pagination.order}
                              onClick={handlePaginationSortChange("name")}
                            >
                              {t('table.label.jobs.name')}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={pagination.sort === "description"}
                              direction={pagination.order}
                              onClick={handlePaginationSortChange("description")}
                            >
                              {t('table.label.jobs.description')}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                              {t('table.label.jobs.execution.mode')}
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={pagination.sort === "batch_size"}
                              direction={pagination.order}
                              onClick={handlePaginationSortChange("batch_size")}
                            >
                              {t('table.label.jobs.batch_size')}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={pagination.sort === "concurrence"}
                              direction={pagination.order}
                              onClick={handlePaginationSortChange("concurrence")}
                            >
                              {t('table.label.jobs.concurrence')}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell align="center">{t('table.label.jobs.actions')}</TableCell>
                        </TableRow>
                      </TableHead>
                        <TableBody>
                          {((typeof tableData != "undefined") && tableData.count > 0) &&
                              tableData.data.map(row => (
                                <TableRow
                                  className={classes.tableRow}
                                  hover
                                  key={row.id}
                                >
                                  <TableCell>{row.id}</TableCell>
                                  <TableCell>{t(row.active)}</TableCell>
                                  <TableCell>{row.name}</TableCell>
                                  <TableCell>{row.description}</TableCell>
                                  <TableCell>
                                    {(() => {
                                      if      (row.daily)                                  return t('table.label.jobs.recurrence.daily')
                                      else if (row.execution_day && row.execution_day_end) return t('table.label.jobs.recurrence.interval')
                                      else if (row.execution_day)                          return t('table.label.jobs.recurrence.specific')
                                      else                                                 return t('table.label.jobs.recurrence.single')
                                    })()}
                                  </TableCell>
                                  <TableCell>{row.batch_size}</TableCell>
                                  <TableCell>{row.concurrence}</TableCell>
                                  <TableCell align="center">
                                      <CustomLink to={'config/' + row.id+'/edit'}>
                                        <Tooltip title={t('button.edit')} aria-label={t('button.edit')}>
                                          <IconButton className={classes.topBarButtons} size="small">
                                            <Edit />
                                          </IconButton>
                                        </Tooltip>
                                      </CustomLink>
                                      <Tooltip title={t('button.remove')} aria-label={t('button.remove')}>
                                          <IconButton className={classes.topBarButtons} size="small" >
                                            <Delete onClick={()=>handleRemove(row.id)} />
                                          </IconButton>
                                      </Tooltip>
                                      {row.active &&
                                        <Tooltip title={t('button.play')} aria-label={t('button.play')}>
                                            <IconButton className={classes.topBarButtons} size="small" >
                                              <PlayCircleFilled onClick={()=>handlePlay(row.id)} />
                                            </IconButton>
                                        </Tooltip>
                                      }
                                  </TableCell>
                                </TableRow>
                              ))}
                        </TableBody>
                      </Table>
                  </Box>
              </div>
            </PerfectScrollbar>
          </CardContent>
          <CardActions className={classes.actions}>
            { (typeof tableData != "undefined" && tableData.count > 0) &&
                <CustomTablePagination
                  component="div"
                  count={tableData.total}
                  onPageChange={handlePaginationPageChange}
                  onRowsPerPageChange={handlePaginationSizeChange}
                  page={pagination.page}
                  rowsPerPage={pagination.size}
                  rowsPerPageOptions={Constants.DEFAULT_ROWS_SIZE_OPTIONS}
                />
            }
          </CardActions>
        </Card>
        <Box mt={3}>
          <Grid
              container
              spacing={4}
            >
              <BackButton />
          </Grid>
        </Box>
    </div>
  )
}
export default withSnackbar(withRouter(Configurations));