import React, { useState, useRef } from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { AccessTime as ClockIcon } from '@material-ui/icons';

const DEFAULT_FORMAT = "HH:mm:ss"

const Clock = (props) => {

    const [date, setDate] = useState(getNow(props.utc));

    function getNow(isUtc) {
      let now = moment()
      if(isUtc) {
        now = now.utc()
      }
      return now.format(props.format ?
        props.format : DEFAULT_FORMAT)
    }

    React.useEffect(() => {
      setInterval(() => setDate(getNow(props.utc)), 1000);
    }, []);

    return (
        <Box display='flex' alignItems='center'>
            <ClockIcon fontSize="medium" />
            <div style={{ marginLeft: "15px", fontFamily: 'arial' }}>
                <b>UTC:</b> {date}
            </div>
        </Box>
    );
}

Clock.propTypes = {
    utc : PropTypes.bool,
    format: PropTypes.string
};

export default Clock;