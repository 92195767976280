import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CollectionIcon from '@material-ui/icons/AddToPhotosOutlined';

import { SearchInput } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  spacer: {
    flexGrow: 1
  },
  formControl: {
    width: '100%'
  }
}));

const TableToolbar = props => {
  const { className, ...rest } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            xs={10}
          >
            <SearchInput
              className={classes.searchInput}
              placeholder="Pesquise por órgãos, empresas, riscos, publicações..."
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  // Do code here
                  ev.preventDefault();
                }
              }}
            />

            </Grid>

            <Grid item
                xs={2}
                align="right"
                justifyContent="right">
              <Button
                color="primary"
                variant="contained"
                startIcon={<CollectionIcon />}
              >
                {t('collection.add')}
              </Button>

            </Grid>

        </Grid>


    </div>
  );
};

TableToolbar.propTypes = {
  className: PropTypes.string
};

export default TableToolbar;
