
export const translation = {
    "agency.code" : "Código",
    "agency.name": "Nome",
    "agency.qt_publications" : "Qtde de Publicações",
    "button.add" : "Incluir",
    "button.add.entity" : "Adicionar Entidade",
    "button.archive" : "Arquivar",
    "button.back" : "Voltar",
    "button.cancel": "Cancelar",
    "button.centralize" : "Centralizar",
    "button.collections" : "Coleções",
    "button.connect.nodes" : "Conectar Entidades",
    "button.constelation" : "Constelação",
    "button.continue" : "Continuar",
    "button.copy.clipboard": "Copiar para área de transferência",
    "button.delete" : "Excluir",
    "button.delete.edges" : "Excluir Conexões",
    "button.detail" : "Detalhes",
    "button.download.image" : "Baixar Imagem",
    "button.edit" : "Alterar",
    "button.edit.edge": "Editar Conexão",
    "button.enter" : "ENTRAR",
    "button.executing" : "Em execução",
    "button.exit" : "Sair",
    "button.pause" : "Pausar",
    "button.pdf" : "Exportar PDF",
    "button.play" : "Executar",
    "button.properties" : "Propriedades",
    "button.redo" : "Refazer Alterações",
    "button.refresh" : "Atualizar",
    "button.rename" : "Renomear",
    "button.remove" : "Remover",
    "button.removed" : "Removido",
    "button.restart" : "Reiniciar",
    "button.save" : "Salvar",
    "button.search" : "Pesquisar",
    "button.search.relationships": "Buscar Relações",
    "button.started" : "Iniciado",
    "button.stopped" : "Parado",
    "button.unarchive" : "Desarquivar",
    "button.undo" : "Desfazer Alterações",
    "button.undo.delete" : "Desfazer Exclusão",
    "button.view.details" : "Visualizar",
    "collection.add" : "COLEÇÃO",
    "collection.label.name": "Nome",
    "collection.operation.create": "Criar",
    "collection.operation.detail": "Detalhe",
    "collection.operation.edit": "Alterar",
    "collection.operation.itemCount": "Qtd de itens",
    "collection.title": "Coleção",
    "company.code": "CNPJ",
    "company.countpublications" : "Qtde de Publicações",
    "company.head_office" : "Matriz",
    "company.name": "Razão Social",
    "dashboard.count.agencies":"Órgãos públicos",
    "dashboard.count.agencies.companies.relations":"Relacionamentos",
    "dashboard.count.collusionRiskInPublications":"Alto Risco de Conlúio",
    "dashboard.count.companies":"Empresas",
    "dashboard.count.publications":"Publicações",
    "dashboard.publications.newer.analysis":"Análise mais recente: ",
    "dashboard.publications.older.analysis":"Análise mais recente: ",
    "dashboard.publications.waiting.analysis":" Aguardando análise: ",
    "datetime.ago" : "atrás",
    "datetime.day" : "dia",
    "datetime.days" : "dias",
    "datetime.hour" : "hora",
    "datetime.hours" : "horas",
    "datetime.minute" : "minute",
    "datetime.minutes" : "minutos",
    "datetime.second" : "segundo",
    "datetime.seconds" : "segundos",
    "dialog.confirm.delete.cancel" : "Cancelar",
    "dialog.confirm.delete.description" : "Esta ação não poderá ser desfeita",
    "dialog.confirm.delete.ok" : "Confirmar",
    "dialog.confirm.delete.selected" : "Tem certeza que deseja excluir o(s) item(ns) selecionado(s)?",
    "dialog.confirm.delete.title" : "Tem certeza que deseja excluir este item?",
    "false":"Não",
    "filter.agency.code": "Código (CNPJ)",
    "filter.agency.name": "Nome",
    "filter.company.code": "Código (CNPJ)",
    "filter.company.name": "Razão Social",
    "filter.general.settings.history.label": "Nome",
    "filter.general.settings.history.username": "Usuário",
    "filter.publication.content": "Conteúdo",
    "filter.publication.created_at": "Data de Criação",
    "filter.publication.date": "Data da Publicação",
    "filter.publication.id": "Id",
    "filter.publication.obtainment_method": "Método de Obtenção",
    "filter.publication.obtainment_method.crawler": "Coletor",
    "filter.publication.obtainment_method.json_file": "Arquivo JSON",
    "filter.publication.risk": "Risco",
    "filter.publication.title": "Título",
    "filter.publication.url": "URL",
    "footer.copyright.prefix" : "Copyright © ",
    "footer.version.label" : "Versão",
    "form.requiredField" : "Campo Obrigatório",
    "form.shouldBeInteger" : "Precisa ser um inteiro",
    "form.shouldBeURL" : "Precisa ser um endereço URL",
    "gazette.label.name": "Nome",
    "gazette.label.sphere": "Esfera",
    "gazette.label.uri": "URI",
    "gazette.operation.create": "Criar",
    "gazette.operation.edit": "Alterar",
    "gazette.title": "Diário Oficial",
    "general.settings.history.change_description": "Descrição",
    "general.settings.history.created_at": "Criação",
    "general.settings.history.label": "Nome",
    "general.settings.history.username": "Usuário",
    "jobConfig.label.active": "Ativo",
    "jobConfig.label.batch_size": "Tamanho do lote de dados",
    "jobConfig.label.concurrence": "Quantidade de lotes simultâneos",
    "jobConfig.label.data_replicated": "Dados replicados",
    "jobConfig.label.description": "Descrição",
    "jobConfig.label.execution_day":"Dia inicial a executar",
    "jobConfig.label.execution_day_end":"Dia final a executar",
    "jobConfig.label.execution_time":"Horário de execução (UTC)",
    "jobConfig.label.extra_config": "Configuração adicional",
    "jobConfig.label.inactive": "Inativo",
    "jobConfig.label.interval_seconds": "Intervalo (segundos)",
    "jobConfig.label.name": "Nome",
    "jobConfig.label.recurrent": "Recorrente",
    "jobConfig.label.specific.day":"Dia a executar",
    "jobConfig.label.url_data_destination": "URL para envio dos lotes de dados",
    "jobConfig.label.url_data_source": "URL de origem dos lotes de dados",
    "jobConfig.label.window_days": "Janela de dias no passado a partir de hoje",
    "jobConfig.label.window_days": "Janela de dias no passado a partir de hoje",
    "jobConfig.operation.create": "Criar",
    "jobConfig.operation.edit": "Alterar",
    "jobConfig.title": "Configuração de Job",
    "jobs.identifier": "Identificador",
    "jobs.info": "Informações:",
    "jobs.info.empty": "Sem informações a exibir.",
    "jobs.last.update": "Última atualização",
    "jobs.name": "Nome",
    "jobs.status": "Situação",
    "jobs.subtitle": "Jobs de execução em segundo plano",
    "label.actions" : "Ações",
    "label.add.filter.field": "Adicionar campo de filtro",
    "label.advanced": "Avançado",
    "label.agencies" : "Órgãos",
    "label.agency" : "Órgão",
    "label.all" : "Todos",
    "label.analisys.resume" : "Análise",
    "label.begin": "Inicial",
    "label.code" : "Código",
    "label.collection" : "Coleção",
    "label.collections" : "Coleções",
    "label.comments" : "Comentários",
    "label.companies" : "Empresas",
    "label.company" : "Empresa",
    "label.constellation" : "Constelação",
    "label.content" : "Conteúdo",
    "label.copied" : "Copiado!",
    "label.created_at" : "Criado em",
    "label.dashed" : "Tracejado",
    "label.date" : "Data",
    "label.date.maximal" : "Data final",
    "label.date.minimal" : "Data inicial",
    "label.density": "Espessura",
    "label.description" : "Descrição",
    "label.details.next": "Próximo",
    "label.details.previous": "Anterior",
    "label.empty.items" : "Nenhum item a exibir.",
    "label.end": "Final",
    "label.entity": "Entidade",
    "label.entity.agency": "Órgão",
    "label.entity.company": "Empresa",
    "label.entity.publication": "Publicação",
    "label.field": "Campo",
    "label.filters" : "Filtros",
    "label.filters.and.condition" : "E",
    "label.filters.condition" : "Condição",
    "label.filters.not.condition" : "Não Contenha",
    "label.filters.or.condition" : "Ou",
    "label.gazette" : "Diário oficial",
    "label.id" : "Id",
    "label.items" : "Itens",
    "label.lastAnalysis" : "Última análise",
    "label.list.csv" : "Exportar para CSV",
    "label.list.screen" : "Listar na tela",
    "label.name" : "Nome",
    "label.nextUpdate": "Próxima atualização",
    "label.node.color" : "Cor",
    "label.node.connection" : "Conexão de Entidades",
    "label.obtained_at" : "Inclusão na plataforma",
    "label.only.active" : "Somente ativos",
    "label.paused" : "pausado",
    "label.properties" : "Propriedades",
    "label.properties" : "Propriedades",
    "label.property" : "Propriedade",
    "label.publication" : "Publicação",
    "label.publications" : "Publicações",
    "label.rate" : "Taxa",
    "label.register.saved": "Registro salvo!",
    "label.relationship" : "Relacionamento",
    "label.relationships" : "Relacionamentos",
    "label.risk" : "Risco",
    "label.risk.maximal" : "Risco máximo",
    "label.risk.minimal" : "Risco mínimo",
    "label.risks" : "Riscos",
    "label.search" : "Busca",
    "label.select": "Selecionar",
    "label.select.field": "Selecione o campo",
    "label.select.service" : "Selecione o serviço a ser monitorado",
    "label.setting" : "Configuração",
    "label.source" : "Source",
    "label.status.active" : "Ativo",
    "label.status.inactive" : "Inativo",
    "label.target" : "Alvo",
    "label.term" : "Termo",
    "label.text" : "Texto",
    "label.title" : "Título",
    "label.type" : "Tipo",
    "label.updated.at" : "Atualizado em: ",
    "label.updated_at" : "Última atualização",
    "label.url" : "URL",
    "label.value": "Valor",
    "login.access" : "Acesso",
    "login.email" : "E-mail",
    "login.forgot" : "Esqueci a senha",
    "login.passwd" : "Senha",
    "login.remember.me" : "Lembrar-me",
    "label.publication.date" : "Data da publicação",
    "login.song" : "\"... I focus on the present concentrate on what I find ...\"",
    "login.song.artist" : "Joseph Duplantier and Mario Duplantier",
    "login.song.title" : "Gojira",
    "menu.about": "Sobre",
    "menu.agencies": "Órgãos",
    "menu.agency": "Órgão",
    "menu.collection" : "Coleção",
    "menu.collections" : "Coleções",
    "menu.companies": "Empresas",
    "menu.company": "Empresa",
    "menu.configs": "Configurações",
    "menu.configs.history": "Histórico de Alterações",
    "menu.constellation": "Constelação",
    "menu.gazettes": "Diários Oficiais",
    "menu.home": "Início",
    "menu.jobs": "Jobs",
    "menu.logs": "Logs do Sistema",
    "menu.objectTypes": "Entidades",
    "menu.otherServices" : "Outros Serviços",
    "menu.publication": "Publicação",
    "menu.publications": "Publicações",
    "menu.riskTypes": "Tipos de Riscos",
    "menu.smartModels" : "Modelos Inteligentes",
    "menu.spheres": "Esferas",
    "objectType.label.description": "Descrição",
    "objectType.operation.create": "Criar",
    "objectType.operation.edit": "Alterar",
    "objectType.title": "Entidade",
    "otherServices.classifiers": "Classificador",
    "otherServices.cnpj": "Consulta ao CNPJ",
    "otherServices.database": "Admin. de Dados",
    "otherServices.docs": "Documentação da API",
    "otherServices.grafana": "Dashboard do Grafana",
    "otherServices.keycloak": "Admin. de Usuários",
    "page.click.here": "(ou clique <linkTo>aqui</linkTo>)",
    "page.not.found.message": "Você acessou um endereço inválido ou chegou aqui por engano. Seja qual for, tente usar a navegação.",
    "page.not.found.title": "Página não encontrada",
    "page.redirecting": "Aguarde, você será redirecionado...",
    "publication.content" : "Conteúdo",
    "publication.date": "Data da Publicação",
    "publication.title" : "Título",
    "publication.url" : "URL",
    "riskType.label.description": "Descrição",
    "riskType.operation.create": "Criar",
    "riskType.operation.edit": "Alterar",
    "riskType.title": "Tipo de risco",
    "session.expired.message" : "Sua sessão expirou, por favor faça o login novamente.",
    "session.expired.title" : "Sessão expirada",
    "settings.ai.models": "Modelos Inteligentes",
    "settings.collects": "Coleta",
    "settings.crawlers": "Coleta",
    "settings.jobs": "Jobs",
    "settings.jobs.configuration": "Configurações",
    "settings.jobs.executions": "Execuções",
    "settings.jobs.batches": "Lotes",
    "settings.objectType": "Tipo de Objeto",
    "settings.riskType": "Tipo de Risco",
    "sphere.label.name": "Nome",
    "sphere.operation.create": "Criar",
    "sphere.operation.edit": "Alterar",
    "sphere.title": "Esfera",
    "status.job.canceled":"Cancelado",
    "status.job.config":"Configuração",
    "status.job.created":"Criado",
    "status.job.error":"Erro",
    "status.job.execution":"Execução",
    "status.job.finished":"Finalizado",
    "status.job.initialized":"Inicializada",
    "status.job.initializing":"Inicializando",
    "status.job.running":"Executando",
    "system.copyright.url" : "https://deepvacuity.cic.unb.br",
    "system.copyright.year" : "2021",
    "system.subtitle" : "",
    "system.title" : "Deep Vacuity",
    "table.content.Agency" : "Órgão",
    "table.content.Company" : "Empresa",
    "table.content.Publication" : "Publicação",
    "table.content.undefined" : "(não definido)",
    "table.label.collection.actions": "Ações",
    "table.label.collection.archived": "Arquivado",
    "table.label.collection.archived.false": "Não",
    "table.label.collection.archived.true": "Sim",
    "table.label.collection.id": "Id",
    "table.label.collection.itemsCount": "Qtde de Itens",
    "table.label.collection.name": "Nome",
    "table.label.gazette.actions": "Ações",
    "table.label.gazette.id": "Id",
    "table.label.gazette.name": "Nome",
    "table.label.gazette.sphere": "Espera",
    "table.label.gazette.uri": "URI",
    "table.label.jobs.actions": "Ações",
    "table.label.jobs.active": "Ativo",
    "table.label.jobs.batch_size": "Tamanho do Lote",
    "table.label.jobs.concurrence": "Concorrência",
    "table.label.jobs.content": "Conteúdo",
    "table.label.jobs.created_at": "Criado em",
    "table.label.jobs.description": "Descrição",
    "table.label.jobs.duration": "Duração",
    "table.label.jobs.execution": "Execução",
    "table.label.jobs.execution.mode": "Modo de execução",
    "table.label.jobs.finished_at": "Finalizado em",
    "table.label.jobs.id": "Id",
    "table.label.jobs.interval": "Intervalo (seg)",
    "table.label.jobs.itemsCount": "Qtde de itens",
    "table.label.jobs.itemsDone": "Itens finalizados",
    "table.label.jobs.name": "Nome",
    "table.label.jobs.nextCursorMark": "Próximo Cursor",
    "table.label.jobs.page": "Página",
    "table.label.jobs.previousChunkId": "Id do chunk anterior",
    "table.label.jobs.processRate": "Taxa de Processamento",
    "table.label.jobs.processRateSuffix": "Itens por minuto",
    "table.label.jobs.progress": "Progresso",
    "table.label.jobs.queryDuration": "Duração da requisição de dados (ms)",
    "table.label.jobs.recurrence": "Recorrência",
    "table.label.jobs.recurrence.daily": "Diário",
    "table.label.jobs.recurrence.interval": "Por intervalo",
    "table.label.jobs.recurrence.single": "Manual",
    "table.label.jobs.recurrence.specific": "Por dia específico",
    "table.label.jobs.size": "Tamanho",
    "table.label.jobs.started_at": "Iniciado em",
    "table.label.jobs.status": "Status",
    "table.label.jobs.tasksCount": "Qtde de tarefas",
    "table.label.jobs.tasksDone": "Tarefas finalizadas",
    "table.label.jobs.timeLeft": "Tempo restante",
    "table.label.jobs.updated_at": "Atualizado em",
    "table.label.objectType.actions": "Ações",
    "table.label.objectType.description": "Descrição",
    "table.label.objectType.id": "Id",
    "table.label.riskType.actions": "Ações",
    "table.label.riskType.description": "Descrição",
    "table.label.riskType.id": "Id",
    "table.label.smart-model.actions" : "Ações",
    "table.label.smart-model.default_runners" : "Qtde de Executores",
    "table.label.smart-model.description" : "Descrição",
    "table.label.smart-model.id" : "Id",
    "table.label.smart-model.url" : "URL",
    "table.label.sphere.actions": "Ações",
    "table.label.sphere.id": "Id",
    "table.label.sphere.name": "Nome",
    "table.page.next": "Próxima página",
    "table.page.previous": "Página anterior",
    "table.page.rowsCount": "em um total de",
    "table.page.rowsFrom": "mostrando linhas de",
    "table.page.rowsPerPage": "Linhas por página",
    "table.page.rowsTo": " a ",
    "table.result.error": "Falha na requisição",
    "table.result.none": "Nenhum registro encontrado",
    "toggle.active" : "Ativar",
    "toggle.inactive" : "Desativar",
    "true":"Sim",
    "validation.begin.greater.than.end" : "O valor inicial é maior que o final.",
    "validation.invalid.date" : "A data informada é inválida.",
    "validation.max.number" : "O valor deve ser menor ou igual a {{value}}.",
    "validation.max.text" : "O campo deve ter no máximo {{value}} caracteres.",
    "validation.min.number" : "O valor deve ser maior ou igual a {{value}}.",
    "validation.min.text" : "O campo deve ter no mínimo {{value}} caracteres.",
    "validation.no.value" : "Nenhum valor foi informado.",
}
