const isFunction = (value) => {
  return typeof value === 'function'
}

const isValidationConfigured = (field) => {
  return (field.expression)
          || (isFunction(field.validator))
          || (isFunction(field.validatorWithMessage))
}

export const validateForm = (form) => {
  Object.keys(form).forEach(key => {
    const field = form[key]
    if(field.validate === false
      || (!isValidationConfigured(field))) {
      field.valid = true
      return
    }
    if(!field.visited) {
      return
    }
    let value = field.value ;
    if(value !== 0 && !value)
      value = ''
    value = value.toString()
    if(field.expression) {
      const regex = new RegExp(field.expression)
      field.valid = regex.test(value)
    } else if(isFunction(field.validator)) {
      field.valid = field.validator(value, field.name, form)
    } else if(isFunction(field.validatorWithMessage)) {
      let message = field.validatorWithMessage(value, field.name, form)
      field.errorMessage = message
      field.valid = !message
    }
  });
}

export const isFormValid = (form) => {
  let res = true;

  Object.keys(form).forEach(key => {
    if(!form[key].valid) {
      console.log(`Field [${key}] not valid`)
      res = false;
      return false
    }

  });
  return res;
}

export const updateFieldsVisited = (form) => {
  Object.keys(form).forEach(key => {
    const field = form[key]
    if(field.visited)
      return
    field.visited =
          !!field.value
          || field.value === false
          || field.value === 0
          || field.validate === false
          || (!isValidationConfigured(field))
  });
}

export const updateFormWithData = (data, form) => {
  Object.keys(data).forEach(key => {
    if(form.hasOwnProperty(key)) {
      form[key].value = data[key]
    }
  });
}

export const getFormData = (form) => {
  const res = {}
  Object.keys(form).forEach(key => {
    res[key] = form[key].value
  });
  return res
}

export const urlValidator = (value) => {
  try {
    let url = new URL(value);
    return url.protocol === "http:" || url.protocol === "https:";
  } catch (_) {
    return false;
  }
}
