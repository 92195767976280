import config from 'config';
import { authHeader, handleResponse } from 'helpers';
import { createPaginationObject } from 'helpers/pagination';
import { objectToQueryString } from 'helpers/url';

export const agenciesService = {
    getById,
    getPage,
    getCount,
    getRelatedCompanies,
};

function getById(id) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/agency/${id}`, requestOptions).then(handleResponse);
}

function getRelatedCompanies(id) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/agency/${id}/relatedCompanies`, requestOptions).then(handleResponse);
}

function getCount() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/agencies/getCount`, requestOptions).then(handleResponse);
}

function getPage(pagination, filterByName) {

    let paginationObj = createPaginationObject(
        pagination.page,
        pagination.size,
        pagination.sort,
        pagination.order);

    if (filterByName) {
        paginationObj.name = filterByName;
    }
    let queryString = objectToQueryString(paginationObj);
    let url = `${config.apiUrl}/agencies` + (queryString && ("?" + queryString));
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(url, requestOptions).then(handleResponse).catch((msg) => { return { error: msg } });
}