
import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  input: {
    "&::-webkit-calendar-picker-indicator": {
      cursor: "pointer"
    }
  },
}));

const DateTimeField = (props) => {
  const classes = useStyles();
  const dateTimeRef = useRef();
  const [dateTimeValue, setDateTimeValue] = useState(props.value);

  function getType() {
    if(props.type != "date"
        && props.type != "time")
        return "datetime-local"
    return props.type
  }

  return (
    <TextField
      {...props}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        classes: { input: classes.input }
      }}
      fullWidth
      inputRef={dateTimeRef}
      onBlur={(event) => {
        if(!dateTimeRef.current.value) {
          dateTimeRef.current.value = null
        }
        event.target.value = dateTimeRef.current.value
        props.onBlur(event)
      }}
      onChange={(event) => {
        setDateTimeValue(event.target.value)
        props.onChange(event)
        props.onBlur(event)
      }}
      type={getType()}
      value={dateTimeValue}
    />
  );
}

DateTimeField.propTypes = {
};

export default DateTimeField;