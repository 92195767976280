import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { Dashboard, Help, Lock, People, Storage, Shuffle as ClassifierIcon } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Constants from 'common/systemConstants';
import BackButton from 'components/BackButton';
import { usersService } from 'services';

const OtherServices = (props) => {
  const { keycloak } = props
  const url = Constants.APP_URL
  const { t } = useTranslation();
  const actualRole = usersService.getRole(keycloak)

  return (
      <Box mx={2} my={2}>
        <Grid
          container
          spacing={4}
        >
            {(actualRole.includes(Constants.DEFAULT_ADMIN_PROFILE))&&(
              <Grid
                item
                lg={3}
                sm={6}
                xl={2}
                xs={12}
              >
                  <Card  >
                    <CardContent>
                      <a href={url+"/auth"} target="_blank">
                        <Typography variant="h4">
                          <Grid container>
                            <Grid xs={12} item>
                            <Lock  fontSize="large" />
                            </Grid>
                            <Grid xs={12} item>
                              {t('otherServices.keycloak')}
                            </Grid>
                          </Grid>
                        </Typography>
                      </a>
                    </CardContent>
                  </Card>
              </Grid>
            )}

            {(actualRole.includes(Constants.DEFAULT_ADMIN_PROFILE) || actualRole.includes(Constants.DEFAULT_MAIN_PROFILE) || actualRole.includes(Constants.DEFAULT_EXTERNAL_PROFILE))&&(
              <Grid
                item
                lg={3}
                sm={6}
                xl={2}
                xs={12}
              >
                  <Card>
                    <CardContent>
                      <a href={url+"/backend/api-doc"} target="_blank">
                        <Typography variant="h4">
                          <Grid container>
                            <Grid xs={12} item>
                              <Help  fontSize="large" />
                            </Grid>
                            <Grid xs={12} item>
                              {t('otherServices.docs')}
                            </Grid>
                          </Grid>
                        </Typography>
                      </a>
                    </CardContent>
                  </Card>
              </Grid>
            )}

            {(actualRole.includes(Constants.DEFAULT_ADMIN_PROFILE) || actualRole.includes(Constants.DEFAULT_MAIN_PROFILE))&&(
              <Grid
                item
                lg={3}
                sm={6}
                xl={2}
                xs={12}
              >
                  <Card  >
                    <CardContent>
                      <a href={url+"/classifiers"} target="_blank">
                        <Typography variant="h4">
                          <Grid container>
                            <Grid xs={12} item>
                              <ClassifierIcon  fontSize="large" />
                            </Grid>
                            <Grid xs={12} item>
                              {t('otherServices.classifiers')}
                            </Grid>
                          </Grid>
                        </Typography>
                      </a>
                    </CardContent>
                  </Card>
              </Grid>
            )}

            {(actualRole.includes(Constants.DEFAULT_ADMIN_PROFILE))&&(
              <Grid
                item
                lg={3}
                sm={6}
                xl={2}
                xs={12}
              >
                  <Card  >
                    <CardContent>
                      <a href={url+"/grafana/:3000/d/000000039/postgresql-backend?orgId=1&refresh=10s"} target="_blank">
                        <Typography variant="h4">
                          <Grid container>
                            <Grid xs={12} item>
                              <Dashboard  fontSize="large" />
                            </Grid>
                            <Grid xs={12} item>
                              {t('otherServices.grafana')}
                            </Grid>
                          </Grid>
                        </Typography>
                      </a>
                    </CardContent>
                  </Card>
              </Grid>
            )}

            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
                <BackButton />
            </Grid>
        </Grid>
      </Box>
  )
};


export default OtherServices;
