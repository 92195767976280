import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from 'routes';
import { KeycloakProvider } from '@react-keycloak/web'
import keycloak from './keycloak'
import 'translation/i18n';
import { ConfirmProvider } from 'material-ui-confirm';
const history = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});
validate.validators = {
  ...validate.validators,
  ...validators
};

export default class App extends Component {
  render() {
    return (
      <KeycloakProvider keycloak={keycloak}>
        <ThemeProvider theme={theme}>
          <ConfirmProvider>
            <Router history={history}>
              <Routes keycloak={keycloak} />
            </Router>
          </ConfirmProvider>
        </ThemeProvider>
      </KeycloakProvider>
    );
  }
}
