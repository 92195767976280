import config from 'config';
import { authHeader, handleResponse } from 'helpers';
import { createPaginationObject } from 'helpers/pagination';
import { objectToQueryString } from 'helpers/url';

export const gazettesService = {
    getById,
    getPage,
    remove,
    save
};

function getById(id) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/settings/gazette/${id}`, requestOptions).then(handleResponse).catch((msg)=>{return {error:msg}});
}

function save(data) {
    const method = (data.id) ? 'PUT' : 'POST'
    const requestOptions = { method: method, headers: authHeader(), body: JSON.stringify(data) };
    if (data.id!=null){
        return fetch(`${config.apiUrl}/settings/gazette/${data.id}`, requestOptions).then(handleResponse).catch((msg)=>{return {error:msg}});
    } else {
        return fetch(`${config.apiUrl}/settings/gazettes`, requestOptions).then(handleResponse).catch((msg)=>{return {error:msg}});
    }
}

function remove(id) {
    const requestOptions = { method: 'DELETE', headers: authHeader() };
    return fetch(`${config.apiUrl}/settings/gazette/${id}`, requestOptions).then(handleResponse).catch((msg)=>{return {error:msg}});
}

function getPage(
    pagination,
    filterByName) {

    let paginationObj = createPaginationObject(
        pagination.page, pagination.size, pagination.sort, pagination.order);
    if(filterByName) {
        paginationObj.name = filterByName;
    }
    let queryString = objectToQueryString(paginationObj);
    let url = `${config.apiUrl}/settings/gazettes` + (queryString && ("?" + queryString));
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(url, requestOptions).then(handleResponse).catch((msg)=>{return {error:msg}});
}