import config from 'config';
import { authHeader, doFetch } from 'helpers';

export const constellationService = {
    getById,
    save,
    create,
    remove
};

function getById(id) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return doFetch(`${config.apiUrl}/constellation/${id}`, requestOptions);
}

function create(obj) {
    return save(null, obj)
}

function save(id, obj) {
    const method = (id) ? 'PUT' : 'POST'
    const requestOptions = { method: method, headers: authHeader(), body: JSON.stringify(obj) };
    if (id != null){
        return doFetch(`${config.apiUrl}/constellation/${id}`, requestOptions);
    } else {
        return doFetch(`${config.apiUrl}/constellation`, requestOptions);
    }
}

function remove(id) {
    const requestOptions = { method: 'DELETE', headers: authHeader() };
    return doFetch(`${config.apiUrl}/constellation/${id}`, requestOptions);
}
