import { usersService } from 'services';

export const Role = {
    Anonymous: 'Anonymous',
    Administrator: 'Administrador',
    Manager: 'Perito Criminal',
    External: 'Externo'
}

const RouteAccess = [
    {
        route: "/logout" ,
        roles: [ Role.Anonymous ]
    },
    {
        route: "/login" ,
        roles: [ Role.Anonymous ]
    },
    {
        route: "/forgot" ,
        roles: [ Role.Anonymous ]
    },
    {
        route: "/not-found" ,
        roles: [ Role.Anonymous ]
    },
    {
        route: "/dashboard" ,
        roles: [ Role.Manager, Role.External ]
    },
    {
        route: "/risks" ,
        roles: [ Role.Manager ]
    },
    {
        route: "/agencies" ,
        roles: [ Role.Manager, Role.External ]
    },
    {
        route: "/agency/:id" ,
        roles: [ Role.Manager, Role.External ]
    },
    {
        route: "/companies" ,
        roles: [ Role.Manager, Role.External ]
    },
    {
        route: "/company/:id" ,
        roles: [ Role.Manager, Role.External ]
    },
    {
        route: "/search" ,
        roles: [ Role.Manager, Role.External ]
    },
    {
        route: "/constellation" ,
        roles: [ Role.Manager, Role.External ]
    },
    {
        route: "/account" ,
        roles: [ Role.Administrator, Role.Manager, Role.External ]
    },
    {
        route: "/logs" ,
        roles: [ Role.Administrator ]
    },
    {
        route: "/log/:id" ,
        roles: [ Role.Administrator ]
    },
    {
        route: "/collections" ,
        roles: [ Role.Manager, Role.External ]
    },
    {
        route: "/collection/:id" ,
        roles: [ Role.Manager, Role.External ]
    },
    {
        route: "/settings/:id" ,
        roles: [ Role.Administrator ]
    },
];

export const accessControlService = {
    userHasRole,
    getHomePageForRole
};

function getHomePageForRole(roles) {

    if(roles.includes(Role.Manager)) {
        return "/dashboard"
    } else if(roles.includes(Role.External)) {
        return "/dashboard"
    } else if(roles.includes(Role.Administrator)) {
        return "/jobs"
    }
    return "/logout"
}

function userHasRole(role) {

    // Check if user is logged in
    if(!usersService.currentUserValue) {
        return false;
    }

    let userRoles = usersService.currentUserValue.roles;
    return (typeof userRoles.find(userRole => userRole == role) != "undefined");
}
