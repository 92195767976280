import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';

const RouteWithLayout = props => {
  const { keycloak, layout: Layout, component: Component, title: title, subtitle: subtitle, ...rest } = props;
  return (
    <Route
      {...rest}
      render={matchProps => {
          return (
            <Layout title={title} subtitle={subtitle} keycloak={keycloak}>
              <Component {...matchProps} />
            </Layout>
          );
      }}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  title: PropTypes.string, 
  subtitle: PropTypes.string
};

export default RouteWithLayout;
