import { Box, IconButton, List, ListItem, ListItemText, Popover, Tooltip } from '@material-ui/core';
import { DynamicFeed } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  list: {
    padding: theme.spacing(0)
  }
}));

const SaveToCollectionContext = (props) => {
  
    const { t } = useTranslation();
    const classes = useStyles();  
    const [anchorEl, setAnchorEl] = useState(null);
    
    const handleOpenContext = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleCloseContext = () => {
      setAnchorEl(null);
    };

    return (
      <>
  
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleCloseContext}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.list}
          >
            <Box display={(typeof props.collections != "undefined" && props.collections.count == 0)?"block":"none"}>
              <ListItem>  
                <ListItemText primary={t('table.result.none')} />
              </ListItem>
  
            </Box>
            
            {((typeof props.collections != "undefined") && props.collections.count > 0) 
                  && props.collections.data.map((row) => (
              <ListItem 
                button 
                key={row.id}  
                onClick={()=>{ 
                  props.onCollectionClicked(row.id); 
                  handleCloseContext();
                }}>                
                <ListItemText primary={row.name} />
              </ListItem>
            ))}
          </List>
        </Popover>  
  
        <Tooltip title={t('button.collections')} aria-label={t('button.collections')}>
          <IconButton 
              color="inherit"
              onClick={(e)=>{
                  handleOpenContext(e)
                }}>
            <DynamicFeed fontSize="large" />
          </IconButton>
        </Tooltip>   
  
      </>
    );
  }

SaveToCollectionContext.propTypes = {
    collections : PropTypes.object,    
    onCollectionClicked: PropTypes.func,
};  

export default SaveToCollectionContext;