import React from 'react';
import { makeStyles } from '@material-ui/styles';
import logoImage from 'public/images/logos/logo.png'; 

const useStyles = makeStyles(theme => ({
  image: {
    maxWidth: "200px"
  }
}));

const TopbarLogo = props => {
  //const { ...rest } = props;

  const classes = useStyles();

  return (
    <img
      alt="Logo"
      src={logoImage}
      className={classes.image}
    />
  );
};

export default TopbarLogo;
