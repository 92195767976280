import React from 'react';

const ELIPSIS = '...'

export function truncateRight(value, length) {
  return ( value && value.length > length ) ?
              value.slice(0, length) + ELIPSIS
              :
              value
}

export function truncateMiddle(
  value,
  leftLength,
  rightLength
) {
  if (!value || (value.length < leftLength + rightLength + ELIPSIS.length)) {
    return value;
  }

  return (
    value.slice(0, leftLength) +
    ELIPSIS +
    value.slice(value.length - rightLength)
  );
}

export const LabelTruncateRight = ({value, length }) => {
  return (
    <>
      { truncateRight(value, length) }
    </>
  )
}
