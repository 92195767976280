import { Grid, IconButton, Tooltip } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ClearIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nextId } from 'helpers/nextId';
import FilterSelect from './FilterSelect';
import * as Constants from 'common/systemConstants';

const DynamicFilters = (props) => {

  const { t } = useTranslation();
  const [filters, setFilters] = useState({});

  const updateFilters = (filtersUpdated) => {
    setFilters(filtersUpdated)

    const res = {
      valid: true,
      filters: []
    }

    Object.keys(filtersUpdated).map(function (filterId) {
      const item = filtersUpdated[filterId]
      if (!item.filterValid) {
        res.valid = false;
        return;
      }

      const filter = {
        clauseCondition: item.filterCondition,
        name: item.filterName
      }

      if (item.filterValue.value
        || item.filterValue.value === 0
        || item.filterValue.value === false) {
        filter.value = item.filterValue.value
      } else {
        filter.beginValue = item.filterValue.beginValue
        filter.endValue = item.filterValue.endValue
      }
      res.filters.push(filter)
    })
    props.onFiltersChanged(res)
  }

  const handleAddFilter = () => {
    const tempFilters = { ...filters }
    tempFilters[nextId()] = {
      filterName: props.translationPrefix.includes('publication') ? Constants.OPTION_DEFAULT : Constants.OPTION_CODE,
      filterValue: Constants.getDefaultFilterValue(Constants.FIELD_TYPES.TEXT),
      filterValid: false,
      filterCondition: Constants.CLAUSE_AND
    }
    updateFilters(tempFilters)
  };

  const handleDelFilter = (index) => () => {
    const tempFilters = { ...filters }
    delete tempFilters[index];
    updateFilters(tempFilters)
  };

  const handleUpdateFilter = (
    index,
    filterName,
    filterValue,
    filterValid,
    filterCondition = Constants.CLAUSE_AND) => {
    const tempFilters = { ...filters }
    tempFilters[index] = {
      filterName: filterName,
      filterValue: filterValue,
      filterValid: filterValid,
      filterCondition: filterCondition
    }
    updateFilters(tempFilters)
  };

  return (

    <Grid
      container
      spacing={2}
    >
      {
        Object.keys(filters).map(function (index) {
          return (
            <React.Fragment key={index}>
              <Grid
                align="left"
                item
                sm={1}
                xs={2}
              >
                <IconButton
                  color="inherit"
                  onClick={handleDelFilter(index)}
                >
                  <ClearIcon />
                </IconButton>
              </Grid>
              <Grid
                align="left"
                item
                sm={11}
                xs={10}
              >
                <FilterSelect
                  defaultFilterCondition={Constants.CLAUSE_AND}
                  defaultFilterName={props.filters.content ? Constants.OPTION_DEFAULT : Constants.OPTION_CODE}
                  defaultFilterValue={Constants.DEFAULT_FILTER_VALUE}
                  filters={props.filters}
                  firstFilterIndex={Math.min(...Object.keys(filters))}
                  index={index}
                  translationPrefix={props.translationPrefix}
                  updateFilter={handleUpdateFilter}
                />
              </Grid>
            </React.Fragment>
          );
        })
      }

      <Grid
        align="left"
        item
        xs={12}
      >
        <Tooltip
          aria-label={t('label.add.filter.field')}
          placement="right"
          title={t('label.add.filter.field')}
        >
          <IconButton
            color="inherit"
            onClick={handleAddFilter}
            size={'medium'}
          >
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

DynamicFilters.propTypes = {
  filters: PropTypes.object,
  onFiltersChanged: PropTypes.func,
  translationPrefix: PropTypes.string
};

export default DynamicFilters;
