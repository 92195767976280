import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Paper, Input, Button, Box } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: '4px',
    display: 'flex',
    flexBasis: 420
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px',
    marginLeft:'2px'
  }
}));

const SearchInput = props => {
  const { className, onChange, onClick, style, ...rest } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const _handleKeyDown = function(e) {
    if (e.key === 'Enter') {
      onClick(e,{})
    }
  }

  return (
    <Paper
      {...rest}
      className={clsx(classes.root, className)}
      style={style}
    >
        <Input
          {...rest}
          className={classes.input}
          disableUnderline
          onChange={onChange}
          onKeyDown={(e)=>{_handleKeyDown(e)}}
        />
      <Button onClick={(e)=>{onClick(e,{})}}><SearchIcon className={classes.icon} /> {t('button.search')}</Button>
    </Paper>
  );
};

SearchInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object
};

export default SearchInput;
