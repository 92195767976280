import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { withSnackbar } from 'hooks/withSnackbar';
import { jobConfigService } from 'services';
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Switch,
  Collapse,
  ListItem,
  ListItemText,
  List,
  FormControl,
  Select,
  MenuItem
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { withRouter, useHistory } from 'react-router-dom';
import { ExpandLess, ExpandMore, Save} from '@material-ui/icons';
import LoadingSpinner from 'components/LoadingSpinner';
import BackButton from 'components/BackButton';
import DateTimeField from 'components/DateTimeField';
import Clock from 'components/Clock';
import { validateForm, isFormValid, updateFieldsVisited, updateFormWithData, getFormData, urlValidator} from 'helpers/formValidation';
import * as moment from 'moment';

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'
const DEFAULT_TIME_FORMAT = 'HH:MM'
const DEFAULT_BATCH_SIZE = 5000
const DEFAULT_CONCURRENCY = 4
const DEFAULT_WINDOW_DAYS = 0

const Recurrency = {
  SINGLE:   0,
  DAILY:    1,
  INTERVAL: 2,
  SPECIFIC: 3,
};
Object.freeze(Recurrency);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  formControl: {
    width: '100%'
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  actions: {
    justifyContent: 'flex-end'
  },
  labelJobStatus: {
    "& span:last-child": {
        fontSize: theme.typography.h3.fontSize
    }
  },
  jobSwitch: {
    "&.Mui-checked": {
        color: "#4f4f4f"
    },
    "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#198a1d",
    }
  }
}));

const windowDaysValidator = (value, recurrence) => {
  if(recurrence != Recurrency.DAILY)
    return true;
  const parsed = parseInt(value)
  if(Number.isNaN(parsed) || parsed < 0)
    return false;
  return true;
}

const executionTimeValidator = (value, recurrence) => {
  if(recurrence != Recurrency.SINGLE && !value) {
    return false;
  }
  return true;
}

const executionDayValidator = (value, field, form, recurrence) => {
  if(recurrence != Recurrency.SPECIFIC
    && recurrence != Recurrency.INTERVAL) {
      return null;
  }

  const basicValidation = (date) => {
    if(!date.isValid())
      return "Data inválida"
    if(date.isAfter(moment()))
      return "Não pode ser uma data futura"
    return null
  }

  const dateBegin = moment(form["execution_day"].value, DEFAULT_DATE_FORMAT)
  if(field == "execution_day") {
    let res = basicValidation(dateBegin);
    if(res) {
      return res
    }
  }

  if(recurrence != Recurrency.INTERVAL) {
    return null
  }

  const dateEnd = moment(form["execution_day_end"].value, DEFAULT_DATE_FORMAT)
  if(field == "execution_day_end") {
    let res = basicValidation(dateEnd);
    if(res) {
      return res
    }
  }

  if(dateBegin.isSameOrAfter(dateEnd)) {
    if(field == "execution_day") {
      return "A data inicial deve ser menor que a final"
    } else {
      return "Corrija a data inicial"
    }
  }

  return null
}

const ConfigurationForm = (props) => {

  const objectId = props.match.params.id;
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState();
  const [disableSaveButton, setDisableSaveButton] = useState(false)
  const [advancedOpened, setAdvancedOpened] = useState(false)
  const [recurrence, setRecurrence] = useState(Recurrency.SINGLE)
  const recurrenceRef = useRef()
  const [formData, setFormData] = useState( {
      active: {
        name: "active",
        value: true,
        validate: false
      },
      name: {
        name: "name",
        label: "jobConfig.label.name",
        value: "",
        expression: /[^\s]{3,}/,
        errorMessage: "O campo deve conter ao menos 3 caracteres"
      },
      description: {
        name: "description",
        label: "jobConfig.label.description",
        value: "",
        value: "",
        expression: /[^\s]{3,}/,
        errorMessage: "O nome deve conter ao menos 3 caracteres"
      },
      url_data_source: {
        name: "url_data_source",
        label: 'jobConfig.label.url_data_source',
        value: "",
        validator: urlValidator,
        errorMessage: "O campo deve conter uma URL válida"
      },
      url_data_destination: {
        name: "url_data_destination",
        label: 'jobConfig.label.url_data_destination',
        value: "",
        validator: urlValidator,
        errorMessage: "O campo deve conter uma URL válida"
      },
      batch_size: {
        name: "batch_size",
        label: 'jobConfig.label.batch_size',
        value: DEFAULT_BATCH_SIZE,
        visited: true,
        expression: /^[1-9]\d*$/,
        errorMessage: "O campo deve ser maior que 0 (zero)"
      },
      concurrence: {
        name: "concurrence",
        label: 'jobConfig.label.concurrence',
        value: DEFAULT_CONCURRENCY,
        visited: true,
        expression: /^[1-9]\d*$/,
        errorMessage: "O campo deve ser maior que 0 (zero)"
      },
      window_days: {
        name: "window_days",
        label: 'jobConfig.label.window_days',
        value: DEFAULT_WINDOW_DAYS,
        visited: true,
        validator: (value) => windowDaysValidator(value, recurrenceRef.current),
        errorMessage: "O valor do campo deve ser maior ou igual a zero",
      },
      execution_day: {
        name: "execution_day",
        label: 'jobConfig.label.execution_day',
        validatorWithMessage: (value, field, form) => executionDayValidator(value, field, form, recurrenceRef.current),
        visited: true,
        value: moment().format(DEFAULT_DATE_FORMAT),
      },
      execution_day_end: {
        name: "execution_day_end",
        label: 'jobConfig.label.execution_day_end',
        validatorWithMessage: (value, field, form) => executionDayValidator(value, field, form, recurrenceRef.current),
        visited: true
      },
      execution_time: {
        name: "execution_time",
        label: 'jobConfig.label.execution_time',
        visited: true,
        value: moment().utc().format(DEFAULT_TIME_FORMAT),
        validator: (value) => executionTimeValidator(value, recurrenceRef.current),
        errorMessage: t('form.requiredField'),
      }
  });

  const handleOnBlur = event => {
    const tempFormData = {...formData}
    tempFormData[event.target.name].visited = true
    validateForm(tempFormData)
    setFormData(tempFormData)
  };

  const handleOnChange = event => {
    const tempFormData = {...formData}
    if(event.target.type == 'checkbox'){
      tempFormData[event.target.name].value = event.target.checked
    } else {
      tempFormData[event.target.name].value = event.target.value
    }
    validateForm(tempFormData)
    setFormData(tempFormData)
  };

  const handleOnSave = () => {
    setLoading(true)
    if(!isFormValid(formData))
      return
    const data = getFormData(formData)
    if(objectId) {
      data.id = objectId
    }
    data.daily = (recurrence == Recurrency.DAILY)

    if (data.daily) {
      data.execution_day = null
      data.execution_day_end = null
    }

    if (recurrence == Recurrency.SINGLE) {
      data.execution_time = null
      data.execution_day = null
      data.execution_day_end = null
    }

    jobConfigService.save(data)
      .then(() => {
        history.goBack()
      })
      .catch(error => {
        props.showMessageError(error)
      })
  }

  React.useEffect(() => {
    const tempFormData = {...formData}
    setLoading(true)
    if (!objectId){
      updateFieldsVisited(tempFormData)
      validateForm(tempFormData)
      setFormData(tempFormData)
      setLoading(false)
    } else {
      jobConfigService
        .getById(objectId)
        .then(data => {
          let recurrenceType = Recurrency.SINGLE
          if(data.daily) {
            recurrenceType = Recurrency.DAILY
          } else if(data.execution_day) {
            if(!data.execution_day_end) {
              recurrenceType = Recurrency.SPECIFIC
            } else {
              recurrenceType = Recurrency.INTERVAL
            }
          }
          if(data.execution_day) {
            data.execution_day = moment(new Date(data.execution_day)).format(DEFAULT_DATE_FORMAT)
          }
          if(data.execution_day_end) {
            data.execution_day_end = moment(new Date(data.execution_day_end)).format(DEFAULT_DATE_FORMAT)
          }

          updateFormWithData(data, tempFormData)
          updateFieldsVisited(tempFormData)
          validateForm(tempFormData)
          setFormData(tempFormData)
          setRecurrence(recurrenceType)
          setLoading(false)
        })
        .catch(error => {
          props.showMessageError(error)
        })
    }
  }, []);

  React.useEffect(() => {
    setDisableSaveButton(!isFormValid(formData))
  }, [formData]);

  React.useEffect(() => {
    recurrenceRef.current = recurrence
    const tempFormData = {...formData}
    validateForm(tempFormData)
    setFormData(tempFormData)
  }, [recurrence]);

  const handleOnChangeExecutionMode = event => {
    let tempRecurrence = event.target.value
    const tempFormData = {...formData}

    tempFormData.execution_time.value = ''
    tempFormData.execution_day.value = ''
    tempFormData.execution_day_end.value = ''
    tempFormData.window_days.value = DEFAULT_WINDOW_DAYS

    setFormData(tempFormData)
    setRecurrence(tempRecurrence)
  };

  const isAdvancedOptionsValid = () => {
    return !(formData.batch_size.valid === false
            || formData.concurrence.valid === false)
  }

  return (
    <div className={classes.root}>
      <form onSubmit={(e) => { e.preventDefault(); handleOnSave() }}>
        <Box mb={2}>
          <Typography variant="h4">{(objectId)?t('jobConfig.operation.edit'):t('jobConfig.operation.create')} - {t('jobConfig.title')}</Typography>
        </Box>
        { loading ? <LoadingSpinner loading={loading}/> :
        <Card>
          <Box mx={2} my={2}>
            <CardContent className={classes.content}>
              <Grid container spacing={1}>

                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    className={classes.labelJobStatus}
                    label={formData.active.value ? t('jobConfig.label.active') : t('jobConfig.label.inactive')}
                    control={
                      <Switch
                        classes={{ switchBase: classes.jobSwitch }}
                        name={formData.active.name}
                        checked={formData.active.value}
                        onChange={handleOnChange}
                      />}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                    label={t(formData.name.label)}
                    name={formData.name.name}
                    value={formData.name.value}
                    error={formData.name.visited && !formData.name.valid}
                    helperText={(formData.name.visited && !formData.name.valid) ? t(formData.name.errorMessage) : '' }
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                    label={t(formData.description.label)}
                    name={formData.description.name}
                    value={formData.description.value}
                    error={formData.description.visited && !formData.description.valid}
                    helperText={(formData.description.visited && !formData.description.valid) ? t(formData.description.errorMessage) : '' }
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                    label={t(formData.url_data_source.label)}
                    name={formData.url_data_source.name}
                    value={formData.url_data_source.value}
                    error={formData.url_data_source.visited && !formData.url_data_source.valid}
                    helperText={(formData.url_data_source.visited && !formData.url_data_source.valid) ? t(formData.url_data_source.errorMessage) : '' }
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                    label={t(formData.url_data_destination.label)}
                    name={formData.url_data_destination.name}
                    value={formData.url_data_destination.value}
                    error={formData.url_data_destination.visited && !formData.url_data_destination.valid}
                    helperText={(formData.url_data_destination.visited && !formData.url_data_destination.valid) ? t(formData.url_data_destination.errorMessage) : '' }
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography variant="h5" style={{marginTop:"30px", marginBottom:"10px"}}>
                    {t('table.label.jobs.execution.mode')}
                  </Typography>

                  <FormControl
                    fullWidth
                  >
                    <Select
                      fullWidth
                      onChange={handleOnChangeExecutionMode}
                      value={recurrence}
                      variant="standard"
                    >
                      <MenuItem value={Recurrency.SINGLE}>
                        {t('table.label.jobs.recurrence.single')}
                      </MenuItem>
                      <MenuItem value={Recurrency.DAILY}>
                        {t('table.label.jobs.recurrence.daily')}
                      </MenuItem>
                      <MenuItem value={Recurrency.SPECIFIC}>
                        {t('table.label.jobs.recurrence.specific')}
                      </MenuItem>
                      <MenuItem value={Recurrency.INTERVAL}>
                        {t('table.label.jobs.recurrence.interval')}
                      </MenuItem>
                    </Select>
                  </FormControl>

                </Grid>

                {(() => {
                  switch(recurrence) {
                    case Recurrency.DAILY:
                      return (
                          <Grid item xs={12} sm={4}>
                            <TextField
                              fullWidth
                              margin="dense"
                              variant="standard"
                              required
                              type="number"
                              label={t(formData.window_days.label)}
                              name={formData.window_days.name}
                              value={formData.window_days.value}
                              error={formData.window_days.visited && !formData.window_days.valid}
                              helperText={(formData.window_days.visited && !formData.window_days.valid) ? t(formData.window_days.errorMessage) : '' }
                              onChange={handleOnChange}
                              onBlur={handleOnBlur}
                            />
                          </Grid>
                      );
                    case Recurrency.SPECIFIC:
                      return (
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth style={{marginTop:"15px"}}>
                            <DateTimeField
                              type="date"
                              label={t('jobConfig.label.specific.day')}
                              name={formData.execution_day.name}
                              value={formData.execution_day.value}
                              error={formData.execution_day.visited && !formData.execution_day.valid}
                              onBlur={handleOnBlur}
                              onChange={handleOnChange}
                              helperText={(formData.execution_day.visited && !formData.execution_day.valid) ? t(formData.execution_day.errorMessage) : '' }
                            />
                          </FormControl>
                        </Grid>
                      );
                    case Recurrency.INTERVAL:
                      return (
                        <>
                        <Grid item xs={12} sm={12}>
                          <Grid container>
                            <Grid item xs={12} sm={4}>
                              <FormControl fullWidth style={{marginTop:"15px"}}>
                                <DateTimeField
                                  type="date"
                                  label={t(formData.execution_day.label)}
                                  name={formData.execution_day.name}
                                  value={formData.execution_day.value}
                                  error={formData.execution_day.visited && !formData.execution_day.valid}
                                  onBlur={handleOnBlur}
                                  onChange={handleOnChange}
                                  helperText={(formData.execution_day.visited && !formData.execution_day.valid) ? t(formData.execution_day.errorMessage) : '' }
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Grid container>
                            <Grid item xs={12} sm={4}>
                              <FormControl fullWidth style={{marginTop:"15px"}}>
                                <DateTimeField
                                  type="date"
                                  label={t(formData.execution_day_end.label)}
                                  name={formData.execution_day_end.name}
                                  value={formData.execution_day_end.value}
                                  error={formData.execution_day_end.visited && !formData.execution_day_end.valid}
                                  onBlur={handleOnBlur}
                                  onChange={handleOnChange}
                                  helperText={(formData.execution_day_end.visited && !formData.execution_day_end.valid) ? t(formData.execution_day_end.errorMessage) : '' }
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        </>
                      );
                  }

                })()}

                { recurrence != Recurrency.SINGLE &&
                  <Grid item xs={12} sm={12}>
                    <Grid container>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth style={{marginTop:"15px"}}>
                          <DateTimeField
                            type="time"
                            label={t(formData.execution_time.label)}
                            name={formData.execution_time.name}
                            value={formData.execution_time.value}
                            error={formData.execution_time.visited && !formData.execution_time.valid}
                            onBlur={handleOnBlur}
                            onChange={handleOnChange}
                            helperText={(formData.execution_time.visited && !formData.execution_time.valid) ? t(formData.execution_time.errorMessage) : '' }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{marginTop:"15px"}}>
                        <Clock utc></Clock>
                      </Grid>
                    </Grid>
                  </Grid>
                }

                <Grid item xs={12} sm={12} style={{marginTop:"30px"}}>
                  <List>
                    <ListItem style={{paddingLeft: 0}} button onClick={() => {setAdvancedOpened(!advancedOpened)} }>
                      <ListItemText primary={<Typography variant="h5">{t('label.advanced')}</Typography> }/>
                      {advancedOpened ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                  </List>

                  <Collapse in={advancedOpened || !isAdvancedOptionsValid()} timeout="auto" unmountOnExit>

                    <Grid container spacing={1}>

                      <Grid item xs={12} sm={3}>
                        <TextField
                          fullWidth
                          margin="dense"
                          variant="standard"
                          required
                          type="number"
                          label={t(formData.batch_size.label)}
                          name={formData.batch_size.name}
                          value={formData.batch_size.value}
                          error={formData.batch_size.visited && !formData.batch_size.valid}
                          helperText={(formData.batch_size.visited && !formData.batch_size.valid) ? t(formData.batch_size.errorMessage) : '' }
                          onChange={handleOnChange}
                          onBlur={handleOnBlur}
                        />
                      </Grid>

                      <Box width="100%"/>

                        <Grid item xs={12} sm={3}>
                          <TextField
                            fullWidth
                            margin="dense"
                            variant="standard"
                            required
                            type="number"
                            label={t(formData.concurrence.label)}
                            name={formData.concurrence.name}
                            value={formData.concurrence.value}
                            error={formData.concurrence.visited && !formData.concurrence.valid}
                            helperText={(formData.concurrence.visited && !formData.concurrence.valid) ? t(formData.concurrence.errorMessage) : ''
                            }
                            onChange={handleOnChange}
                            onBlur={handleOnBlur}
                          />
                        </Grid>

                    </Grid>

                  </Collapse>

                </Grid>
              </Grid>
            </CardContent>
            <CardActions className={classes.actions}>
              <Grid container justifyContent = "flex-end">
                <Button
                    type="submit"
                    disabled={disableSaveButton}
                    startIcon={<Save/>}
                    style={{marginTop: "20px"}}
                    color="primary"
                    variant="contained">
                  {t('button.save')}
                </Button>
              </Grid>
            </CardActions>
          </Box>
        </Card>
        }
        <Box mt={3}>
          <Grid
              container
              spacing={4}
              >
              <BackButton />
          </Grid>
        </Box>
      </form>
    </div>
    )
  }

export default withSnackbar(withRouter(ConfigurationForm));